import React, { useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ChargeMilesPlayStore from "../../image/chargemiles-playstore.webp";
import ChargeMilesAppStore from "../../image/chargemiles-492x0w.webp";
import { StyledButton } from "../../component/common/Styled";
import { Context } from "../../context";
import { DomainText } from "../../component/chargeMiles/DomainText";
import { useDomainConfig } from "../../hooks/useDomainConfig";

function MobileAppInstallWidget() {
  const domainConfig = useDomainConfig()
  const { maps } = useContext(Context);
  const isIOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const closeWidget = () => {
    const { setMapsData } = maps;
    setMapsData((prev) => ({
      ...prev,
      widget: false,
    }));
  };

  useEffect(() => {
    const { setMapsData } = maps;
    setMapsData((prev) => ({
      ...prev,
      widget: true,
    }));
  }, []);

  return (
    <>
      {maps.mapsData?.widget && (
        <Box className="mobile-install-widget">
          <Box className="widget">
            <IconButton onClick={closeWidget} aria-label="close widget">
              {" "}
              <CloseOutlinedIcon />{" "}
            </IconButton>
            <picture>
              <source media="(max-width:600px)" type="image/webp" />
              <img
                src={!isIOS ? ChargeMilesPlayStore : ChargeMilesAppStore}
                alt="Play Store Logo"
              />
            </picture>
            <Paper elevation={0}>
              <Typography variant="subtitle2">
                <DomainText />
              </Typography>
            </Paper>
            <StyledButton
              variant="contained"
              target="_blank"
              href={
                !isIOS
                  ? domainConfig.playStoreUrl
                  : process.env.REACT_APP_IOS_APP_STORE_URL
              }
            >
              Install
            </StyledButton>
          </Box>
        </Box>
      )}
    </>
  );
}

export default React.memo(MobileAppInstallWidget);
