// material
import { styled } from "@mui/material/styles";
import { Container, Grid, Box, Typography } from "@mui/material";
import { CMBreadCrumb } from "../common/CMBreadCrumb";
import { CMFooter } from "../common/CMFooter";

// images and icons
import FindBestCM from "../../image/why-chargemiles/find-best-cm.jpg";
import GoMilesCM from "../../image/why-chargemiles/go-miles-cm.jpg";
import ManageYourCharging from "../../image/why-chargemiles/manage-your-charging.png";
import PlanYourCharging from "../../image/why-chargemiles/plan-your-trip.jpg";
// components
import Page from "../Page";
import { DomainText } from "../chargeMiles/DomainText";

const StyledTitleSpan = styled("span")`
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  font-size: 3.25rem;
  line-height: 24px;
  font-weight: 500;
  position: relative;
  color: #01b763;
  width: 100%;
  margin: 0 0 10px;
  padding-right: 45px;
  letter-spacing: -2px;
  padding-left: 45px;
  :before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #01b763;
    height: 1px;
    width: 35px;
  }
  :after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #01b763;
    height: 1px;
    width: 35px;
  }
`;
export default function WhyCM() {
  return (
    <Page title="Dashboard | WhyUs-UI">
      <Container className="why-cm-container">
        <Box className="our-feature mb-28">
          <StyledTitleSpan className="title-legend">
            OUR FEATURES
          </StyledTitleSpan>
          <Typography variant="body1">
            Do you want to locate the best rapid charging stations along your
            route?
          </Typography>
          <Typography variant="body1">
            Are you preparing for a long trip by electric vehicle for the
            holidays?
          </Typography>
          <Typography variant="body1">
            Are you looking for free charging stations in the surrounding area?
          </Typography>
          <Typography variant="body1">
            Are you looking for a charging station nearby?
          </Typography>
        </Box>
        <Typography variant="h4">
          Introducing <DomainText /> Mapping Service – Locate Charge Points,
          Plan Your Journey And Share Updates With Other EV Drivers
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="zig-zag"
          spacing={2}
        >
          <Grid item xs={12} lg={4} md={4}>
            <Typography variant="h2">Find the best charging point</Typography>
          </Grid>
          <Grid item xs={12} lg={8} md={8}>
            <span className="image">
              <img
                src={FindBestCM}
                alt="find best charging point"
                height={275}
              />
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="zig-zag"
          spacing={2}
        >
          <Grid item xs={12} lg={8} md={8}>
            <span className="image">
              <img
                src={PlanYourCharging}
                alt="find best charging point"
                height={275}
              />
            </span>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <Typography variant="h2">Plan Longer Electric Journey's</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="zig-zag"
          spacing={2}
        >
          <Grid item xs={12} lg={4} md={4}>
            <Typography variant="h2">
              Travel Together with fellow EV drivers
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8} md={8}>
            <span className="image">
              <img
                src={GoMilesCM}
                alt="find best charging point"
                height={275}
              />
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="zig-zag"
          spacing={2}
        >
          <Grid item xs={12} lg={8} md={8}>
            <span className="image">
              <img
                src={ManageYourCharging}
                alt="find best charging point"
                height={275}
              />
            </span>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <Typography variant="h2">Manage your charging</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Box className="our-feature">
            <StyledTitleSpan className="title-legend">
              Other Features
            </StyledTitleSpan>
            <Typography variant="body1">
              <DomainText /> allows commercial and domestic users, charging
              point providers to add a charger to <DomainText /> Map to enable
              community charging for free or a small charge. Add new public
              charge points through the wizard, points will be reviewed by the{" "}
              <DomainText />
              data team and then added to <DomainText /> Map.
            </Typography>
          </Box>
        </Grid>
        <Grid container justifyContent="center">
          <Box className="team-cares mb-28">
            <StyledTitleSpan className="title-legend">
              A Team that Cares
            </StyledTitleSpan>
            <Typography variant="body1">
              <DomainText /> is also a dream team that gives their all to
              enhance the app every day with the help of your invaluable
              feedback. Any questions, suggestions, rave reviews? Please don’t
              hesitate to get in touch{" "}
              <a href="mailto:info@chargemiles.ca">support@chargemiles.ca</a>
            </Typography>
          </Box>
        </Grid>
      </Container>
      {/* <CMFooter /> */}
    </Page>
  );
}
