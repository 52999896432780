// material
import { Box, Button, Container, Paper } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { CMBreadCrumb } from "../common/CMBreadCrumb";
import { CMFooter } from "../common/CMFooter";
// components
import Page from "../Page";
// ----------------------------------------------------------------------

export default function ContactUs() {
  return (
    <Page title="Dashboard | ContactUs-UI">
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            "& > :not(style)": {
              m: { md: 3, xs: "auto" },
              mb: { xs: 3 },
              width: 350,
              padding: "60px 45px 60px 45px",
              backgroundColor: "#F7F9FA",
            },
            padding: "128px 0",
          }}
        >
          <Paper elevation={3}>
            <Button
              href="#text-buttons"
              sx={{
                backgroundColor: "#01B763",
                fill: "#FFFFFF",
                color: "#FFFFFF",
                fontSize: "56px",
                borderRadius: "50%",
                padding: "0.5em",
                marginBottom: "15px",
              }}
            >
              <LocalPhoneIcon sx={{ fontSize: "56px" }} />
            </Button>
            <h4 style={{ marginBottom: "0px", color: "#2C2C2C" }}>
              Our Contact
            </h4>
            <p
              style={{
                color: "#848484",
                marginBottom: "4px",
                textAlign: "left",
              }}
            >
              <a
                href="tel:+1-6477224348"
                style={{
                  color: "#848484",
                  textDecoration: "none",
                  textAlign: "left",
                }}
              >
                Canada: +1 647-792-0124 +1 647-722-4348 (whatsapp)
              </a>
            </p>
          </Paper>
          <Paper elevation={3}>
            <Button
              href="#text-buttons"
              sx={{
                backgroundColor: "#01B763",
                fill: "#FFFFFF",
                color: "#FFFFFF",
                fontSize: "56px",
                borderRadius: "50%",
                padding: "0.5em",
                marginBottom: "15px",
              }}
            >
              <EmailIcon sx={{ fontSize: "56px" }} />
            </Button>
            <h4 style={{ marginBottom: "0px", color: "#2C2C2C" }}>Mail Us</h4>
            <p style={{ color: "#848484", marginBottom: "4px" }}>
              <a
                href="mailto:info@chargemiles.ca"
                style={{
                  color: "#848484",
                  textDecoration: "none",
                }}
              >
                info@chargemiles.ca
              </a>
            </p>
            <p style={{ color: "#848484", marginBottom: "4px" }}>
              <a
                href="mailto:info@chargemiles.ca"
                style={{
                  color: "#848484",
                  textDecoration: "none",
                }}
              >
                info@chargemiles.ca
              </a>
            </p>
          </Paper>
          <Paper elevation={3}>
            <Button
              href="#text-buttons"
              sx={{
                backgroundColor: "#01B763",
                fill: "#FFFFFF",
                color: "#FFFFFF",
                fontSize: "56px",
                borderRadius: "50%",
                padding: "0.5em",
                marginBottom: "15px",
              }}
            >
              <LocationOnIcon sx={{ fontSize: "56px" }} />
            </Button>
            <h4 style={{ marginBottom: "0px", color: "#2C2C2C" }}>
              Our Location
            </h4>
            <p style={{ color: "#848484", marginBottom: "4px" }}>
              10 Biggood Drive, Ajax, L1Z0E2,Ontario,Canada.
            </p>
          </Paper>
        </Box>
      </Container>
      {/* <CMFooter /> */}
    </Page>
  );
}
