import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Avatar from '@mui/material/Avatar';
import "./UploadPhoto.css";
import attachment from "../../image/attachment.png";

function UploadPhoto({ setImageSrc, imageSrc, uploadImage }) {

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = (readerEvt) => {
      let binaryString = readerEvt.target.result;
      let base64tImageString = "data:image/png;base64," + btoa(binaryString);
      //setImageSrc(binaryString);
      uploadImage(binaryString);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box component="div" m={1}>
      <label className="display-block">
        <input
          type="file"
          id="myProfilePhotoUploadBtn"
          onChange={handleImageChange}
          className="file-input"
        />
        {/* {imageSrc && <Avatar
          src={imageSrc || attachment}
          alt={"userDetail.username"}
          variant="square"
        />} */}
        <Avatar
          src={imageSrc || attachment}
          variant="square"
          className="attachment-image"
        />
        Attachment
      </label>
    </Box>
  );
}

export default UploadPhoto;
