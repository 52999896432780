// import homeBanner1 from '../asset/home-banner-4.jpg';
// import homeBanner2 from '../asset/Banner.jpg';
// import homeBanner3 from '../asset/event.jpg';
import PlugCCSType2PNG from "../image/plug_combo_2.png";
import {ReactComponent as PlugCCSType2} from "../image/connectorSvg/img_connector_1.svg";
import {ReactComponent as PlugType2 } from "../image/connectorSvg/img_connector_2.svg";
import {ReactComponent as PlugChademo } from "../image/connectorSvg/img_connector_3.svg";
import {ReactComponent as PlugType1 } from "../image/connectorSvg/img_connector_4.svg";
import {ReactComponent as PlugType1AC } from "../image/connectorSvg/img_connector_4.svg";
import {ReactComponent as PlugGbt } from "../image/connectorSvg/img_connector_7.svg";
import {ReactComponent as PlugType3Pin } from "../image/connectorSvg/img_connector_6.svg";
import {ReactComponent as PlugTypeCCSType1 } from "../image/connectorSvg/img_sockets_CCS_type_1.svg";
import {ReactComponent as PlugTypeJ1772 } from "../image/connectorSvg/img_sockets_j1772.svg";
import {ReactComponent as PlugTypeWall } from "../image/connectorSvg/img_sockets_Wall.svg";
import {ReactComponent as PlugTypeThreePhase } from "../image/connectorSvg/img_sockets_3_phase.svg";
import {ReactComponent as PlugTypeCSSSAE } from "../image/connectorSvg/img_sockets_CCS_type_1.svg";
import {ReactComponent as PlugTypeIEC60309 } from "../image/connectorSvg/img_sockets_iec60309.svg"
import SlowPNG from "../image/speed_slow.png";
import SpeedFastPNG from "../image/speed_fast.png";
import SpeedRapidPNG from "../image/speed_rapid.png";
import SpeedUltraRapidPNG from "../image/speed_ultra_rapid.png";
import EVTwoPNG from '../image/ev_two.png';
import EVThreePNG from '../image/ev_three.png';
import EVFourPNG from '../image/ev_four.png';
import EVSwipePNG from '../image/ev_swipe.png';
import MarkerGreenPNG from "../image/balloon_green.svg";
import MarkerRedPNG from "../image/balloon_red.svg";
import MarkerBluePNG from "../image/balloon_blue.png";
import MarkerOrangePNG from "../image/balloon_orange.png";
import DefaultMarkerPNG from "../image/balloon_gray_dot.png";
import EV15PNG from '../image/EV-15.png';
import EV16PNG from '../image/EV-16.png';
import EV18PNG from '../image/EV-18.png';
import EV19PNG from '../image/EV-19.png';
import twoWheelerGeneric from '../image/genericVehicleSegment/twoWheelerGeneric.png';
import threeWheelerGeneric from '../image/genericVehicleSegment/threeWheelerGeneric.png';
import fourWheelerGeneric from '../image/genericVehicleSegment/fourWheelerGeneric.png';
import Icon14 from '../image/icon-14.png';
import Icon15 from '../image/icon-15.png';
import Icon16 from '../image/icon-16.png';
import Icon17 from '../image/icon-17.png';
import FaultConnectorImage from '../image/Others_Switch.svg';
import AARGO from "../image/networkFilter/AARgo.svg";
import AARGO_GREEN from "../image/networkFilter/AARgo_green.svg";
import AARGO_RED from "../image/networkFilter/AARgo_red.svg";
import AARGO_GREY from "../image/networkFilter/AARgo_grey.svg";
import ATHER from "../image/networkFilter/Ather.svg";
import ATHER_GREEN from "../image/networkFilter/Ather_green.svg";
import ATHER_RED from "../image/networkFilter/Ather_red.svg";
import ATHER_GREY from "../image/networkFilter/Ather_grey.svg";
import CHARGE_GRID from "../image/networkFilter/ChargeGrid.svg";
import CHARGE_GRID_GREEN from "../image/networkFilter/ChargeGrid_green.svg";
import CHARGE_GRID_RED from "../image/networkFilter/ChargeGrid_red.svg";
import CHARGE_GRID_GREY from "../image/networkFilter/ChargeGrid_grey.svg";
import CHARGE_ZONE from "../image/networkFilter/ChargeZone.svg";
import CHARGE_ZONE_GREEN from "../image/networkFilter/ChargeZone_green.svg";
import CHARGE_ZONE_RED from "../image/networkFilter/ChargeZone_red.svg";
import CHARGE_ZONE_GREY from "../image/networkFilter/ChargeZone_grey.svg";
import EFILL from "../image/networkFilter/Efill.svg";
import EFILL_GREEN from "../image/networkFilter/Efill_green.svg";
import EFILL_RED from "../image/networkFilter/Efill_red.svg";
import EFILL_GREY from "../image/networkFilter/Efill_grey.svg";
import ELECTRIVA from "../image/networkFilter/Electriva.svg";
import ELECTRIVA_GREEN from "../image/networkFilter/Electriva_green.svg";
import ELECTRIVA_RED from "../image/networkFilter/Electriva_red.svg";
import ELECTRIVA_GREY from "../image/networkFilter/Electriva_grey.svg";
import EVRE from "../image/networkFilter/EVRe.svg";
import EVRE_GREEN from "../image/networkFilter/EVRe_green.svg";
import EVRE_RED from "../image/networkFilter/EVRe_red.svg";
import EVRE_GREY from "../image/networkFilter/EVRe_grey.svg";
import FORTUM from "../image/networkFilter/Fortum.svg";
import FORTUM_GREEN from "../image/networkFilter/Fortum_green.svg";
import FORTUM_RED from "../image/networkFilter/Fortum_red.svg";
import FORTUM_GREY from "../image/networkFilter/Fortum_grey.svg";
import GOEGO from "../image/networkFilter/Goego.svg";
import GOEGO_GREEN from "../image/networkFilter/Goego_green.svg";
import GOEGO_RED from "../image/networkFilter/Goego_red.svg";
import GOEGO_GREY from "../image/networkFilter/Goego_grey.svg";
import RELUX from "../image/networkFilter/Relux.svg";
import RELUX_GREEN from "../image/networkFilter/Relux_green.svg";
import RELUX_RED from "../image/networkFilter/Relux_red.svg";
import RELUX_GREY from "../image/networkFilter/Relux_grey.svg";
import STATIQ from "../image/networkFilter/Statiq.svg";
import STATIQ_GREEN from "../image/networkFilter/Statiq_green.svg";
import STATIQ_RED from "../image/networkFilter/Statiq_red.svg";
import STATIQ_GREY from "../image/networkFilter/Statiq_grey.svg";
import TATA from "../image/networkFilter/Tata.svg";
import TATA_GREEN from "../image/networkFilter/Tata_green.svg";
import TATA_RED from "../image/networkFilter/Tata_red.svg";
import TATA_GREY from "../image/networkFilter/Tata_grey.svg";
import VOLTTIC from "../image/networkFilter/Volttic.svg";
import VOLTTIC_GREEN from "../image/networkFilter/Volttic_green.svg";
import VOLTTIC_RED from "../image/networkFilter/Volttic_red.svg";
import VOLTTIC_GREY from "../image/networkFilter/Volttic_grey.svg";
import JIO_BP from "../image/networkFilter/JioBP.svg";
import JIO_BP_GREEN from "../image/networkFilter/JioBP_green.svg";
import JIO_BP_RED from "../image/networkFilter/JioBP_red.svg";
import JIO_BP_GREY from "../image/networkFilter/JioBP_grey.svg";
import XOBOLT from "../image/networkFilter/XObolt.svg";
import XOBOLT_GREEN from "../image/networkFilter/XObolt_green.svg";
import XOBOLT_RED from "../image/networkFilter/XObolt_red.svg";
import XOBOLT_GREY from "../image/networkFilter/XObolt_grey.svg";
import ZEON from "../image/networkFilter/Zeon.svg";
import ZEON_GREEN from "../image/networkFilter/Zeon_green.svg";
import ZEON_RED from "../image/networkFilter/Zeon_red.svg";
import ZEON_GREY from "../image/networkFilter/Zeon_grey.svg";
import CHARGEMILES from "../image/networkFilter/Chargemiles.png";
import CHARGEMILES_GREEN from "../image/networkFilter/Chargemiles.png";
import CHARGEMILES_RED from "../image/networkFilter/Chargemiles.png";
import CHARGEMILES_GREY from "../image/networkFilter/Chargemiles.png";
import BECIL from "../image/networkFilter/BECIL.svg";
import BECIL_GREEN from "../image/networkFilter/BECIL_green.svg";
import BECIL_RED from "../image/networkFilter/BECIL_red.svg";
import BECIL_GREY from "../image/networkFilter/BECIL_grey.svg";
import IOCL from "../image/networkFilter/IOCL.svg";
import IOCL_GREEN from "../image/networkFilter/IOCL_green.svg";
import IOCL_RED from "../image/networkFilter/IOCL_red.svg";
import IOCL_GREY from "../image/networkFilter/IOCL_grey.svg";
import INSTAVOLT from "../image/networkFilter/InstaVolt.svg";
import INSTAVOLT_GREEN from "../image/networkFilter/InstaVolt_green.svg";
import INSTAVOLT_RED from "../image/networkFilter/InstaVolt_red.svg";
import INSTAVOLT_GREY from "../image/networkFilter/InstaVolt_grey.svg";
import KHT from "../image/networkFilter/KHT.svg";
import KHT_GREEN from "../image/networkFilter/KHT_green.svg";
import KHT_RED from "../image/networkFilter/KHT_red.svg";
import KHT_GREY from "../image/networkFilter/KHT_grey.svg";
import KROPEX from "../image/networkFilter/Kropex.svg";
import KROPEX_GREEN from "../image/networkFilter/Kropex_green.svg";
import KROPEX_RED from "../image/networkFilter/Kropex_red.svg";
import KROPEX_GREY from "../image/networkFilter/Kropex_grey.svg";
import NTPC from "../image/networkFilter/NTPC.svg";
import NTPC_GREEN from "../image/networkFilter/NTPC_green.svg";
import NTPC_RED from "../image/networkFilter/NTPC_red.svg";
import NTPC_GREY from "../image/networkFilter/NTPC_grey.svg";
import OLA from "../image/networkFilter/Ola.svg";
import OLA_GREEN from "../image/networkFilter/Ola_green.svg";
import OLA_RED from "../image/networkFilter/Ola_red.svg";
import OLA_GREY from "../image/networkFilter/Ola_grey.svg";
import REIL from "../image/networkFilter/REIL.svg";
import REIL_GREEN from "../image/networkFilter/REIL_green.svg";
import REIL_RED from "../image/networkFilter/REIL_red.svg";
import REIL_GREY from "../image/networkFilter/REIL_grey.svg";
import TCIL from "../image/networkFilter/TCIL.svg";
import TCIL_GREEN from "../image/networkFilter/TCIL_green.svg";
import TCIL_RED from "../image/networkFilter/TCIL_red.svg";
import TCIL_GREY from "../image/networkFilter/TCIL_grey.svg";
import GENERIC from "../image/networkFilter/Generic.svg";
import GENERIC_GREEN from "../image/networkFilter/Generic_green.svg";
import GENERIC_RED from "../image/networkFilter/Generic_red.svg";
import GENERIC_GREY from "../image/networkFilter/Generic_grey.svg";
import EESL from "../image/networkFilter/EESL.svg";
import EESL_GREEN from "../image/networkFilter/EESL_green.svg";
import EESL_GREY from "../image/networkFilter/EESL_grey.svg";
import EESL_RED from "../image/networkFilter/EESL_red.svg";
import MINOOKI from "../image/networkFilter/Minooki.svg";
import MINOOKI_GREEN from "../image/networkFilter/Minooki_green.svg";
import MINOOKI_GREY from "../image/networkFilter/Minooki_grey.svg";
import MINOOKI_RED from "../image/networkFilter/Minooki_red.svg";
import PLUGZMART from "../image/networkFilter/PlugzMart.svg";
import PLUGZMART_GREEN from "../image/networkFilter/PlugzMart_green.svg";
import PLUGZMART_GREY from "../image/networkFilter/PlugzMart_grey.svg";
import PLUGZMART_RED from "../image/networkFilter/PlugzMart_red.svg";
import PGCIL from "../image/networkFilter/PGCIL.svg";
import PGCIL_GREEN from "../image/networkFilter/PGCIL_green.svg";
import PGCIL_GREY from "../image/networkFilter/PGCIL_grey.svg";
import PGCIL_RED from "../image/networkFilter/PGCIL_red.svg";
import DELTA from "../image/networkFilter/Delta.svg";
import DELTA_GREEN from "../image/networkFilter/Delta_green.svg";
import DELTA_GREY from "../image/networkFilter/Delta_grey.svg";
import DELTA_RED from "../image/networkFilter/Delta_red.svg";
import VerdeMobility from "../image/networkFilter/verde.svg";
import VerdeMobility_GREEN from "../image/networkFilter/verde_green.svg";
import VerdeMobility_GREY from "../image/networkFilter/verde_grey.svg";
import VerdeMobility_RED from "../image/networkFilter/verde_red.svg";
import BBMP from "../image/networkFilter/BBMP.svg";
import BBMP_GREEN from "../image/networkFilter/BBMP_green.svg";
import BBMP_GREY from "../image/networkFilter/BBMP_grey.svg";
import BBMP_RED from "../image/networkFilter/BBMP_red.svg";
import OEM from "../image/networkFilter/OEM.svg";
import OEM_GREEN from "../image/networkFilter/OEM_green.svg";
import OEM_GREY from "../image/networkFilter/OEM_grey.svg";
import OEM_RED from "../image/networkFilter/OEM_red.svg";
import Kazam from "../image/networkFilter/Kazam.svg";
import Kazam_GREEN from "../image/networkFilter/Kazam_green.svg";
import Kazam_GREY from "../image/networkFilter/Kazam_grey.svg";
import Kazam_RED from "../image/networkFilter/Kazam_red.svg";
import WidgetsIcon from '@mui/icons-material/Widgets';
import MoveonEV from "../image/networkFilter/MoveonEV.png"
import MoveonEV_GREEN from "../image/networkFilter/MoveonEV.png"
import MoveonEV_RED from "../image/networkFilter/MoveonEV.png"
import MoveonEV_GREY from "../image/networkFilter/MoveonEV.png"
import AmikCharge from "../image/networkFilter/AmikCharge.svg"
import AmikCharge_GREEN from "../image/networkFilter/AmikCharge.svg"
import AmikCharge_RED from "../image/networkFilter/AmikCharge.svg"
import AmikCharge_GREY from "../image/networkFilter/AmikCharge.svg"

import { openDB } from 'idb';
import Token from "../util/Token";
import HttpService from "../services/HttpService";
import Constant from './constants';

const { socketType, chargePointSpeed, vehicleSegment,networkFilter, imageMapper, googleMap, types } = Constant;

const homeServicelList = [
    {
        key: 1,
        name: WidgetsIcon
    },
    {
        key: 2,
        name: WidgetsIcon
    },
    {
        key: 3,
        name: WidgetsIcon
    },
    {
        key: 3,
        name: WidgetsIcon
    },
    {
        key: 3,
        name: WidgetsIcon
    }
]

const images = {
    [socketType.CCS_TYPE_2]: PlugCCSType2,
    [socketType.TYPE_1]: PlugType1,
    [socketType.TYPE_2]: PlugType2,
    [socketType.TYPE_1_AC]: PlugType1AC,
    [socketType.CHADEMO]: PlugChademo,
    [socketType.GBT]: PlugGbt,
    [socketType.PIN_3]: PlugType3Pin,
    [socketType.BHARAT_AC_001]: PlugType3Pin,
    [socketType.BHARAT_DC_001]: PlugGbt,
    [socketType.CCS_SAE]: PlugTypeCSSSAE,
    [socketType.CCS_TYPE_1]: PlugTypeCCSType1,
    [socketType.J1772]: PlugTypeJ1772,
    [socketType.THREE_Phase]: PlugTypeThreePhase,
    [socketType.WAll]: PlugTypeWall,
    [socketType.IEC60309]: PlugTypeIEC60309,
    [chargePointSpeed.SPEED_SLOW]: SlowPNG,
    [chargePointSpeed.SPEED_FAST]: SpeedFastPNG,
    [chargePointSpeed.SPEED_RAPID]: SpeedRapidPNG,
    [chargePointSpeed.SPEED_ULTRA_RAPID]: SpeedUltraRapidPNG,
    [vehicleSegment.SEGMENT_2_WHEELER]: EVTwoPNG,
    [vehicleSegment.SEGMENT_3_WHEELER]: EVThreePNG,
    [vehicleSegment.SEGMENT_4_WHEELER]: EVFourPNG,
    [vehicleSegment.SEGMENT_SWIPE]: EVSwipePNG,
    [vehicleSegment.SEGMENT_2_WHEELER_DISABLED]: EV16PNG,
    [vehicleSegment.SEGMENT_3_WHEELER_DISABLED]: EV18PNG,
    [vehicleSegment.SEGMENT_4_WHEELER_DISABLED]: EV15PNG,
    [vehicleSegment.SEGMENT_SWIPE_DISABLED]: EV19PNG,
    [vehicleSegment.SEGMENT_2_WHEELER_GENERIC]: twoWheelerGeneric,
    [vehicleSegment.SEGMENT_3_WHEELER_GENERIC]: threeWheelerGeneric,
    [vehicleSegment.SEGMENT_4_WHEELER_GENERIC]: fourWheelerGeneric,
    AARGO:AARGO,
    AARGO_GREEN:AARGO_GREEN,
    AARGO_RED:AARGO_RED,
    AARGO_GREY:AARGO_GREY,
    ATHER:ATHER,
    ATHER_GREEN:ATHER_GREEN,
    ATHER_RED:ATHER_RED,
    ATHER_GREY:ATHER_GREY,
    CHARGE_GRID:CHARGE_GRID,
    CHARGE_GRID_GREEN:CHARGE_GRID_GREEN,
    CHARGE_GRID_RED:CHARGE_GRID_RED,
    CHARGE_GRID_GREY:CHARGE_GRID_GREY,
    CHARGE_ZONE:CHARGE_ZONE,
    CHARGE_ZONE_GREEN:CHARGE_ZONE_GREEN,
    CHARGE_ZONE_RED:CHARGE_ZONE_RED,
    CHARGE_ZONE_GREY:CHARGE_ZONE_GREY,
    EFILL:EFILL,
    EFILL_GREEN:EFILL_GREEN,
    EFILL_RED:EFILL_RED,
    EFILL_GREY:EFILL_GREY,
    ELECTRIVA:ELECTRIVA,
    ELECTRIVA_GREEN:ELECTRIVA_GREEN,
    ELECTRIVA_RED:ELECTRIVA_RED,
    ELECTRIVA_GREY:ELECTRIVA_GREY,
    EVRE:EVRE,
    EVRE_GREEN:EVRE_GREEN,
    EVRE_RED:EVRE_RED,
    EVRE_GREY:EVRE_GREY,
    FORTUM:FORTUM,
    FORTUM_GREEN:FORTUM_GREEN,
    FORTUM_RED:FORTUM_RED,
    FORTUM_GREY:FORTUM_GREY,
    GOEGO:GOEGO,
    GOEGO_GREEN:GOEGO_GREEN,
    GOEGO_RED:GOEGO_RED,
    GOEGO_GREY:GOEGO_GREY,
    RELUX:RELUX,
    RELUX_GREEN:RELUX_GREEN,
    RELUX_RED:RELUX_RED,
    RELUX_GREY:RELUX_GREY,
    STATIQ:STATIQ,
    STATIQ_GREEN:STATIQ_GREEN,
    STATIQ_RED:STATIQ_RED,
    STATIQ_GREY:STATIQ_GREY,
    TATA:TATA,
    TATA_GREEN:TATA_GREEN,
    TATA_RED:TATA_RED,
    TATA_GREY:TATA_GREY,
    MINOOKI:MINOOKI,
    MINOOKI_GREEN:MINOOKI_GREEN,
    MINOOKI_GREY:MINOOKI_GREY,
    MINOOKI_RED:MINOOKI_RED,
    PLUGZMART:PLUGZMART,
    PLUGZMART_GREEN:PLUGZMART_GREEN,
    PLUGZMART_GREY:PLUGZMART_GREY,
    PLUGZMART_RED:PLUGZMART_RED,
    DELTA:DELTA,
    DELTA_GREEN:DELTA_GREEN,
    DELTA_GREY:DELTA_GREY,
    DELTA_RED:DELTA_RED,
    PGCIL:PGCIL,
    PGCIL_GREEN:PGCIL_GREEN,
    PGCIL_GREY:PGCIL_GREY,
    PGCIL_RED:PGCIL_RED,
    BBMP:BBMP,
    BBMP_GREEN:BBMP_GREEN,
    BBMP_GREY:BBMP_GREY,
    BBMP_RED:BBMP_RED,
    VOLTTIC:VOLTTIC,
    VOLTTIC_GREEN:VOLTTIC_GREEN,
    VOLTTIC_RED:VOLTTIC_RED,
    VOLTTIC_GREY:VOLTTIC_GREY,
    JIO_BP:JIO_BP,
    JIO_BP_GREEN:JIO_BP_GREEN,
    JIO_BP_RED:JIO_BP_RED,
    JIO_BP_GREY:JIO_BP_GREY,
    XOBOLT:XOBOLT,
    XOBOLT_GREEN:XOBOLT_GREEN,
    XOBOLT_GREY:XOBOLT_GREY,
    XOBOLT_RED:XOBOLT_RED,
    ZEON:ZEON,
    ZEON_GREEN:ZEON_GREEN,
    ZEON_GREY:ZEON_GREY,
    ZEON_RED:ZEON_RED,
    BECIL:BECIL,
    BECIL_GREEN:BECIL_GREEN,
    BECIL_GREY:BECIL_GREY,
    BECIL_RED:BECIL_RED,
    CHARGEMILES:CHARGEMILES,
    CHARGEMILES_GREEN:CHARGEMILES_GREEN,
    CHARGEMILES_GREY:CHARGEMILES_GREY,
    CHARGEMILES_RED:CHARGEMILES_RED,
    KROPEX:KROPEX,
    KROPEX_GREEN:KROPEX_GREEN,
    KROPEX_GREY:KROPEX_GREY,
    KROPEX_RED:KROPEX_RED,
    Kazam:Kazam,
    Kazam_GREEN:Kazam_GREEN,
    Kazam_GREY:Kazam_GREY,
    Kazam_RED:Kazam_RED,
    NTPC:NTPC,
    NTPC_GREEN:NTPC_GREEN,
    NTPC_GREY:NTPC_GREY,
    NTPC_RED:NTPC_RED,
    INSTAVOLT:INSTAVOLT,
    INSTAVOLT_GREEN:INSTAVOLT_GREEN,
    INSTAVOLT_GREY:INSTAVOLT_GREY,
    INSTAVOLT_RED:INSTAVOLT_RED,
    OLA:OLA,
    OLA_GREEN:OLA_GREEN,
    OLA_GREY:OLA_GREY,
    OLA_RED:OLA_RED,
    OEM:OEM,
    OEM_GREEN:OEM_GREEN,
    OEM_GREY:OEM_GREY,
    OEM_RED:OEM_RED,
    REIL:REIL,
    REIL_GREEN:REIL_GREEN,
    REIL_GREY:REIL_GREY,
    REIL_RED:REIL_RED,
    TCIL:TCIL,
    TCIL_GREEN:TCIL_GREEN,
    TCIL_GREY:TCIL_GREY,
    TCIL_RED:TCIL_RED,
    IOCL:IOCL,
    IOCL_GREEN:IOCL_GREEN,
    IOCL_GREY:IOCL_GREY,
    IOCL_RED:IOCL_RED,
    KHT:KHT,
    KHT_GREEN:KHT_GREEN,
    KHT_GREY:KHT_GREY,
    KHT_RED:KHT_RED,
    EESL:EESL,
    EESL_GREEN:EESL_GREEN,
    EESL_GREY:EESL_GREY,
    EESL_RED:EESL_RED,
    GENERIC:GENERIC,
    GENERIC_GREEN:GENERIC_GREEN,
    GENERIC_GREY:GENERIC_GREY,
    GENERIC_RED:GENERIC_RED,
    VERDE_MOBILITY: VerdeMobility,
    VERDE_MOBILITY_GREEN: VerdeMobility_GREEN,
    VERDE_MOBILITY_RED: VerdeMobility_RED,
    VERDE_MOBILITY_GREY: VerdeMobility_GREY,
    MOVEONEV:MoveonEV,
    MOVEONEV_RED:MoveonEV_RED,
    MoveonEV_GREEN:MoveonEV_GREEN,
    MOVEONEV_GREY:MoveonEV_GREY,
    AMIKCHARGE:AmikCharge,
    AMIKCHARGE_RED:AmikCharge_RED,
    AMIKCHARGE_GREEN:AmikCharge_GREEN,
    AMIKCHARGE_GREY:AmikCharge_GREY,
    [imageMapper.FAULT_CONNECTOR_IMAGE]: FaultConnectorImage,
    [imageMapper.ICON_14]: Icon14,
    [imageMapper.ICON_15]: Icon15,
    [imageMapper.ICON_16]: Icon16,
    [imageMapper.ICON_17]: Icon17
}

export const getImage = (key) => images[key];

export const socketTypeList = [
    { type: socketType.CCS_TYPE_2, value:"connector1", src: <PlugCCSType2 /> , id: 1 },
    { type: socketType.TYPE_2 , value: "connector2", src: <PlugType2 />, id: 2 },
    { type: socketType.CHADEMO, value:  "connector3", src: <PlugChademo />, id: 3 },
    { type: socketType.TYPE_1, value: "connector4", src: <PlugType1 />, id: 4 },
    { type: socketType.TYPE_1_AC, value: "connector5", src: <PlugType1AC />, id: 5 },
    { type: socketType["3_PIN"], value:"connector6", src: <PlugType3Pin />, id: 6 },
    { type: socketType.GBT, value: "connector7", src: <PlugGbt />, id: 7 },
    { type: socketType.CCS_TYPE_1, value: "ccstype1", src: <PlugTypeCCSType1 />, id: 8 },
    { type: socketType.CCS_SAE, value: "ccssae", src: <PlugTypeCSSSAE />, id: 9 },
    { type: socketType.THREE_Phase, value: "threephase", src: <PlugTypeThreePhase />, id: 10},
    { type: socketType.WAll, value: "wall", src: <PlugTypeWall />, id: 11 },
    { type: socketType.J1772, value:"j1772", src: <PlugTypeJ1772 />, id: 12 },
    { type: socketType.IEC60309, value: "ie60309", src: <PlugTypeIEC60309 />, id: 13 }
    // { type: socketType.BHARAT_AC_001, value: 'connector7', src: getImage(socketType.BHARAT_AC_001), id: 8 },
    // { type: socketType.BHARAT_DC_001, value: 'connector7', src: getImage(socketType.BHARAT_DC_001), id: 9 },
]

export const chargePointSpeedList = [
    { type: chargePointSpeed.SPEED_SLOW, value: 'power1', src: getImage(chargePointSpeed.SPEED_SLOW), id: 1, kw: '< 7kw', alt: 'Slow charger icon', text: 'Slow' },
    { type: chargePointSpeed.SPEED_FAST, value: 'power2', src: getImage(chargePointSpeed.SPEED_FAST), id: 2, kw: '7-22kw', alt: 'Fast charger icon', text: 'Fast', },
    { type: chargePointSpeed.SPEED_RAPID, value: 'power3', src: getImage(chargePointSpeed.SPEED_RAPID), id: 3, kw: '22-50kw', alt: 'Rapid carger icon', text: 'Rapid' },
    { type: chargePointSpeed.SPEED_ULTRA_RAPID, value: 'power4', src: getImage(chargePointSpeed.SPEED_ULTRA_RAPID), id: 4, kw: '> 50kw', alt: 'Ultra Rapid charger icon', text: 'Ultra Rapid', }
]

export const networkFilterList = Object.entries(networkFilter).map(([key,value])=>({name:value,src:getImage(key),activeSrc:getImage(`${key}_GREEN`)?getImage(`${key}_GREEN`):null,inActiveSrc:getImage(`${key}_RED`)?getImage(`${key}_RED`):null,unknownSrc:getImage(`${key}_GREY`)?getImage(`${key}_GREY`):null}))

export const vehicleSegmentList = [
    { id: 2, src: getImage(vehicleSegment.SEGMENT_2_WHEELER), disableSrc: getImage(vehicleSegment.SEGMENT_2_WHEELER_DISABLED), ariaLabel: 'Two vehicle segment', marker: MarkerGreenPNG, checkboxLabel: 'Two wheeler', name: vehicleSegment.SEGMENT_2_WHEELER, value: vehicleSegment.SEGMENT_2_WHEELER  },
    { id: 3, src: getImage(vehicleSegment.SEGMENT_3_WHEELER), disableSrc: getImage(vehicleSegment.SEGMENT_3_WHEELER_DISABLED), ariaLabel: 'Three vehicle segment', marker: MarkerBluePNG, checkboxLabel: 'Three wheeler', name: vehicleSegment.SEGMENT_3_WHEELER, value: vehicleSegment.SEGMENT_3_WHEELER },
    { id: 4, src: getImage(vehicleSegment.SEGMENT_4_WHEELER), disableSrc: getImage(vehicleSegment.SEGMENT_4_WHEELER_DISABLED), ariaLabel: 'Four vehicle segment', marker: MarkerOrangePNG, checkboxLabel: 'Four wheeler', name: vehicleSegment.SEGMENT_4_WHEELER, value: vehicleSegment.SEGMENT_4_WHEELER },
    { id: 5, src: getImage(vehicleSegment.SEGMENT_SWIPE), disableSrc: getImage(vehicleSegment.SEGMENT_SWIPE_DISABLED), ariaLabel: 'Swipe segment', marker: MarkerRedPNG, checkboxLabel: 'Swapping', name: vehicleSegment.SEGMENT_SWIPE, value: vehicleSegment.SEGMENT_SWIPE }
]

export const genericVehicleSegmentList = [
    { id: 2, src: getImage(vehicleSegment.SEGMENT_2_WHEELER_GENERIC), name: vehicleSegment.SEGMENT_2_WHEELER_GENERIC, value: vehicleSegment.SEGMENT_2_WHEELER_GENERIC  },
    { id: 3, src: getImage(vehicleSegment.SEGMENT_3_WHEELER_GENERIC), name: vehicleSegment.SEGMENT_3_WHEELER_GENERIC, value: vehicleSegment.SEGMENT_3_WHEELER_GENERIC },
    { id: 4, src: getImage(vehicleSegment.SEGMENT_4_WHEELER_GENERIC),  name: vehicleSegment.SEGMENT_4_WHEELER_GENERIC, value: vehicleSegment.SEGMENT_4_WHEELER_GENERIC },
]

export const reportIssuesList = [
    { id: 1, value: 'LOCATION', text: 'Issue with charger location' },
    { id: 2, value: 'DETAIL', text: 'Charger details are incorrect' },
    { id: 3, value: 'CARD', text: 'Payment not working' },
    { id: 4, value: 'CABLE', text: 'My cable is stuck' }
]

export const getHomeServicelList = () => {
    return homeServicelList;
}

export const getConnectors = (devices) => {
    let connectorWithCount = [];
    //let connector, power, vSegment;
    let cType = {}

    if (devices?.length > 0) {
        for (let i = 0; i < devices?.length; i++) {
            if (devices[i]?.stations?.length > 0) {
                for (let j = 0; j < devices[i]?.stations?.length; j++) {
                    if (devices[i]?.stations[j]?.connector) {
                        let connectorType = devices[i]?.stations[j]?.connector;
                        if (cType[connectorType]) {
                            cType[connectorType] = cType[connectorType] + 1;
                        } else {
                            cType[connectorType] = 1;
                        }
                    }else{
                        let defaultConnectorType = "connector6";

                        if (cType[defaultConnectorType]) {
                            cType[defaultConnectorType] = cType[defaultConnectorType] + 1;
                        } else {
                            cType[defaultConnectorType] = 1;
                        }
                    }
                }
            }
        }
    }
    let connectors = Object.keys(cType);
    if (connectors?.length > 0) {
        for (let i = 0; i < connectors?.length; i ++) {
            let connector = socketTypeList.find(socket => socket.value === connectors[i] || Number(socket.id)===Number(connectors[i]));
            // console.log(`connectorID:${connectors[i]}, connectorType: ${connector}`);
            connectorWithCount.push({
                totalPoints: cType[connectors[i]],
                connectorTypeImage: connector ? connector.src : PlugCCSType2PNG,
                vehicleSegmentImage: DefaultMarkerPNG,
                power: '44'
            })
        }
    }
    connectors.push({
        totalPoints: 5,
        connectorTypeImage: PlugCCSType2PNG,
        vehicleSegmentImage: DefaultMarkerPNG, 
        power: '44'
    })
    return connectorWithCount;
}

export const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
        if('navigator' in window){
            const geolocation = window.navigator.geolocation;
            geolocation.getCurrentPosition(async (pos) => {
                resolve(pos.coords);
            }, (err) => {
                reject(err);
            })
        }
    })
}

export const getGoogleMapUrl = (query) => {
    return `${process.env.REACT_APP_GOOGLE_MAP_SEARCH_API}=${encodeURIComponent(query)}`
}

export const memoize = (func) => {
    const memoizeCache = [];
    return async (...args) => {
        let result = memoizeCache.find((memo) => memo.args === JSON.stringify(args));
        if (result) {
            return Promise.resolve(result.value);
        }

        result = await func.apply(this, args);
        memoizeCache.push({
            args: JSON.stringify(args),
            value: result
        });
        return Promise.resolve(result);
    }
}

export const constructDirMapUrl = (obj) => {
    return new Promise((resolve) => {
        const url = new URL(process.env.REACT_APP_BASE_GOOGLE_DIR_MAP_API);
        const { google, mapInstance, directionRendererInstance } = window;
        const { places:{ PlacesService } } = google.maps;
        const service = new PlacesService(mapInstance);
        const directions = directionRendererInstance.getDirections();
        let query = {
            travelMode: 'driving',
            zoom: mapInstance.zoom,
            ...obj
        };
        if(directions) {
            const { routes, geocoded_waypoints } = directions;
            const legs = routes[0]?.legs;
            const arrGC = geocoded_waypoints.map(geocode => geocode.place_id);
            const waypoints_ids = arrGC.filter((f, idx) => idx !== 0 && idx !== arrGC.length - 1).join('|');
            const waypoints = legs.length > 1 && legs.map((leg, i) => (i !== legs.length - 1) ? leg.end_address : '');
            waypoints && waypoints.pop();
            query = {
                ...query,
                destination: legs[legs.length-1].end_address,
                origin: legs[0].start_address,
                destination_place_id: arrGC[arrGC.length-1],
                origin_place_id: arrGC[0],
                ...(types.EMPTY !== waypoints_ids && waypoints && { 
                    waypoint_place_ids: waypoints_ids, 
                    waypoints: waypoints.join('|') 
                })
            }
            for(let [k, v] of Object.entries(query)) {
                url.searchParams.set(k, v);
            }
            resolve(url.href);
        }

        if (!directions) {
            service.findPlaceFromQuery({
                fields: ['geometry', 'place_id', 'formatted_address'],
                query: obj.destination
            }, (result) => {
                if (result) {
                    url.searchParams.set('destination_place_id', result[0].place_id);
                    for(let [k, v] of Object.entries(query)) {
                        url.searchParams.set(k, v);
                    }
                    resolve(url.href);
                }
                if(!result) resolve('');
            });
        }
    });
};

export const getFBShareUrl = (shareUrl) => {
    return process.env.REACT_APP_FB_CONNECT_URL.replace('SHARE_URL', encodeURIComponent(shareUrl))
}

export const sleep = (timeout) => new Promise(resolve => setTimeout(resolve, timeout));

export const toggleFavourite = async (a, u, s, _id) => {
    const { authenticated, setAuthenticated } = a;
    
    if(!authenticated.isAuth){
      setAuthenticated({ 
        ...authenticated, 
        authPopup: !0, 
        redirectTo: Constant.paths.HOME
      });
      return;
    }

    const { data } = Token.parsedToken();
    const { methods: { ADD_FAVOURITES, REMOVE_FAVOURITES } } = Constant;
    const method = !s.favourite ? ADD_FAVOURITES : REMOVE_FAVOURITES;
    const response = await HttpService(
      method, {
      userId: data._id,
      locationId: _id
    })
    
    if(response.status){
      u.setUserDetail((prev) => ({
        ...prev,
        favoriteLocations: response.data.favoriteLocations
      }));
    }
}

export const resetRoutes = (setMapZoom = true) => {
    const { directionRendererInstance, markerClustererInstance, mapInstance } = window;
    directionRendererInstance.set('directions', null);
    markerClustererInstance.setMaxZoom(googleMap.CLUSTER_DEFAULT_MAX_ZOOM);
    markerClustererInstance.repaint();
    setMapZoom && mapInstance.setZoom(googleMap.DEFAULT_ZOOM);
}

export const getDistance = (total) => {
    const _dist = (total / 1000).toFixed(1);
    const [km, m] = _dist.split('.');
    return Number(m) ? Math.ceil(_dist)+' km' : km+' km';
}

export const getDuration = (mins) => {
    mins = mins / 60;
    const d = Math.floor(mins / (1440)) // 60 * 24 = 1440;
    const h = Math.floor((mins - (d * 1440)) / 60);
    const m = Math.round(mins % 60);
    if (d > 0) return `${d} ${d === 1 ? 'day' : 'days'} ${h} hours`;
    if (h > 0) return `${h} ${h === 1 ? 'hour' : 'hours'} ${m} mins`;
    return `${m} mins`;
}

export const hasAddedToTrip = (lat, lng) => {
    const { directionRendererInstance } = window;
    const direction = directionRendererInstance.getDirections();
    const { request: { waypoints } } = direction;
    return waypoints.some(({ location }) => location?.location.lat() === lat 
        && location?.location.lng() === lng)
}

const idb = openDB(Constant.openDB.name, Constant.openDB.version, {
    upgrade(db){
        db.createObjectStore(Constant.openDB.objectStore);
    }
})

export async function get(key){
    return (await idb).get(Constant.openDB.objectStore, key);
}

export async function set(key, val){
    return (await idb).put(Constant.openDB.objectStore, val, key);
}

export async function del(key){
    return (await idb).delete(Constant.openDB.objectStore, key)
}

export async function clear(){
    return (await idb).clear(Constant.openDB.objectStore)
}

export async function keys(){
    return (await idb).getAllKeys(Constant.openDB.objectStore)
}