const cmsBaseUrl = process.env.REACT_APP_CHARGEMILES_CMS_END_POINT;
const cmsRestBaseUrl = process.env.REACT_APP_CHARGEMILES_CMS_REST_END_POINT;
const apiEndPoint = process.env.REACT_APP_API_END_POINT
// console.log(cmsBaseUrl);
export const cmsBaseUrlV1 = `${cmsBaseUrl}/v1`;
export const chargerUrl = (chargerId, query) => `${cmsBaseUrlV1}/charger/${chargerId}${query}`;
export const liveConnectorUrl = (chargerId) => `${cmsRestBaseUrl}/rest/${chargerId}/connectorIds`;
export const sendOtp = `${cmsBaseUrlV1}/otp/send`;
export const verifyOtp = `${cmsBaseUrlV1}/otp/verify`;
export const chargerStatus = `${cmsBaseUrlV1}/charger/status`;
export const chargerStop = `${cmsBaseUrlV1}/charger/stop`;
export const chargerStart = `${cmsBaseUrlV1}/charger/start`;
export const deleteVehicleUrl =(vehicleId)=> `${apiEndPoint}user/delete-vehicle?id=${vehicleId}`;
export const chargerTransactionActivityUrl = (endPoint) => `${cmsBaseUrlV1}/status/${endPoint}`; 
export const transactionRedirectionReq = (endPoint) => `${cmsBaseUrlV1}/status/${endPoint}`; 
