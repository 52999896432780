import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Header from "./Header";
import Map from "./Map";
import AuthPopup from "../authentication/AuthPopup";

export default function ChargeMiles(props) {
  //const classes = useStyles();
  
  return (
    <React.Fragment>
      <CssBaseline />
      <Map />
      <AuthPopup />
    </React.Fragment>
  );
}
