import { Box, Button, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import "./CMBreadCrumb.css";

export const CMBreadCrumb = ({ prevPage, currPage }) => {
  return (
    <Box className="cm-breadcrumbs mb-28" marginTop="56px">
      <div className="pt-breadcrumb">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="pt-breadcrumb-title">
                <Typography
                  variant="h2"
                  gutterBottom
                  component="div"
                  color="#ffffff"
                  fontSize={{ md: "56px", xs: "36px" }}
                  fontWeight={500}
                  fontFamily="Futura"
                  lineHeight={"64px"}
                  mb={0}
                >
                  {currPage}
                </Typography>
              </div>
              <div className="pt-breadcrumb-container">
                <ol className="breadcrumb">
                  <li>
                    <Button
                      href="https://chargemiles.ca"
                      startIcon={<HomeIcon />}
                      sx={{ textTransform: "none", fontWeight: 700 }}
                    >
                      {prevPage}
                    </Button>
                  </li>
                  <li className="active">{currPage}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
