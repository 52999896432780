import { Typography } from "@mui/material";
import {  useDomainConfig } from "../../hooks/useDomainConfig";

export const DomainText = () => {
  const domainConfig = useDomainConfig();
  return (
    <Typography component="span" sx={{ textTransform: "capitalize" }}>
      {domainConfig.title}
    </Typography>
  );
};
