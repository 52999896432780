import { Box, Button, CircularProgress, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { paymentStyles } from "./payment.constants";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../react-query/queryKeys";
import { fetchWithError } from "../../../react-query/fetchWithError";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  validateEmail,
  validatePhone,
} from "../../../util/phoneEmailValidation";
import { useDomainConfig } from "../../../hooks/useDomainConfig";

export const PaymentStatusContainer = ({
  status,
  message,
  chargerId,
  connectorId,
  email,
}) => {
  const navigate = useNavigate();
  const domainConfig = useDomainConfig();
  const [stopCharger, setStopCharger] = useState(false);
  const { isFetching, isError } = useQuery(
    queryKeys.chargerStatus({
      domain: domainConfig.domain,
      chargerId,
      connectorId,
      email: validateEmail(email) ? email : "",
      phone: validatePhone(email) ? email : "",
    }),
    fetchWithError,
    {
      enabled: status,
      retryDelay: 20000,
      retry: (failureCount, error) => {
        if (failureCount > 9) {
          return false;
        }
        return true;
      },
      onSuccess: (data) => {
        const { token } = data;
        navigate(`/status?token=${token}`);
      },
    }
  );
  const { isFetching: isStopFetching, isError: isStopError } = useQuery(
    queryKeys.stopCharger({ chargerId, connectorId }),
    fetchWithError,
    {
      enabled: stopCharger,
      retry: 2,
    }
  );
  return (
    <Box
      className="tabs-parent"
      sx={{
        ...paymentStyles.tabParent,
        height: "400px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {status && (isFetching || isStopFetching) && (
        <CircularProgress sx={{ marginBottom: "30px" }} />
      )}
      {(!status || isError || isStopError) && (
        <CancelIcon
          sx={{ color: "red", fontSize: "50px", marginBottom: "30px" }}
        />
      )}
      {stopCharger && !isStopFetching && !isStopError && (
        <CheckCircleIcon
          sx={{ color: "green", fontSize: "50px", marginBottom: "30px" }}
        />
      )}
      {(!status || isFetching) && <Typography>{message}</Typography>}
      {status && !isFetching && !isError && !stopCharger && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon
            sx={{ color: "green", fontSize: "50px", marginBottom: "30px" }}
          />
          <Typography>
            Charger has been started successfully. Please plugin the connector
            to your car.
          </Typography>
          <p>
            Click here to stop the charger{" "}
            <Button
              variant="contained"
              type="button"
              onClick={() => setStopCharger(true)}
            >
              Stop
            </Button>
          </p>
        </Box>
      )}
      {status && !isFetching && isError && (
        <Typography>
          Unable to start the charger. Please contact support team.
        </Typography>
      )}
      {stopCharger && !isStopFetching && isStopError && (
        <Typography>
          Unable to stop the charger. Please contact support team.
        </Typography>
      )}
      {stopCharger && !isStopFetching && !isStopError && (
        <Typography>Charger has been stop successfully.</Typography>
      )}
    </Box>
  );
};
