import {
  Alert,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  Button,
  Link,
  TextField,
  ImageList,
  ImageListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import redirectFill from "@iconify/icons-eva/arrow-back-fill";
import {
  getImage,
  reportIssuesList,
  socketTypeList,
} from "../../../util/Display";
import { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Constant from "../../../util/constants";
import Page from "../../Page";
import MarkerInformationTab from "../MarkerInformationTab";
import { StripeCheckout } from "./StripeCheckout";
import { PaymentForm } from "./PaymentForm";
import { PaymentStatusContainer } from "./PaymentStatusContainer";
import { paymentStyles } from "./payment.constants";
import { useCharger } from "../../../react-query/hooks/useCharger";
import { EmailComponent } from "./EmailComponent";
import { Context } from "../../../context";
import { is } from "../../../util/is";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as CallUs } from "../../../image/chargerStationIcons/callUs.svg";
import { ReactComponent as ReportIssue } from "../../../image/chargerStationIcons/reportIssue.svg";
import { ReactComponent as EvStation } from "../../../image/chargerStationIcons/ev-station.svg";
import { ReactComponent as Info } from "../../../image/chargerStationIcons/info.svg";
import { ReactComponent as UnkownMsgInfo } from "../../../image/chargerStationIcons/unknownMsgIcon.svg";
import { ReactComponent as PhotosIcon } from "../../../image/chargerStationIcons/photosIcon.svg";
import { Icon } from "@iconify/react";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChargemilesLogo from "../../../image/networkFilter/Chargemiles.png";

import { useTheme } from "@emotion/react";
import { MuiSnackBar } from "../../@material-extend";
import Token from "../../../util/Token";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDomainConfig } from "../../../hooks/useDomainConfig";

const { imageMapper } = Constant;

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
}));

const TabItem = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    minWidth: "0",
  },
}));

const IssueButton = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  width: "100%",
}));

const a11yProps = (index) => {
  return {
    id: `charger-station-simple-tab-${index}`,
  };
};
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const ChargerInformation = ({ locationDetail }) => {
  const params = useParams();
  const location = useLocation();
  const domainConfig = useDomainConfig();
  const chargerId = params.chargerId;
  const connectorId = params.connectorId;
  const {
    data: charger,
    isFetching,
    isError,
    error,
  } = useCharger(chargerId, location.search);
  // const { data: connectors = [1, 2] } = useLiveConnectors(chargerId);
  const { user } = useContext(Context);
  const [isGuest, setGuest] = useState(false);
  const [email, setEmail] = useState("");
  // const [customerName, setCustomerName] = useState("");
  const [clientId, setClientId] = useState("");
  const [value, setValue] = useState(0);
  const [connector, setConnector] = useState();
  const [showCardInfo, setShowCardInfo] = useState(false);
  const [showConnectorMsg, setShowConnectorMsg] = useState(false);
  const [chargerInfo, setChargerInfo] = useState({});
  const [reportIssue, setReportIssue] = useState(false);
  const [chargerData, setChargerData] = useState();
  const [networkFilters, setNetworkFilter] = useState([]);
  const [reportIssueData, setReportIssueData] = useState({
    issue: "",
    message: "",
    otherIssue: false,
    category: "",
  });
  const [faqData, setFaqData] = useState();
  const [reportStat, setReportStat] = useState({
    open: false,
    variant: "",
    message: "",
  });
  const [photoModal, setPhotoModal] = useState({ open: false, src: "" });

  const handleChange = (_, newValue) => {
    setReportIssue(false);
    setValue(newValue);
  };
  const [paymentStatus, setPaymentStatus] = useState({
    isDone: false,
    status: false,
    message: "",
  });

  const clientHandler = (showCardInfo, clientId) => {
    clientId && setClientId(clientId);
    setShowCardInfo(showCardInfo);
  };

  useEffect(() => {
    if (charger && charger.amenities) {
      let amenities = charger.amenities.split(",");
      chargerInfo.amenities = amenities || [];
      setChargerInfo(chargerInfo);
    }

    if (charger && charger?.connectors?.length > 0 && charger.status.toUpperCase() === 'A') {
        charger.connectors.forEach((connector) => {
          if (
            connector?.connector_id === parseInt(connectorId) &&
            (connector?.status.toUpperCase() === "AVAILABLE" ||
              connector?.status.toUpperCase() === "PREPARING")
          ) {
            setConnector(connector);
          }
        });
    } else if (charger && charger?.connectors?.length > 0 && charger.status.toUpperCase() !== 'A') {
      charger.connectors.forEach((connector) => {
        connector.status = "UNAVAILABLE";
      });
    }
  }, [charger, connectorId]);

  useEffect(() => {
    setEmail(user?.userDetail?.email);
  }, [user]);

  useEffect(() => {
    const getFaqData = async (chargerBoxId) => {
      let baseUrl = `${process.env.REACT_APP_CHARGEMILES_CMS_END_POINT}/v1/charger/${chargerBoxId}/faq`;
      try {
        let response = await axios(`${baseUrl}`, { method: "get" });
        if (response.status === 200) {
          let faq = response.data.faqs;
          setFaqData(faq);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (charger?.chargepoint_id) {
      getFaqData(charger.chargepoint_id);
    } else return;
  }, [charger]);

  useEffect(() => {
    const fetchData = async () => {
      let baseUrl = `${process.env.REACT_APP_API_END_POINT}v2/location/get-detail`;
      baseUrl = `${baseUrl}?id=${charger.portalId}`;
      try {
        let response = await axios(`${baseUrl}`, { method: "get" });
        if (response.data.data) {
          setChargerData(response.data.data);
        }
      } catch (error) {
        console.error(error.response);
      }
    };

    if (charger?.portalId) {
      fetchData();
    } else return;
  }, [charger?.portalId]);

  useEffect(() => {
    const _fetchNetworkFilters = async () => {
      let url = `${process.env.REACT_APP_API_END_POINT}v2/location/get-provider`;

      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          let updatedNetworkArr = response.data.data;
          setNetworkFilter(updatedNetworkArr);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!(networkFilters.length > 0)) _fetchNetworkFilters();
  }, []);

  const onChangeOtherIssue = (e) => {
    e.preventDefault();
    setReportIssueData({
      ...reportIssueData,
      message: e.target.value,
      otherIssue: true,
    });
  };

  const onSubmitReportIssue = async () => {
    const report = ["LOCATION", "DETAIL", "CARD", "CABLE", "OTHER"];
    let payload = {
      issue: report[reportIssueData.issue],
      category: reportIssueData.category,
      comment: reportIssueData.message,
      locationId: chargerData?._id ? chargerData._id : "",
      charge_box_id: chargerData?.name ? chargerData?.name : "",
    };
    const { data } = Token.parsedToken();
    if (data._id) {
      payload = { ...payload, user: data._id };
    }

    if (reportIssueData.otherIssue) {
      try {
        let baseUrl = `${process.env.REACT_APP_CHARGEMILES_CMS_END_POINT}/v1/charger/${chargerData?.name}/report`;
        let response = await axios(`${baseUrl}`, {
          method: "post",
          data: payload,
        });
        if (response.status) {
          setReportStat({
            open: true,
            variant: "success",
            message: "Report Submitted Successfully",
          });
          setReportIssueData({
            issue: "",
            message: "",
            otherIssue: false,
            category: "",
          });
        }
      } catch (error) {
        setReportStat({
          open: true,
          variant: "error",
          message: "Something went wrong",
        });
        console.log(error);
      }
    } else return;
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const getIcon = () => {
    let provider = networkFilters.filter(
      (network) => network.name === chargerData.provider
    );
    if (!provider)
      provider = networkFilters.filter((network) => network.name === "GENERIC");

    return (
      <img
        style={{ height: "30px", width: "30px" }}
        src={provider[0]?.icon}
        alt="Logo"
      />
    );
  };
  return (
    <Page
      title="Dashboard | Charger-UI"
      sx={{ marginTop: { xs: "128px", md: "62px", overflow: "hidden" } }}
    >
      <Container
        sx={{
          padding: {
            xs: "0",
            sm: "0",
            md: "50px 24px",
            lg: "50px 24px",
            xl: "50px 24px",
          },
          width: "100%",
          margin: "auto",
        }}
        style={{ maxWidth: "500px" }}
      >
        {paymentStatus.isDone ? (
          <PaymentStatusContainer
            status={paymentStatus.status}
            message={paymentStatus.message}
            chargerId={chargerId}
            connectorId={
              !is.empty(connector?.ocpi_connector_id)
                ? connector?.ocpi_connector_id
                : connector?.connector_id
            }
            email={email}
          />
        ) : (
          <>
            <Box className="tabs-parent" sx={paymentStyles.tabParent}>
              {isError && (
                <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ marginBottom: "20px", color: "red" }}
                >
                  {error?.response?.data?.message ??
                    "Some error occured  while loading charger details"}
                </Alert>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                marginBottom={0}
              >
                {networkFilters && chargerData ? getIcon() : null}
                <Typography
                  variant="h3"
                  ml={1}
                  fontSize="20px"
                  fontWeight={600}
                >
                  {!is.empty(charger)
                    ? !is.empty(charger.chargepoint_name)
                      ? charger.chargepoint_name
                      : "N/A"
                    : ""}
                </Typography>
              </Stack>
              {!isError && (
                <Typography my={2} padding="0 10px" fontSize="14px">{`${
                  charger?.name ?? ""
                } ${charger?.house_number ?? ""} ${
                  charger?.street ?? ""
                }`}</Typography>
              )}
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="outlined"
                  disabled={
                    chargerData?.phone || chargerData?.alternatePhone
                      ? false
                      : true
                  }
                  disableRipple
                  sx={{
                    opacity:
                      chargerData?.phone || chargerData?.alternatePhone
                        ? "1"
                        : "0.5",
                    background: "#fff",
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Link
                    href={`tel: ${
                      chargerData?.phone
                        ? chargerData?.phone
                        : chargerData?.alternatePhone
                    }`}
                    color="inherit"
                    sx={{
                      display: "flex",
                      textDecoration: "none",
                      placeItems: "center",
                    }}
                  >
                    <CallUs fill="var(--app-color)" />
                    <Typography marginLeft={2}>Call Us</Typography>
                  </Link>
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setReportIssue(true)}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "20% 80%",
                    color: "#FFA726",
                    background: "#fff",
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                  }}
                >
                  <ReportIssue />
                  <Typography marginLeft={isSmallScreen ? 1 : 2}>
                    Report Issue
                  </Typography>
                </Button>
              </Stack>
            </Box>
            <Box
              sx={{
                ...paymentStyles.tabPaymentIcons,
                borderBottom: 1,
                borderColor: "divider",
                padding: "0",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon tabs example"
                centered
              >
                <TabItem
                  icon={<EvStation />}
                  aria-label="EV Station"
                  {...a11yProps(0)}
                />
                <TabItem
                  icon={<Info />}
                  aria-label="Information"
                  {...a11yProps(1)}
                />
                <TabItem
                  icon={<UnkownMsgInfo />}
                  aria-label="Unknown Chat Box"
                  {...a11yProps(2)}
                />
                <TabItem
                  icon={<PhotosIcon />}
                  aria-label="Photos"
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>
            {!reportIssue && (
              <TabPanel
                value={value}
                index={0}
                className="tab-list tab-location"
              >
                <Box className="tabs-parent" sx={paymentStyles.tabParent}>
                  <Typography
                    variant="h3"
                    textAlign="center"
                    mb={2}
                    fontSize="16px"
                    fontWeight={"600"}
                  >
                    Select, Pay & Charge
                  </Typography>
                  <List
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: isSmallScreen ? "1rem" : "2rem",
                    }}
                  >
                    {charger &&
                      charger?.connectors?.map((item, i) => (
                        <>
                          {item && (
                            <ListItem
                              sx={{
                                padding: "0px",
                                cursor: "pointer",
                              }}
                              key={item.connector_id}
                              onClick={() =>
                                (item.status.toUpperCase() === "AVAILABLE" ||
                                item.status.toUpperCase() === "PREPARING"
                                  ? setConnector(item)
                                  : setConnector()) ||
                                (item.status.toUpperCase() === "AVAILABLE" ||
                                item.status.toUpperCase() === "PREPARING"
                                  ? setShowConnectorMsg(true)
                                  : setShowConnectorMsg(false))
                              }
                              // selected={
                              //   connector?.connector_id === item.connector_id
                              // }
                              // disabled={item.status !== "Available"}
                            >
                              <Paper
                                elevation={0}
                                sx={{
                                  // ...paymentStyles.listItemPaper,
                                  width: "100%",
                                  height: "100%",
                                  // backgroundColor:
                                  //   connector?.connector_id === item.connector_id
                                  //     ? "#76BC45"
                                  //     : "",
                                  display: "grid",
                                  gridTemplateColumns: "20% 80%",
                                  borderRadius: "8px",
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    margin: "0",
                                    ...(item.connector_id !==
                                    connector?.connector_id
                                      ? {
                                          background: "#D6D6D6",
                                          color: "#929292",
                                        }
                                      : {
                                          background: "#76BC45",
                                          color: "#fff",
                                        }),
                                    borderRadius: "8px 0 0 8px",
                                  }}
                                  className="connector-item-text"
                                  primary={
                                    item?.connectorName
                                      ? item.connectorName
                                      : item.connector_id
                                  }
                                />
                                <div className="charger-detail-content">
                                  <Box
                                    className="charger-type-conector"
                                    sx={{ gap: isSmallScreen ? "0" : "6px" }}
                                  >
                                    <span
                                      className={
                                        item.connector_id ===
                                        connector?.connector_id
                                          ? "charger-connector-image-select"
                                          : "charger-connector-image-unselect"
                                      }
                                    >
                                      {item.connectorType ? (
                                        <span
                                          className={
                                            showConnectorMsg &&
                                            item.connector_id ===
                                              connector.connector_id
                                              ? "connector_type_img_available"
                                              : "connector_type_img_unavailable"
                                          }
                                        >
                                          {
                                            socketTypeList.find(
                                              (socket) =>
                                                socket.value ===
                                                (item.connectorType ===
                                                  "null" || !item.connectorType
                                                  ? "connector1"
                                                  : item.connectorType)
                                            ).src
                                          }
                                        </span>
                                      ) : (
                                        getImage(
                                          imageMapper.FAULT_CONNECTOR_IMAGE
                                        )
                                      )}
                                    </span>
                                    <div>
                                      <ListItemText
                                        className="item_text_type"
                                        sx={{
                                          textAlign: "center",
                                          color:
                                            item.connector_id !==
                                            connector?.connector_id
                                              ? "#929292"
                                              : "#76BC45",
                                        }}
                                        primary={`${
                                          socketTypeList.find(
                                            (socket) =>
                                              socket.value ===
                                              (item.connectorType === "null" ||
                                              !item.connectorType
                                                ? "connector1"
                                                : item.connectorType)
                                          ).type || "N/A"
                                        }`}
                                      />
                                      <ListItemText
                                        className="item_text_kw"
                                        sx={{
                                          textAlign: "center",
                                          color:
                                            item.connector_id !==
                                            connector?.connector_id
                                              ? "#929292"
                                              : "#76BC45",
                                        }}
                                        primary={`${item.kw || "N/A"}KW`}
                                      />
                                    </div>
                                  </Box>
                                  <ListItemText
                                    className="item_text_status"
                                    sx={{
                                      textAlign: "center",
                                      color:
                                        item.status.toUpperCase() ===
                                          "AVAILABLE" ||
                                        item.status.toUpperCase() ===
                                          "PREPARING"
                                          ? "#76BC45"
                                          : "#E84037",
                                    }}
                                    primary={item.status}
                                  />
                                </div>
                              </Paper>
                            </ListItem>
                          )}
                        </>
                      ))}
                    {charger &&
                      charger?.evses?.map((evse) => (
                        <ListItem
                          sx={{
                            padding: "0px",
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                          key={evse.evse_id}
                          onClick={() =>
                            evse.status === "Available" && setConnector(evse)
                          }
                          selected={connector?.uid === evse.uid}
                          disabled={evse.status !== "Available"}
                        >
                          <Paper
                            elevation={0}
                            sx={{
                              ...paymentStyles.listItemPaper,
                              backgroundColor:
                                connector?.uid === evse.uid
                                  ? "#76BC45"
                                  : "#E0E0E0",
                            }}
                          >
                            <span className="charger-connector-image">
                              <img
                                src={getImage(
                                  imageMapper.FAULT_CONNECTOR_IMAGE
                                )}
                                style={paymentStyles.listPaperImage}
                                alt={"img"}
                              />
                            </span>
                            <ListItemText
                              className="item-text"
                              sx={{ textAlign: "center" }}
                              primary={`${
                                (
                                  (1 *
                                    evse.connectors[0].voltage *
                                    evse.connectors[0].amperage) /
                                  1000
                                ).toFixed(3) || "N/A"
                              }KW`}
                            />
                            {/* <ListItemText
                                className="item-text"
                                sx={{ textAlign: "center" }}
                                primary={`${evse.connectors[0].voltage || "N/A"}V`}
                              /> */}
                            <ListItemText
                              className="item-text"
                              sx={{ textAlign: "right", fontWeight: 600 }}
                              primary={evse.status}
                            />
                          </Paper>
                        </ListItem>
                      ))}
                    {(isFetching || isError) && (
                      <>
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            ...paymentStyles.listItemPaper,
                            height: "90px",
                            borderRadius: "10px",
                            marginBottom: "16px",
                          }}
                        />
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            ...paymentStyles.listItemPaper,
                            height: "90px",
                            borderRadius: "10px",
                            marginBottom: "16px",
                          }}
                        />
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            ...paymentStyles.listItemPaper,
                            height: "90px",
                            borderRadius: "10px",
                          }}
                        />
                      </>
                    )}
                  </List>
                  {showConnectorMsg && (
                    <Typography sx={{ mt: 2, color: "#E84037" }}>
                      Note - Please connect charging gun to the EV.
                    </Typography>
                  )}
                </Box>
              </TabPanel>
            )}

            {reportIssue && (
              <Box className="tabs-parent" sx={paymentStyles.tabParent}>
                <Stack direction="row" mb={2}>
                  <Icon
                    icon={redirectFill}
                    width={24}
                    height={24}
                    onClick={() => setReportIssue(false)}
                  />
                  <Typography component="h3" sx={{ width: "100%" }}>
                    Report an issue
                  </Typography>
                </Stack>
                <Typography>
                  Please select an issue that you've encountered:
                </Typography>
                <Stack
                  direction="column"
                  spacing={1.6}
                  sx={{
                    margin: isSmallScreen ? "1rem 1rem 0" : "1rem 2rem 0",
                    placeItems: "center",
                  }}
                >
                  {reportIssuesList.map((list, index) => {
                    return (
                      <IssueButton
                        key={list.id}
                        variant="outlined"
                        className={
                          reportIssueData.issue === index
                            ? "selected_report_issue"
                            : ""
                        }
                        sx={{ background: "#fff", textTransform: "capitalize" }}
                        onClick={() =>
                          setReportIssueData({
                            issue: index,
                            otherIssue: true,
                            category: list.text,
                          })
                        }
                      >
                        {list.text}
                      </IssueButton>
                    );
                  })}
                  <IssueButton
                    variant="outlined"
                    className={
                      reportIssueData.issue === 4 ? "selected_report_issue" : ""
                    }
                    sx={{ background: "#fff", textTransform: "capitalize" }}
                    onClick={() =>
                      setReportIssueData({
                        issue: 4,
                        otherIssue: true,
                        category: "Other",
                      })
                    }
                  >
                    Other
                  </IssueButton>
                  {reportIssueData.otherIssue && (
                    <Paper
                      sx={{ padding: "1rem" }}
                      component="div"
                      elevation={0}
                      variant="outlined"
                      square
                    >
                      <Typography variant="body2" mb={1}>
                        {Constant.label.ISSUE_PARA}
                      </Typography>
                      <TextField
                        id="others-issue"
                        placeholder="Placeholder"
                        value={reportIssueData.message}
                        multiline
                        rows={3}
                        fullWidth
                        onChange={(e) => onChangeOtherIssue(e)}
                        // sx={styles.textArea}
                      />
                    </Paper>
                  )}
                  <IssueButton
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmitReportIssue()}
                  >
                    SUBMIT
                  </IssueButton>
                </Stack>
              </Box>
            )}

            {!reportIssue && (
              <TabPanel value={value} index={1} className="tab-list tab-info">
                <Box>
                  <MarkerInformationTab
                    locationDetail={chargerData}
                    forLocationTab={false}
                  />
                </Box>
              </TabPanel>
            )}
            {!reportIssue && (
              <TabPanel value={value} index={2} className="tab-list tab-info">
                <Box className="tabs-parent" sx={paymentStyles.tabParent}>
                  <Typography
                    variant="h3"
                    textAlign="center"
                    mb={2}
                    fontSize="16px"
                    fontWeight={"600"}
                  >
                    FAQs:
                  </Typography>
                  {faqData &&
                    faqData.map((faqs, index) => {
                      return (
                        <Accordion
                          key={faqs.id}
                          sx={{ marginBottom: "1rem" }}
                          className="accordion_content"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography textAlign="left">
                              Q-{index + 1}: {faqs.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography textAlign="left">
                              Ans-{index + 1}: {faqs.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </Box>
              </TabPanel>
            )}
            {!reportIssue && (
              <TabPanel value={value} index={3} className="tab-list tab-info">
                <Box className="tabs-parent" sx={paymentStyles.tabParent}>
                  <Typography
                    variant="h3"
                    textAlign="center"
                    mb={2}
                    fontSize="16px"
                    fontWeight={"600"}
                  >
                    Photos:
                  </Typography>
                  <ImageList cols={3} rowHeight={100} gap={16}>
                    {chargerData?.photos &&
                      chargerData.photos.map((photo, index) => (
                        <ImageListItem key={index}>
                          <img
                            src={photo}
                            alt="Feedback Img"
                            onClick={() => {
                              setPhotoModal({ open: true, src: photo });
                            }}
                          />
                        </ImageListItem>
                      ))}
                  </ImageList>
                  <Dialog
                    onClose={() => {
                      setPhotoModal({ open: false, src: "" });
                    }}
                    aria-labelledby="customized-dialog-title"
                    open={photoModal.open}
                  >
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        setPhotoModal({ open: false, src: "" });
                      }}
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent
                      sx={{
                        minHeight: "300px",
                        minWidth: isSmallScreen ? "100%" : "600px",
                        display: "flex",
                        placeItems: "center",
                        placeContent: "center",
                        padding: isSmallScreen ? "30px 16px" : "32px 40px",
                      }}
                    >
                      <img src={photoModal.src} alt="Review" />
                    </DialogContent>
                  </Dialog>
                </Box>
              </TabPanel>
            )}

            {!isGuest &&
              (!!charger?.pay_with_card || !!charger?.employee_or_resident) && (
                <EmailComponent
                  connector={connector}
                  email={email}
                  setEmail={setEmail}
                  // customerName = {customerName}
                  // setCustomerName= {setCustomerName}
                  chargerId={chargerId}
                  setPaymentStatus={setPaymentStatus}
                  setGuest={setGuest}
                  payWithCard={!!charger?.pay_with_card}
                  employeeOrResident={!!charger?.employee_or_resident}
                />
              )}
            {isGuest && !reportIssue && email && value === 0 && (
              <PaymentForm
                clientHandler={clientHandler}
                chargerId={chargerId}
                email={email}
                companyId={charger.companyId}
                domain={charger?.domain}
                // customerName={customerName}
                connector={connector}
                submitHandler={setPaymentStatus}
                paymentMethod={{
                  keyId: charger?.key_id,
                  id: charger?.payment_gateway_id,
                }}
              />
            )}
            {showCardInfo && clientId && (
              <Box
                className="tabs-parent"
                sx={{
                  ...paymentStyles.tabParent,
                }}
              >
                <StripeCheckout
                  clientId={clientId}
                  submitHandler={setPaymentStatus}
                  keyId={charger?.key_id}
                />
              </Box>
            )}
          </>
        )}
      </Container>

      <MuiSnackBar
        open={reportStat.open}
        severity={reportStat.variant}
        message={reportStat.message}
        onCloseFunc={() =>
          setReportStat((messageOp) => ({ ...messageOp, open: false }))
        }
      />
    </Page>
  );
};
