import { Box, Grid, Typography } from "@mui/material";
import { useDomainConfig } from "../../hooks/useDomainConfig";
import googlePlayStore from "../../image/android-download.svg";
import applePlayStore from "../../image/app-store.png";

import { Email, Language, PhoneAndroid } from "@mui/icons-material";
import WhatsappImg from "../../image/whatsapp_logo.png";
import Page from "../Page";
// import googlePlayStore from "../.../image/app-store.png"

// ----------------------------------------------------------------------

export default function DomainScanner() {
  const domainConfig = useDomainConfig();

  return (
    <Page title={`${domainConfig.domain}`}>
      <Grid container justifyContent="center">
        <Grid
          container
          justifyContent="center"
          item
          xs={12}
          md={4}
          margin={{ xs: 2, md: 0 }}
        >
          <Grid container justifyContent="center" xs={12} md={6}>
            <Box
              component="img"
              src={domainConfig.logoUrl}
              margin={{ xs: 1, md: 2 }}
            />
          </Grid>
          {/* Web Links */}
          <Grid justifyContent={"center"}>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              margin={{ xs: 1, md: 1 }}
            >
              <Language sx={{ width: 30, height: 30 }} />
              <Typography
                marginLeft={"10px"}
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                <a
                  href={`${domainConfig.websiteUrl}`}
                  target="blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {domainConfig.websiteUrl}
                </a>
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              margin={{ xs: 1, md: 1 }}
            >
              <Email sx={{ width: 30, height: 30 }} />
              <Typography
                marginLeft={"10px"}
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                <a
                  href={`mailto:${domainConfig.email}`}
                  target="blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {domainConfig.email}
                </a>
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              margin={{ xs: 1, md: 1 }}
            >
              <PhoneAndroid sx={{ width: 30, height: 30 }} />
              <Typography
                marginLeft={"10px"}
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                <a
                  href={`tel:${domainConfig.countryCode}${domainConfig.phone}`}
                  target="blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {domainConfig.countryCode} {domainConfig.phone}
                </a>
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              margin={{ xs: 1, md: 1 }}
            >
              <Box
                component="img"
                src={WhatsappImg}
                sx={{ width: 30, height: 30 }}
              />
              <Typography
                marginLeft={"10px"}
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                <a
                  href={`https://wa.me/${domainConfig.countryCode}${domainConfig.whatsappContact}`}
                  target="blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {domainConfig.countryCode} {domainConfig.whatsappContact}
                </a>
              </Typography>
            </Grid>
          </Grid>
          {/* App Links */}
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent={"center"}
            alignItems={"center"}
            marginY={{ xs: 1, md: 2 }}
          >
            <Grid item xs={12} md={12} justifyContent={"left"}>
              <a href={`${domainConfig.appStoreUrl}`} target="blank">
                <Box
                  component="img"
                  src={applePlayStore}
                  sx={{ width: 188, height: 65 }}
                />
              </a>
            </Grid>
            <Grid item xs={12} md={12} justifyContent={"left"}>
              <a href={`${domainConfig.playStoreUrl}`} target="blank">
                <Box
                  component="img"
                  src={googlePlayStore}
                  sx={{ width: 188, height: 65 }}
                />
              </a>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction="row"
            spacing={1}
            marginBottom={{ xs: 1, md: 2 }}
          >
            <Grid item xs={6} md={5}>
              <Typography fontWeight={800}>Registered Office :</Typography>
            </Grid>
            <Grid item xs={6} md={6} textAlign={"left"}>
              <Typography>{domainConfig.registeredOffice}</Typography>
            </Grid>
          </Grid>
          {/* Address Links */}
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction="row"
            spacing={1}
          >
            <Grid item xs={6} md={5}>
              <Typography fontWeight={800}>Regional Office :</Typography>
            </Grid>
            <Grid item xs={6} md={6} textAlign={"left"}>
              <Typography>{domainConfig.regionalOffice}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
