import Constant from "../util/constants";
import { domainDetails } from "../util/domainDetails";

const { domainList } = Constant;

export const useDomainConfig = () => {
  const hostSubArray = window.location.host.split(".");
  const domain = hostSubArray[hostSubArray.length - 2];
  return  domainDetails[domain] ?? domainDetails[domainList.AMIKTECHNOLOGIES];
};
