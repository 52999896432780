import React, { useState, useContext }  from 'react'
import { 
    Slide, 
    IconButton,
    Typography,
    CardHeader,
    CardContent,
    Box,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    TextField
} from "@mui/material";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { reportIssuesList } from '../../util/Display';
import Constant from '../../util/constants';
import { StyledLoadingButton } from '../common/Styled';
import Token from '../../util/Token';
import HttpService from '../../services/HttpService';
import { Context } from '../../context';
import debounce from 'lodash/debounce'

const styles={
    reportHeader: {
        textAlign: 'start'
    },
    cardContent: {
        paddingTop: 0,
        textAlign: 'start'
    },
    toggleButtonGroup: {
        gap: '12px',
        width: '-webkit-fill-available',
        mb: 2,
        '& > button': {
            outline: '2px solid var(--app-color)',
            borderRadius: '50px !important',
            textTransform: 'initial'
        },
        '& > button.Mui-selected': {
            bgcolor: 'var(--app-color) !important',
            outline: 'none'
        }
    },
    paperOthers: {
        padding: 2,
        bgcolor: 'var(--app-color-light)',
        borderRadius: '25px',
        mb: 2
    },
    textArea: {
        bgcolor: '#fff',
        borderRadius: '25px',
        '& > div:first-child': {
            borderRadius: '25px'
        }
    }
}

const initialState = {
    issue: '',
    others: !1,
    message: '',
    loading: !1
}

function ReportIssue({ isShow, container, onReportClick, locationId, direction = 'left',chargerId=null }){
    const [issueState, setIssueState] = useState(initialState);
    const { message } = useContext(Context);
    
    const onExited = () => {
        onReportClick(!0, !1)();
    }

    const onIssueChecked = (e, value) => {
        setIssueState({
            ...issueState,
            issue: value,
            others: 'OTHER' === value ? !0 : !1
        })
    }

    const onSubmitReport = async () => {
        const { setMessageOption } = message;
        const { data } = Token.parsedToken();
        const othersIssues = document.getElementById('others-issue');
        setIssueState({ ...issueState, loading: !0 })
        const payload =chargerId!==null?{
            issue: issueState.issue,
                message: othersIssues?.value || '',
                user: data._id,
                chargerId,
                locationId
        }:{
            issue: issueState.issue,
                message: othersIssues?.value || '',
                user: data._id,
                locationId
        }
        const response = await HttpService(Constant.methods.REPORT_ISSUE,payload);
        if(response.status) {
            setIssueState(initialState);
            setMessageOption((prev) => ({
                ...prev,
                open: !0,
                message: Constant.message.ISSUE_REPORTED_SUCCESS
            }))
        }
    }

    return (
        <Slide 
        direction={direction}
        container={container} 
        in={isShow} 
        mountOnEnter 
        unmountOnExit 
        timeout={500} 
        onExited={onExited}
        >
            <Box>
                <CardHeader
                sx={styles.reportHeader}
                avatar={
                    <IconButton aria-label="back to location detail" data-click-skip="true" onClick={onReportClick(!1, !1)}>
                        <ArrowBackOutlinedIcon />
                    </IconButton>
                }
                title={<Typography variant="h6">{Constant.label.FLAG_AN_ISSUE}</Typography>}
                />
                <CardContent sx={styles.cardContent}>
                    <Typography variant="body1" mb={2}>{Constant.label.ISSUE_TITLE}</Typography>
                    <ToggleButtonGroup 
                    sx={styles.toggleButtonGroup} orientation='vertical' 
                    exclusive value={issueState.issue} onChange={onIssueChecked}>
                        {reportIssuesList.map(issue => (
                            <ToggleButton key={issue.id} disabled={issueState.loading} value={issue.value}>{issue.text}</ToggleButton>
                        ))}
                        <ToggleButton value='OTHER' disabled={issueState.loading}>{Constant.label.OTHERS}</ToggleButton>
                    </ToggleButtonGroup>
                    {issueState.others &&
                     <Paper sx={styles.paperOthers} component="div" elevation={0} variant="outlined" square>
                       <Typography variant="body2" mb={1}>{Constant.label.ISSUE_PARA}</Typography>
                       <TextField 
                       id="others-issue"
                       placeholder="Placeholder"
                       multiline
                       name="message"
                       rows={4}
                       fullWidth
                       sx={styles.textArea}
                       />
                    </Paper>}
                    <StyledLoadingButton onClick={onSubmitReport} variant="contained" fullWidth loading={issueState.loading}>
                        {Constant.label.SUBMIT}
                    </StyledLoadingButton>
                </CardContent>
            </Box>
        </Slide>
    )
}

ReportIssue.defaultProps = {
    direction: 'left'
}

export default React.memo(ReportIssue);