import Constant from "./constants";
import { useDomainConfig } from "../hooks/useDomainConfig";

const defineStatusIcon = (providerObject, status) => {
  let iconSrc = providerObject.icon;
  console.log("device status:", status);
  switch (status) {
    case "A":
      if (providerObject.greenIcon) iconSrc = providerObject.greenIcon;
      break;
    case "NA":
      if (providerObject.redIcon) iconSrc = providerObject.redIcon;
      break;
    default:
      if (providerObject.greyIcon) iconSrc = providerObject.greyIcon;
      break;
  }

  return iconSrc;
};
export const GetIconSrc = ({ location, networkFilters, deviceAvailable }) => {
  const domainConfig = useDomainConfig();
  //if provider is chargemiles we will whitelabel it with domain provider
  const provider =
    location?.provider === Constant.domainProviderList["chargemiles"]
      ? domainConfig.provider
      : location?.provider;

  const networkProvider = networkFilters.find(
    (network) => network.name === provider
  );

  const genericNetworkProvider = networkFilters.find(
    (network) => network.name === "GENERIC"
  );

  if (networkProvider?._id)
    return defineStatusIcon(networkProvider, deviceAvailable);
  else return defineStatusIcon(genericNetworkProvider, deviceAvailable);
};
