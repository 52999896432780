import { useState, useEffect, useContext, Fragment } from "react";
import {
  Link as RouterLink,
  useNavigate,
  Outlet,
  useMatch,
  useResolvedPath,
  matchRoutes,
} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

import axios from "../../util/axios";
import Token from "../../util/Token";
import { Context } from "../../context";
import CssBaseline from "@mui/material/CssBaseline";
// ----------------------------------------------------------------------
import Header from "./Header";
import { useLocation } from "react-router-dom";

const APP_BAR_MOBILE = 10;
const APP_BAR_DESKTOP = 50;

const RootStyle = styled("div")({});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
}));

const ignoreHeaderRoutes = [
  { path: "/charger/:chargerId" },
  { path: "/product" },
];

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userContact, setUserContact] = useState("");
  const [userId, setUserId] = useState("");

  const { user, auth } = useContext(Context);
  const { userDetail, setUserDetail } = user;
  const { authenticated, setAuthenticated } = auth;
  const location = useLocation();
  const matchRoute = matchRoutes(ignoreHeaderRoutes, location);

  /**
   * First thing to call after login or page refresh
   * This function will check if JWT token is set or not
   * If not it will redirect user to login
   * If yes, fetch JWT token from local storage and parse it. set userId to state
   */
  useEffect(() => {
    const userData = Token.parsedToken();
    if (userData && userData.success && userData.data) {
      if (userData.data.email) {
        setUserEmail(userData.data.email);
        setUserContact("email");
      }
      if (userData.data.phone) {
        setUserPhone(userData.data.phone);
        setUserContact("phone");
      }
      setUserId(userData.data._id);
    }
  }, []);

  /**
   * First thing to call after login or page refresh
   * return axios.get("/user/getUserDetail", {
      params: {
        username: userData.data.username
      }
   */
  useEffect(() => {
    if (!userContact || !userId) {
      return;
    }
    (async function () {
      try {
        const getUserDetail = async () => {
          const response = await axios(
            `${process.env.REACT_APP_API_END_POINT}user/detail?userId=${userId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Token.get()}`,
              },
            }
          );
          const userDetailRes = response.data.data;
          if (userDetailRes) {
            setUserDetail(userDetailRes);
          }
        };

        await getUserDetail();
      } catch (error) {
        console.error(error.response);
      }
    })();
  }, [userContact]);

  useEffect(() => {
    if (userDetail && userDetail._id) {
      setAuthenticated({
        ...authenticated,
        isAuth: !0,
        authPopup: !1,
        redirect: null,
      });
    }
  }, [userDetail]);

  return (
    <RootStyle>
      {/* {!matchRoute && <Header />} */}
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
