import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  // {
  //   title: 'use List',
  //   path: '/dashboard/user',
  //   icon: getIcon(peopleFill)
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // },
  {
    title: 'user',
    path: '/user',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Course Setup',
    path: '/course',
    icon: getIcon(alertTriangleFill),
    children: [
      {
        title: 'Course',
        path: '/course',
        icon: getIcon(lockFill)
      },
      {
        title: 'Course Store',
        path: '/setup/course',
        icon: getIcon(personAddFill)
      }
    ]
  },
  {
    title: 'System Setting',
    path: '/setup',
    icon: getIcon(alertTriangleFill),
    children: [
      {
        title: 'User Type',
        path: '/setup/usertype',
        icon: getIcon(lockFill)
      },
      {
        title: 'Category',
        path: '/setup/category',
        icon: getIcon(personAddFill)
      }
    ]
  },
  {
    title: 'Account Setting',
    path: '/account',
    icon: getIcon(alertTriangleFill),
    children: [
      {
        title: 'Basic Setting',
        path: '/account/basic',
        icon: getIcon(lockFill)
      },
      {
        title: 'User Setting',
        path: '/account/user',
        icon: getIcon(personAddFill)
      }
    ]
  }
];

export default sidebarConfig;
