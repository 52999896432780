import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import googleFill from "@iconify/icons-eva/google-fill";
// import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from "@iconify/icons-eva/facebook-fill";
import { gapi, loadAuth2 } from "gapi-script";
// material
import {
  Stack,
  Divider,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import token from "../../util/Token";
import "./AuthSocial.css";
import { useDomainConfig } from "../../hooks/useDomainConfig";

export default function AuthSocial({ direction = "column" }) {
  const domainConfig = useDomainConfig();
  const [loader, setLoader] = useState(false);
  const socialLogin = async (response) => {
    try {
      if (response.accessToken) {
        setLoader(true);
        let data = {
          provider: response.customType,
          domain:domainConfig.domain,
          token: response.accessToken,
        };
        const socialResponse = await axios.post(
          `${process.env.REACT_APP_API_END_POINT}auth/social-login`,
          data
        );
        setLoader(false);
        const tokenResp = socialResponse.data.data;
        if (socialResponse?.status === 200 && tokenResp.data) {
          token.set(tokenResp.data.token);
          window.location.href = "/";
          //  TODO: make it work
          //  Token.setAuthToken();
          //  window.location.href = '/dashboard';
          //  navigate('/dashboard');

          // localStorage.setItem("token", JSON.stringify(res?.data?.data?.token));
          // localStorage.setItem(
          //   "refreshToken",
          //   JSON.stringify(res?.data?.data?.refreshToken)
          // );
          // saveToken(res?.data?.data?.token);
          // saveRefreshToken(res?.data?.data?.refreshToken);
        } else {
          const snackbarJson = {
            msg: socialResponse?.message,
            open: true,
            severity: "error",
          };
        }
      }
    } catch (error) {
      console.error("error", error);
      const snackbarJson = {
        msg: error?.message,
        open: true,
        severity: "error",
      };
    }
  };
  const loadGapiAuth2 = async () => {
    await loadAuth2(gapi, domainConfig.google_client_id);
  };
  useEffect(() => {
    // function start() {
    //   gapi.auth2.init({
    //     client_id: "565280511289-gpmor4ji28cq444c1t5gmkdo7b1fb3o2.apps.googleusercontent.com"
    //   })
    // }
    // gapi.load('client:auth2', start)
    loadGapiAuth2();
  }, []);
  return (
    <>
      <Stack direction={direction} spacing={2}>
        <Divider sx={{ mt: 3 }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            OR
          </Typography>
        </Divider>
        {loader ?
        <Box width={"100%"} textAlign={"center"}>
          <CircularProgress />
        </Box>
        :
        <GoogleLogin
          clientId={domainConfig.clientId}
          render={(renderProps) => (
            <IconButton
              width={"100%"}
              textAlign={"center"}
              disableElevation
              disableRipple
              size="small"
              sx={{
                "margin-top": "0px",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <GoogleIcon
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                color="primary"
              />
            </IconButton>
          )}
          onSuccess={(res) => socialLogin({ ...res, customType: "gmail" })}
          onFailure={(err) => {
            console.error("Error", err);
            const snackbarJson = {
              msg: `${err?.error} ${err.idpId}`,
              open: true,
              severity: "error",
            };
            //setSnackbarJson(snackbarJson);
          }}
        />
}
        {/* <GoogleLogin
          clientId="565280511289-gpmor4ji28cq444c1t5gmkdo7b1fb3o2.apps.googleusercontent.com"
          buttonText="LOGIN WITH GOOGLE"
          onSuccess={(res) =>
            socialLogin({ ...res, customType: "gmail" })
          }
          onFailure={(err) => {
            console.error("Error", err);
            const snackbarJson = {
              msg: `${err?.error} ${err.idpId}`,
              open: true,
              severity: "error",
            };
            //setSnackbarJson(snackbarJson);
          }}
          scope="profile"
          cookiePolicy={'single_host_origin'}
          cssClass="google-button"
          className="google-button"
        /> */}
        {/* <GoogleLogin
          clientId="629251778572-qr3qs1aosv8cr022nnngutrnqhmj0ddq.apps.googleusercontent.com"
          render={(renderProps) => (
            <Button fullWidth size="large" color="inherit" variant="outlined" className="google-button">
              <GoogleIcon style={{ color: "white", marginRight: '5px' }} /> LOGIN WITH GOOGLE
            </Button>
          )}
          onSuccess={(res) =>
            socialLogin({ ...res, customType: "gmail" })
          }
          onFailure={(err) => {
            console.error("Error", err);
          }}
          cookiePolicy={"single_host_origin"}
        /> */}
        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <GoogleIcon style={{ color: "#df3e30" }} />
        </Button> */}
        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <FacebookIcon style={{ color: "#8a72df" }} />
        </Button> */}
        {/* <FacebookLogin
          appId="703430944243858"
          autoLoad={false}
          callback={(res) =>
            socialLogin({ ...res, customType: "facebook" })
          }
          label="Login with facebook"
          icon={<FacebookIcon style={{ color: "white", marginBottom: '-4px', marginRight: '5px' }} />}
          disableMobileRedirect={true}
          cssClass="facebook-button"
        /> */}
      </Stack>
    </>
  );
}
