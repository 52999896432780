import { useMemo, useEffect } from "react";
// material
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Card,
  Grid,
  Link,
  Button,
  Stack,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Constant from "../../util/constants";
import { socketTypeList, getImage } from "../../util/Display";
import payNow from "../../image/pay_now.svg";
import accessNow from "../../image/access_now.svg";
import { Link as RouterLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDomainConfig } from "../../hooks/useDomainConfig";
// ----------------------------------------------------------------------

const { imageMapper, label } = Constant;
const styles = {
  listItemBox: {
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    display: "inline-flex",
    paddingBottom: "10px",
  },
  listItemPaper: {
    width: "100%",
    borderRadius: "50px",
    display: "flex",
    padding: "5px 12px 5px 8px",
    alignItems: "center",
  },
  listItemBold: {
    fontWeight: "600",
    textAlign: "right",
  },
  listPaperImage: {
    height: 32,
    width: 32,
    verticalAlign: "middle",
  },
  listItemText: {
    fontSize: "0.7em",
  },
};

const PayNowBtn = ({ locationDetail, isThirdPartyCPO }) => {
  const chargerId = !isThirdPartyCPO
    ? locationDetail?.devices[0]?.deviceId
    : locationDetail.name;
  const queryParams = isThirdPartyCPO
    ? "?vendor=" +
      locationDetail.party_id +
      "&country=" +
      locationDetail.country
    : "";

  console.log(locationDetail);
  return (
    <>
      <Link
        component={RouterLink}
        to={`charger/${chargerId + queryParams}`}
        target="_blank"
        underline="none"
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={
            <img src={payNow} alt="charging_button" width={24} height={24} />
          }
        >
          Pay Now
        </Button>
      </Link>
    </>
  );
};

const AccessNowBtn = ({ locationDetail, networks }) => {
  const providerName = locationDetail?.provider;
  const accessLink = networks.find(
    ({ name }) => name === providerName
  )?.accessLink;

  return (
    <>
      {accessLink && accessLink !== "N/A" && (
        <Button
          component="a"
          href={accessLink}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="primary"
          size="small"
          startIcon={
            <img src={accessNow} alt="charging_button" width={24} height={24} />
          }
        >
          Access
        </Button>
      )}
    </>
  );
};

export default function MarkerConnectorTab({
  locationDetail,
  networkFiltersArr,
  isThirdPartyCPO,
}) {
  const devices = useMemo(
    () =>
      isThirdPartyCPO
        ? [{ evses: locationDetail[0]?.evses }]
        : locationDetail[0]?.devices,
    [locationDetail, isThirdPartyCPO]
  );

  const domainConfig = useDomainConfig();

  useEffect(() => console.log(isThirdPartyCPO), [isThirdPartyCPO]);

  return (
    <>
      {locationDetail &&
        locationDetail.map((data, i) => (
          <Card
            sx={{
              minWidth: 275,
              textAlign: "start",
              marginBottom: "5px",
              border: "2px solid #f3f3f3",
              boxShadow: "none",
            }}
            key={uuidv4()}
          >
            <CardContent>
              <Typography
                fontSize={16}
                textTransform="capitalize"
                fontWeight={600}
                // color="text.secondary"
                gutterBottom
              >
                {data.name}
              </Typography>
              <List>
                {isThirdPartyCPO &&
                  data?.devices[0]?.evses &&
                  data?.devices[0]?.evses.map((evse) => (
                    <ListItem
                      key={uuidv4()}
                      sx={{ padding: "0px", flexDirection: "column" }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={2}>
                          <span className="connector-image">
                            {socketTypeList[2].src}
                            {/* // style={styles.listPaperImage}
                          // alt={"img"} */}
                          </span>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {`${evse.connectors[0].id} (${(
                              (1 *
                                evse.connectors[0].voltage *
                                evse.connectors[0].amperage) /
                              1000
                            ).toFixed(3)}KW)`}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {`By ${data.provider}`}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            {`${evse.connectors[0].format} (${evse.connectors[0].power_type})`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                {!isThirdPartyCPO &&
                  data?.devices[0]?.stations &&
                  data?.devices[0].stations.map((station, i) => {
                    let socketInfo;
                    const connectorIndentifier = station.connector
                      ? station.connector
                      : "connector6";
                    socketInfo = socketTypeList.find(
                      ({ id, value }) =>
                        value === connectorIndentifier ||
                        Number(id) === Number(connectorIndentifier)
                    );

                    return (
                      <ListItem
                        key={uuidv4()}
                        sx={{
                          padding: "0px",
                          flexDirection: "column",
                          marginBottom: "1rem",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <span className="connector-image">
                              {station.connector
                                ? socketInfo?.src
                                : getImage(imageMapper.FAULT_CONNECTOR_IMAGE)}
                            </span>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {`${socketInfo?.type} (${station.kilowatts}KW ${station.powerType} V)`}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {`By ${
                                station.operator ===
                                Constant.domainProviderList["chargemiles"]
                                  ? domainConfig?.title
                                  : station?.operator
                              }`}
                            </Typography>
                            {station?.cost > 0 && (
                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                {`Rate ${station.cost}/kwh`}
                              </Typography>
                            )}
                            {station?.cost_desc && (
                              <>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  {`Rate Detail`}
                                </Typography>
                                <Grid item xs={10} sx={{ textAlign: "start" }}>
                                  {station?.cost_desc?.split("/n").length > 0 &&
                                    station?.cost_desc
                                      ?.split("/n")
                                      .map((Item) => (
                                        <Typography
                                          sx={{ fontSize: 14 }}
                                          color="text.secondary"
                                          gutterBottom
                                        >
                                          {Item}
                                        </Typography>
                                      ))}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
              </List>
              <Stack direction="row" justifyContent="flex-end">
                {data.provider === "AMIK Technologies" ||
                data.provider === "MoveonEV" ||
                data.provider === "Techvolt Network" ||
                data.ownerName === "AMIK Technologies" ||
                data.ownerName === "MoveonEV" ||
                data.ownerName === "Techvolt Network" ||
                isThirdPartyCPO ? (
                  <PayNowBtn
                    locationDetail={data}
                    isThirdPartyCPO={isThirdPartyCPO}
                  />
                ) : (
                  <AccessNowBtn
                    locationDetail={data}
                    networks={networkFiltersArr}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        ))}
    </>
  );
}

/**
 * <List>
            {locationDetail && locationDetail?.stations.map((item, i) => (<>
                {item &&
                <ListItem key={uuidv4()} sx={{padding:'0px', flexDirection:'column'}}>
                    <Box sx={styles.listItemBox}>
                    </Box>
                    <Paper elevation={0} sx={styles.listItemPaper}>
                        <span className='connector-image'>
                            <img
                                src={item.connector ?
                                    socketTypeList.find(socket => socket.id === item.connector).src :
                                    getImage(imageMapper.FAULT_CONNECTOR_IMAGE)}
                                style={styles.listPaperImage}
                                alt={"img"}
                            />
                        </span>
                        <ListItemText className='item-text' sx={{ textAlign: 'center' }} primary={item.connectorName} />
                        <ListItemText className='item-text' sx={{ textAlign: 'right', fontWeight: 600 }} primary={Number(item.kilowatts) ? item.kilowatts: '' } />
                        <ListItemText className='item-text' sx={{ textAlign: 'right' }} id="switch-list-label-wifi" primary={`${item.cost_desc ? item.cost_desc : 'N/A'}`} />
                    </Paper>
                </ListItem>
                }
            </>
            ))}
        </List>
 */
