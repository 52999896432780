import { Navigate, useRoutes } from "react-router-dom";
import Login from "../component/pages/Login";
import Logout from "../component/pages/Logout";
import Account from "../component/pages/Account";
import ResetPassword from "../component/pages/ResetPassword";
import ForgetPassword from "../component/pages/ForgetPassword";
import Register from "../component/pages/Register";
import ChargeMiles from "../component/chargeMiles";
import FeedBack from "../component/Feedback";
import DashboardLayout from "../layouts/dashboard";
import TestApp from "../component/pages/Test";
import Terms from "../component/pages/Terms";
import PrivacyPolicy from "../component/pages/PrivacyPolicy";
import ChangePassword from "../component/pages/ChangePassword";
import MyVehicle from "../component/account/MyVehicle";
import RequestCharger from "../component/pages/RequestCharger";
import WhyCM from "../component/pages/WhyCM";
import Product from "../component/pages/Product";
import AboutUs from "../component/pages/AboutUs";
import ContactUs from "../component/pages/ContactUs";
import Refund from "../component/pages/Refund";
import { PaymentStatus } from "../component/chargeMiles/Payment/PaymentStatus";
import { ChargerInformation } from "../component/chargeMiles/QRScanner/ChargerDetails";
import { PaymentResponse } from "../component/chargeMiles/Payment/PaymentResponse";
import ChargerTransactionStats from "../component/chargeMiles/ChargerTransactionStats";
import TransactionInvoice from "../component/chargeMiles/TransactionInvoice";
import RedirectPage from "../component/chargeMiles/RedirectPage";
import DomainScanner from "../component/pages/DomainScanner";
import { useDomainConfig } from "../hooks/useDomainConfig";

export default function Router({ isLoggedIn }) {
  const domainConfig = useDomainConfig();
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/" replace /> },
        { path: "/", element: <ChargeMiles /> },
        { path: "/home", element: <Navigate to="/" replace /> },
        { path: "/feedback", element: <FeedBack /> },
        { path: "/terms-of-services", element: <Terms /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/refund", element: <Refund /> },
        { path: "/account", element: <Account /> },
        { path: "/my-vehicle", element: <MyVehicle /> },
        { path: "/change-password", element: <ChangePassword /> },
        { path: "/request-charger", element: <RequestCharger /> },
        { path: "/why-chargemiles", element: <WhyCM /> },
        { path: "/product", element: <Product /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/contact-us", element: <ContactUs /> },
        { path: "/payment-status", element: <PaymentStatus /> },
        { path: "/payment/status", element: <PaymentResponse /> },
        { path: "/charger/:chargerId", element: <ChargerInformation /> },
        { path: "/charger/:chargerId/:connectorId", element: <ChargerInformation /> },
        { path: "/en/:redirectId", element: <RedirectPage /> },
        { path: "/status", element: <ChargerTransactionStats /> },
        { path: "/invoice", element: <TransactionInvoice /> },
      ],
    },
    // { path: '/', element: <ChargeMiles /> },
    // { path: '/feedback', element: <FeedBack /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/forget-password", element: <ForgetPassword /> },
    { path: "/reset-password/:id/:secret", element: <ResetPassword /> },
    { path: "/reset-password/:id/:secret/:type", element: <ResetPassword /> },
    { path: "/logout", element: <Logout /> },
    { path: `/${domainConfig.domain}`, element: <DomainScanner /> },
  ]);
}

// import React, { useEffect, useState, useMemo } from "react";
// import { Router } from "@reach/router";
// import Login from "../component/Login";
// import Logout from "../component/Logout";
// import EmptyLayout from "../layout/EmptyLayout";
// import HeaderOnlyLayout from "../layout/HeaderOnlyLayout";
// import Register from "../component/Register";
// import Dashboard from "../component/Dashboard";
// import HomeLayout from "../layout/HomeLayout";
// import ChargeMiles from "../component/chargeMiles";
// import FeedBack from "../component/Feedback";

// const RouterComponent = (props) => {
//   return (
//     <Router>
//       <EmptyLayout path="/" component={ChargeMiles} />
//       <HomeLayout path="/manage">
//         <Dashboard path="/dashboard" />
//       </HomeLayout>
//       {/* <AdminLayout path="/admin">
//         <DashboardContainer path="/" />
//         <UserContainer path="/user" />
//         <UserDetail path="/user/:username" />
//       </AdminLayout>
//       <AdLayout path="/ads">
//         <Instruction path="/" />
//         <BannerCampaignContainer path="/banner" />
//         <CreateCampaignContainer path="/campaign" />
//       </AdLayout> */}
//       <EmptyLayout path="/login" component={Login} />
//       <EmptyLayout path="/register" component={Register} />
//       <EmptyLayout path="/logout" component={Logout} />
//       <HeaderOnlyLayout path="/feedback" component={FeedBack} />
//     </Router>
//   );
// };

// export default RouterComponent;
