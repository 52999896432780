// material
import { Container, Grid, Slide, Typography } from "@mui/material";
import { CMBreadCrumb } from "../common/CMBreadCrumb";
import { CMFooter } from "../common/CMFooter";
// components
import Page from "../Page";
import aboutUs01 from "../../image/aboutUs01.jpeg";
import { DomainText } from "../chargeMiles/DomainText";
// ----------------------------------------------------------------------

export default function WhyCM() {
  return (
    <Page title="Dashboard | About-UI">
      <CMBreadCrumb prevPage="Home" currPage={"About Us"} />
      <Container
        sx={{
          padding: "128px 0",
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={7} margin={{ xs: 2, md: 0 }}>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              fontSize={{ md: "48px", xs: "22px" }}
              fontWeight={500}
              fontFamily="Futura"
              margin="0 0 5px"
              textAlign="left"
            >
              Providing Best Experience For Charging Point Services
            </Typography>
            <p
              style={{
                marginBottom: "30px",
                color: "#848484",
                textAlign: "left",
                lineHeight: 1.8,
              }}
            >
              <DomainText /> is a company formed by a diverse team who have
              extensive expertise and experience in Utilities, Electric Vehicle
              Industry, smart metering, energy distribution and digital
              transformation and consulting.&nbsp;
              <br />
              The team has proven entrepreneur skills as demonstrated while
              building an independent fleet management solution ’Fleetomize’
              using open-source technologies.
            </p>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              marginTop="20px"
              margin={{ xs: 0, md: 2 }}
            >
              <Grid xs={12} md={6}>
                <Grid xs={12} md={6}></Grid>
                <Grid xs={12} md={6}>
                  <h4
                    style={{
                      marginBottom: "0px",
                      fontSize: "28px",
                      color: "#2C2C2C",
                      lineHeight: "36px",
                      textTransform: "capitalize",
                      fontWeight: 600,
                    }}
                  >
                    Smart Utility Expertise
                  </h4>
                </Grid>
              </Grid>
              <Grid xs={12} md={6}>
                <Grid xs={12} md={6}></Grid>
                <Grid xs={12} md={6}>
                  <h4
                    style={{
                      marginBottom: "0px",
                      fontSize: "28px",
                      color: "#2C2C2C",
                      lineHeight: "36px",
                      textTransform: "capitalize",
                      fontWeight: 600,
                    }}
                  >
                    40 years Of experience
                  </h4>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} margin={{ xs: 2, md: 0 }}>
            <Slide
              direction="left"
              in={true}
              timeout={{ appear: 2000, enter: 2000 }}
            >
              <div>
                <img src={aboutUs01} title="2.jpg" alt="2.jpg" width={"100%"} />
              </div>
            </Slide>
          </Grid>
        </Grid>
      </Container>
      {/* <CMFooter /> */}
    </Page>
  );
}
