import { useMemo, useState, useEffect, useCallback } from "react";

import { StandaloneSearchBox } from "@react-google-maps/api";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./Direction.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Direction({ toggleDirection, setMapFromLocation, setMapToLocation, getDirectionHandler }) {
  const [expanded, setExpanded] = useState(false);
  const [fromLocation, setFromLocation] = useState([]);
  const [toLocation, setToLocation] = useState([]);

  const onFromLocationLoad = (ref) => {
    setFromLocation(ref);
  };
  const onToLocationLoad = (ref) => {
    setToLocation(ref);
  };

  const onFromLocationChanged = () => {
    console.log(fromLocation.getPlaces());
    let places = fromLocation.getPlaces();
    let place = places[0].geometry.location;
    //setCenter({ lat: place.lat(), lng: place.lng() });
    //setMapFromLocation({ lat: place.lat(), lng: place.lng() });
  };
  const onToLocationChanged = () => {
    console.log(toLocation.getPlaces());
    let places = toLocation.getPlaces();
    let place = places[0].geometry.location;
    //setCenter({ lat: place.lat(), lng: place.lng() });
    //setMapToLocation({ lat: place.lat(), lng: place.lng() });
  };

  const getDirection = (e) => {
    e.preventDefault();
    let fromPlaces = fromLocation.getPlaces();
    let fromPlace = fromPlaces[0].geometry.location;
    let toPlaces = toLocation.getPlaces();
    let toPlace = toPlaces[0].geometry.location;
    getDirectionHandler({ lat: fromPlace.lat(), lng: fromPlace.lng() }, { lat: toPlace.lat(), lng: toPlace.lng() })
  }

  return (
    <Card sx={{ maxWidth: 400, minWidth: 400 }} className="direction-root">
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <CancelPresentationIcon onClick={(e) => toggleDirection(false)} />
          </IconButton>
        }
        subheader="Plan your trip"
      />
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <StandaloneSearchBox
              onLoad={onFromLocationLoad}
              onPlacesChanged={onFromLocationChanged}
            >
              <TextField
                variant="standard"
                label="Starting Location"
                sx={{ width: "100%" }}
                placeholder="Your location"
              />
            </StandaloneSearchBox>
            <StandaloneSearchBox
              onLoad={onToLocationLoad}
              onPlacesChanged={onToLocationChanged}
            >
              <TextField
                variant="standard"
                label="Destination"
                sx={{ width: "100%" }}
                placeholder="Destination"
              />
            </StandaloneSearchBox>
            <Button variant="contained" onClick={e => getDirection(e)}>Search</Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}
