import axios from "axios";
import { PAYMENT_ENDPOINTS } from "./payment.constants";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}



export async function displayRazorpay(
  { chargerId, connectorId, minutes, amount, emailOrPhone, orderId, currency,keyId ,companyId},
  submitHandler
) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const phoneRegex = /^\d{10}$/;
  let contact;
  let email;
  if (phoneRegex.test(emailOrPhone)) {
    contact = emailOrPhone;
  } else {
    email = emailOrPhone;
  }
  const options = {
    key: keyId,
    amount: amount.toString(),
    currency: currency,
    order_id: orderId,
    handler: async function (response) {
      const requestData = {
        orderCreationId: orderId,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpaySignature: response.razorpay_signature,
        companyId
      };

      const { data } = await axios.post(
        PAYMENT_ENDPOINTS.getVerifyPaymentUrl,
        requestData
      );
      if (data?.success) {
        submitHandler({
          isDone: true,
          status: true,
          message:
            "Payment is successful! Please wait, charger will start in a while.",
        });
      } else {
        submitHandler({
          isDone: true,
          status: false,
          message: "Payment is failed. Please try again.",
        });
      }
    },
    prefill: {
      email,
      contact,
    },
    hidden: {
      email: !email,
      contact: !contact,
    },
    readonly: {
      email: true,
      contact: true,
    },
    notes: {
      chargerId,
      connectorId,
      minutes,
      email,
      mobile: contact,
    },
    config: {
      display: {
        hide: [
          {
            method: "cardless_emi",
          },
          {
            method: "paylater",
          },
        ],
      },
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
