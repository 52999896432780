import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import { Stack, TextField, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "../../../util/axios";
import token from "../../../util/Token";
import OTPVerification from "../register/OTPVerification";
import { useDomainConfig } from "../../../hooks/useDomainConfig";

// ----------------------------------------------------------------------

export default function ForgetPasswordForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [openOtpVerify, setOpenOtpVerify] = useState(false);
  // const [phoneForgotPasswordId, setPhoneForgotPasswordId] = useState(null);
  const [forgotPasswordId, setForgotPasswordId] = useState(null);
  const [resetPasswordPayloadObj, setResetPasswordPayloadObj] = useState({});
  const domainConfig = useDomainConfig();
  const phoneNoRegex = /^\d{10}$/i;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
  const [contactType, setContactType] = useState("");

  const forgetPasswordSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required')
    contact: Yup.string()
      .test(
        "phone-or-email",
        "Please enter a valid phone number or email address",
        (value) => {
          if (phoneNoRegex.test(value) || emailRegex.test(value)) return true;
          return false;
        }
      )
      .required("Either email or phone no. is required"),
  });

  const formik = useFormik({
    initialValues: {
      contact: "",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: (resetPasswordData, { setSubmitting, resetForm }) => {
      (async function () {
        try {
          const registerHelper = async () => {
            let resetPayload = {
              type: emailRegex.test(resetPasswordData.contact)
                ? "email"
                : "phone",
            };
            resetPayload.domain = domainConfig.domain;
            if (resetPayload.type === "email")
              resetPayload.email = resetPasswordData.contact;
            else {
              resetPayload.phone = resetPasswordData.contact;
              resetPayload.countryCode = domainConfig.countryCode;
            }
            setResetPasswordPayloadObj(resetPayload);
            setContactType(resetPayload.type);
            const response = await axios(
              `${process.env.REACT_APP_API_END_POINT}auth/v2/forget-password`,
              {
                method: "post",
                data: resetPayload,
              }
            );
            const forgetPasswordResponse = response.data.data;
            if (resetPayload.type === "phone" || resetPayload.type === "email") {
              // let id = forgetPasswordResponse.data;
              // setPhoneForgotPasswordId(id)
              setForgotPasswordId(forgetPasswordResponse.data);
              setOpenOtpVerify(true);
            } else if (response.data.message === "User detail not found"){
                setError(response.data.message);
            }
            setEmailSent(true);
            setSubmitting(false);
          };
          await registerHelper();
        } catch (error) {
          setSubmitting(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setError(error.response.data.message);
          }
        }
      })();
      //navigate('/dashboard', { replace: true });
    },
  });

  const resendOtpFunc = async () => {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_API_END_POINT}auth/v2/forget-password`,
          {
            method: "post",
            data: resetPasswordPayloadObj,
          }
        );

        const registerHelperResp = response.data;
        if (registerHelperResp && registerHelperResp.success) {
          setForgotPasswordId(registerHelperResp.data.data);
          res("resend success");
        }
      } catch (error) {
        rej(error.response.message);
      }
    });
  };
  const submitOtp = (otp) => {
    return new Promise(async (res, rej) => {
      try {
        const otpPayload = {
          id: forgotPasswordId,
          domain: domainConfig.domain,
          otp: otp.Otp,
        };
        const url = `${process.env.REACT_APP_API_END_POINT}auth/verify-change-password-otp`;
        const response = await axios(url, {
          method: "post",
          data: otpPayload,
        });
        res(response.data);
      } catch (error) {
        rej(error.response.data.message);
      }
    });
  };

  const onSuccessfullVerify = (otp) => {
    resetForm();
    navigate(`/reset-password/${forgotPasswordId}/${otp}/${contactType}`);
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
    values,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <div style={{ color: "red" }}>{error}</div>
      <div style={{ color: "green" }}>{successMessage}</div>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {!emailSent && (
            <TextField
              fullWidth
              autoComplete="username"
              type="text"
              label="Email address / Phone number"
              {...getFieldProps("contact")}
              error={Boolean(touched.contact && errors.contact)}
              helperText={touched.contact && errors.contact}
            />
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <Link component={RouterLink} variant="subtitle2" to="/Login">
              Back to login
            </Link>
          </Stack>
          {!emailSent && (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          )}
        </Stack>
      </Form>
      {openOtpVerify && (
        <OTPVerification
          contactType={contactType}
          contact={values.contact}
          onClose={() => setOpenOtpVerify(false)}
          open={openOtpVerify}
          submitOtpFunc={submitOtp}
          handleSuccess={onSuccessfullVerify}
          countryCode={
            contactType === "phone" ? domainConfig.countryCode : undefined
          }
          resendOtp={resendOtpFunc}
          forgotPasswordId={forgotPasswordId}
        />
      )}
    </FormikProvider>
  );
}
