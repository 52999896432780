import { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Container } from "@mui/material";
import Page from "../../Page";
import { PaymentStatusContainer } from "../QRScanner/PaymentStatusContainer";
import { Overlay } from "../../Overlay";
import { useSearchParams } from "react-router-dom";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
// const stripePromise = (async () => {
//   loadStripe.setLoadParameters({ advancedFraudSignals: false });
//   return await loadStripe(process.env.REACT_APP_STRIPE_KEY);
// })();

export const ConfirmationMessage = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientId = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientId).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(
            "Payment is successful! Please wait, charger will start in a while."
          );
          setStatus(true);
          break;

        case "processing":
          setMessage(
            "Please wait!  Payment is  being processed. Charger will start in a while."
          );
          setStatus(true);
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Payment failed. Please try another payment method.");
          setStatus(false);
          break;

        default:
          setMessage(
            "Payment  is unsuccessful! Money will be refunded to your original payment method if deducted.  Please try again."
          );
          setStatus(false);
          break;
      }
    });
  }, [stripe]);

  return (
    <>
      {message ? (
        <PaymentStatusContainer status={status} message={message} />
      ) : (
        <Overlay />
      )}
    </>
  );
};

export const PaymentStatus = () => {
  const clientId = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  const [stripePromise, setStripePromise] = useState();
  const [searchParams] = useSearchParams();
  const keyId = searchParams.get("keyId");

  useEffect(() => {
    if (keyId) {
      loadStripe.setLoadParameters({ advancedFraudSignals: false });
      const stripe = loadStripe(keyId);
      setStripePromise(stripe);
    }
  }, [keyId]);

  return (
    <Page
      title="Dashboard | PaymentStatus-UI"
      sx={{ marginTop: { xs: "128px", md: "62px" } }}
    >
      <Container
        sx={{
          padding: "50px",
          width: "100%",
          margin: "auto",
          flex: "1 1 auto",
        }}
        style={{ maxWidth: "500px" }}
      >
        {stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret: clientId }}>
            <ConfirmationMessage />
          </Elements>
        )}
      </Container>
    </Page>
  );
};
