import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import "./Map.css";
import {
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { MarkerClusterer } from "@react-google-maps/api";
import axios from "axios";
import SearchBar from "./SearchBar";
import CMMarker from "./CMMarker";
//import LocationDetail from "./LocationDetail";
import MarkerLocationDetail from "./MarkerLocationDetail";
import markerOther from "../../image/balloon__0.png";
import markerGreen from "../../image/balloon_green.png";
import markerBlue from "../../image/balloon_blue.png";
import markerOrange from "../../image/balloon_orange.png";
import markerRed from "../../image/balloon_red.png";
import Direction from "./Direction";
import RoutePlanner from "./RoutePlanner";
import LocationFilters from "./LocationFilters";
import WhatAreYourThoughts from "./WhatAreYourThoughts";
import Disclaimer from "./Disclaimer";
import Mapkey from "./MapKey";
import VehicleSegment from "./VehicleSegment";
import { LinearProgress } from "@mui/material";
import { Context } from "../../context";
import {
  sleep,
  resetRoutes,
  networkFilterList,
  socketTypeList,
} from "../../util/Display";
import { useDebounce } from "../../hooks/useDebounce";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { MuiDrawer } from "../@material-extend";
import { TripPlanner } from "../create-trip";
import Constant from "../../util/constants";
import { CMFooter } from "../common/CMFooter";
import { useDomainConfig } from "../../hooks/useDomainConfig";
import { GetIconSrc } from "../../util/providerIcons";

const libraries = ["places"];
const { googleMap, socketType, connectorStatus, chargerStatus } = Constant;

export default function Home() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDUgxJLnLjZm3fV_24nDDB-2BKMNqmra9I",
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const domainConfig = useDomainConfig();
  const clusterSvg = `${process.env.PUBLIC_URL}/${domainConfig.clusterSvgName}.svg`;

  const elRefs = useRef({ waypoints: [] });
  const options = useMemo(
    () => ({
      disabledDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );
  const { maps, message } = useContext(Context);
  const [cmMap, setCmMap] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [locations, setLocation] = useState([]);
  const [searchBox, setSearchBox] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 28.6314512,
    lng: 77.2166672,
  });
  const center = useMemo(() => defaultCenter, [defaultCenter]);
  const [markerClickLocation, setMarkerClickLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isDirectionOpen, setIsDirectionOpen] = useState(false);
  const [mapDirection, setMapDirection] = useState();
  const [loading, setLoading] = useState(!1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [networkFilters, setNetworkFilter] = useState([]);
  const [fetchedNetworkFilters, setFetchedNetworkFilters] = useState(false);
  const [networkFilterNameArr, setNetworkFilterNameArr] = useState({});
  const [mapFilter, setMapFilter] = useState({
    access: [], //"RESIDENTIAL","ALLDAY"
    minLat: null,
    minLng: null,
    maxLat: null,
    maxLng: null,
    polyline: "",
    segment2Wheeler: true,
    segment3Wheeler: true,
    segment4Wheeler: true,
    segmentSwipe: true,
    power1: false,
    power2: false,
    power3: false,
    power4: false,
    connector1: false,
    connector2: false,
    connector3: false,
    connector4: false,
    connector5: false,
    connector6: false,
    connector7: false,
  });

  const debounceFilter = useDebounce(mapFilter, 500);
  const [polyline, setPolyline] = useState("");
  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    const {
      google: {
        maps: { Marker },
      },
      mapInstance,
    } = window;
    if (searchBox) {
      let places = searchBox.getPlaces();
      let place = places[0].geometry.location;
      const position = { lat: place.lat(), lng: place.lng() };
      setDefaultCenter(position);
      if (mapDirection) {
        resetRoutes();
        setPolyline("");
      }
      window.searchMarkerInstance = new Marker({
        map: mapInstance,
        position,
      });
      maps.setMapsData({
        destination: searchBox,
      });
    }
  };

  const _getSegmentFilter = () => {
    let segmentFilters = [];
    if (mapFilter && mapFilter.segment2Wheeler) {
      segmentFilters.push(2);
    }
    if (mapFilter && mapFilter.segment3Wheeler) {
      segmentFilters.push(3);
    }
    if (mapFilter && mapFilter.segment4Wheeler) {
      segmentFilters.push(4);
    }
    if (mapFilter && mapFilter.segmentSwipe) {
      segmentFilters.push(5);
    }
    return segmentFilters;
  };

  const _getPowerFilter = () => {
    let powerFilters = [];
    if (mapFilter && mapFilter.power1) {
      powerFilters.push(1);
    }
    if (mapFilter && mapFilter.power2) {
      powerFilters.push(2);
    }
    if (mapFilter && mapFilter.power3) {
      powerFilters.push(3);
    }
    if (mapFilter && mapFilter.power4) {
      powerFilters.push(4);
    }
    return powerFilters;
  };

  const _getConnectorFilter = () => {
    let allConnectorType = socketTypeList.map(({ id }) => id);
    console.log(allConnectorType)
    console.log(mapFilter)
    let selectedConnectors = Object.entries(mapFilter).reduce(
      (connectorsArr, [key, isSelected]) => {
        const matchingSocket = socketTypeList.find(
          (socket) => socket.value === key
        );
  
        if (isSelected === true && matchingSocket) {
          connectorsArr.push(matchingSocket.id);
        }
        return connectorsArr;
      },
      []
    );
    return selectedConnectors;
  };
  const _getNetworkProviderFilter = () => {
    let allProviders = Object.keys(networkFilterNameArr);
    let selectedProviders = Object.entries(mapFilter).reduce(
      (networkProviders, [key, value]) => {
        if (allProviders.includes(key) && value === true) {
          networkProviders.push(key);
        }
        return networkProviders;
      },
      []
    );
    console.log("Selected Providers:", selectedProviders); 
    return selectedProviders;
  };

  const _fetchLocation = debounce((params = null) => {
    let baseUrl = `${process.env.REACT_APP_API_END_POINT}v2/location/get`;
    let accessFilter = mapFilter.access.join();
    let segmentFilter = _getSegmentFilter().join();
    let powerFilter = _getPowerFilter().join();
    let connectorFilter = _getConnectorFilter();
    let selectedProviders = _getNetworkProviderFilter();

    if (
      mapFilter.minLat &&
      mapFilter.minLng &&
      mapFilter.maxLat &&
      mapFilter.maxLng
    ) {
      (async function () {
        try {
          setLoading(!0);
          const fetchLocation = async () => {
            let response = await axios(`${baseUrl}`, {
              method: "post",
              data: {
                access: accessFilter,
                segment: segmentFilter,
                minLat: mapFilter.minLat,
                minLng: mapFilter.minLng,
                maxLat: mapFilter.maxLat,
                maxLng: mapFilter.maxLng,
                polyline: polyline,
                provider: selectedProviders,
                // ...(params ? {
                power: powerFilter,
                connector: connectorFilter,
                // }: {
                //   ...params
                // })
              },
            });
            // localStorage.setItem('mapsData', JSON.stringify(response.data.data));
            return response.data.data;
          };

          const locations = await fetchLocation();
          setLocation(locations);
          setLoading(!1);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, 500);

  const applyFilter = (filterName, value, element) => {
    filterName &&
      setMapFilter((prevFilter) => ({
        ...prevFilter,
        [filterName]: element.target.checked,
      }));
  };

  const applyLocationFilter = (filters) => {
    let newMapFilter = { ...mapFilter, ...filters };
    setMapFilter(newMapFilter);
  };

  const handleMarkerClick = debounce(async (loc) => {
    marketLocationDetail();
    await sleep(300);
    setSelectedLocation(loc);
    setMarkerClickLocation("onMarker");
  }, 500);

  const marketLocationDetail = () => {
    setMarkerClickLocation("");
  };

  /**
   * Add map reference for further use
   * @param {*} map
   * @returns
   */
  const onMapLoad = useCallback((map) => {
    if (!map) {
      return;
    }
    window.mapInstance = map;
    setCmMap(map);
  }, []);

  /**
   * Get min/max lat/lng from map and load only that data
   * @param {*} i
   * @returns
   */
  const onMapIdle = useCallback(
    (i) => {
      if (!cmMap) {
        return;
      }
      let ne = cmMap.getBounds().getNorthEast();
      let sw = cmMap.getBounds().getSouthWest();
      let newFilter = {
        ...mapFilter,
        minLat: sw.lat(),
        minLng: sw.lng(),
        maxLat: ne.lat(),
        maxLng: ne.lng(),
      };
      if (mapFilter.minLat != sw.lat() || mapFilter.minLng != sw.lng()) {
        setMapFilter({
          ...mapFilter,
          minLat: sw.lat(),
          minLng: sw.lng(),
          maxLat: ne.lat(),
          maxLng: ne.lng(),
        });
      }
      if (cmMap.tilesloading) {
        //_fetchLocation();
      }
    },
    [cmMap, mapFilter]
  );

  const exampleMapStyles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3", visibility: "off" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948", visibility: "false" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3", visibility: "false" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
  ];

  const toggleDirection = (isOpen) => {
    setIsDirectionOpen(isOpen);
  };

  const toggleDrawer = () => {
    const isShow = !showDrawer;
    const { history, location } = window;
    const url = new URL(location.href);
    if (!isShow) {
      resetRoutes(!1);
      setPolyline("");
    }

    if (url.searchParams.has("tripId") && showDrawer) {
      history.replaceState(null, null, url.origin + url.pathname);
    }

    setShowDrawer(!showDrawer);
  };

  const setWayPoints = (waypoint, remove) => {
    let waypoints = [...elRefs.current.waypoints];
    if (isEmpty(waypoint)) {
      waypoints.length = 0;
    }

    if (!isEmpty(waypoint) && !remove) {
      Array.isArray(waypoint)
        ? (waypoints = [...waypoint])
        : waypoints.push(waypoint);
    }

    if (!isEmpty(waypoint) && remove) {
      waypoints = waypoints.filter((way) => {
        const {
          location: { lat, lng },
        } = way;
        const { location } = waypoint;
        return (
          lat().toPrecision(5) !== location.lat().toPrecision(5) &&
          lng().toPrecision(5) !== location.lng().toPrecision(5)
        );
      });
    }
    return waypoints;
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setDefaultCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        _fetchLocation();
      });
    } else {
      _fetchLocation();
    }
  }, []);

  useEffect(() => {
    _fetchLocation();
  }, [debounceFilter]);

  useEffect(() => setIsMounted(true), []);

  useEffect(() => {
    const _fetchNetworkFilters = async () => {
      let url = `${process.env.REACT_APP_API_END_POINT}v2/location/get-provider`;

      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          let fetchedNetworksObj;
          let updatedNetworkArr;
          if (response.data.data.length > 0) {
            fetchedNetworksObj = response.data.data.reduce(
              (fetchedNetworks, network) => ({
                ...fetchedNetworks,
                [network.name]: false,
              }),
              {}
            );
            updatedNetworkArr = response.data.data;
            // .reduce(
            //   (updatedNetworkArr, network) => {
            //     let updatedNetworkObj = { ...network };
            //     if (
            //       networkFilterList.find(
            //         (filter) => filter.name === network.name
            //       )
            //     ) {
            //       updatedNetworkObj = {
            //         ...updatedNetworkObj,
            //         src: networkFilterList.find(
            //           (filter) => filter.name === network.name
            //         ).src,
            //       };
            //     } else {
            //       updatedNetworkObj = {
            //         ...updatedNetworkObj,
            //         src: networkFilterList.find(
            //           (filter) => filter.name === "Generic"
            //         ).src,
            //       };
            //     }
            //     updatedNetworkArr = [...updatedNetworkArr, updatedNetworkObj];
            //     return updatedNetworkArr;
            //   },
            //   []
            // );
            setNetworkFilterNameArr({ ...fetchedNetworksObj });
            setMapFilter({ ...mapFilter, ...fetchedNetworksObj });
            setNetworkFilter(updatedNetworkArr);
          }
          setFetchedNetworkFilters(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!fetchedNetworkFilters) _fetchNetworkFilters();
  }, [fetchedNetworkFilters, mapFilter]);

  const _fetchLocationOnRoute = (polyRes) => {
    if (polyRes && polyRes.routes[0] && polyRes.routes[0].overview_path) {
      let overviewPath = polyRes.routes[0].overview_path;
      if (overviewPath.length > 0) {
        let paths = [];
        for (let i = 0; i < overviewPath.length; i++) {
          paths.push(overviewPath[i].lat());
          paths.push(overviewPath[i].lng());
        }
        setPolyline(paths.join());
      }
    }
  };

  const getDirectionHandler = useCallback(
    (start, end, waypoint = {}, waypointRemove = false) => {
      if (!start || !end) return;
      const { DirectionsService, UnitSystem } = window.google.maps;
      const service = new DirectionsService();
      elRefs.current.waypoints = setWayPoints(waypoint, waypointRemove);

      service.route(
        {
          origin: start,
          destination: end,
          waypoints: elRefs.current.waypoints,
          optimizeWaypoints: true,
          unitSystem: UnitSystem.METRIC,
          travelMode: "DRIVING",
        },
        (result, status) => {
          setMapDirection((p) => undefined);
          const { DistanceMatrixElementStatus } = window.google.maps;
          const { markerClustererInstance } = window;
          if (status === DistanceMatrixElementStatus.OK && result) {
            const { searchBarRef } = elRefs.current;
            setMapDirection(result);
            maps.mapsData.next && maps.mapsData.next.call(this);
            maps.setMapsData({
              loading: !1,
            });
            searchBarRef && (searchBarRef.value = "");
            if (result) {
              setLocation([]);
              window.clustererPrevMaxZoom =
                markerClustererInstance.getMaxZoom();
              markerClustererInstance.setMaxZoom(1);
              markerClustererInstance.repaint();
              !showDrawer && toggleDrawer();
              _fetchLocationOnRoute(result);
            }
          }

          if (status === DistanceMatrixElementStatus.ZERO_RESULTS) {
            const { setMessageOption } = message;
            maps.setMapsData((prev) => ({
              ...prev,
              loading: !1,
            }));
            setMessageOption((prev) => ({
              ...prev,
              open: !0,
              variant: "info",
              message: Constant.message.NO_ROUTE_BETWEEN_LOCATION,
            }));
          }
        }
      );
    },
    [maps, mapDirection, showDrawer]
  );

  const mapMarkers = useCallback(
    (clusterer) =>
      locations.map((loc, index) => {
        let deviceAvailable;
        if (loc.devices && loc.devices.length) {
          const { stations, status } = loc.devices[0];
          if (status && chargerStatus.includes(status)) {
            const isAnyStationAvailable =
              stations && stations.length
                ? stations.find(({ status }) =>
                    connectorStatus.available.includes(status)
                  )
                : false;
            deviceAvailable =
              status === "Active" && isAnyStationAvailable ? "A" : "NA";
          } else deviceAvailable = "U";
        }
        const hasPartyId = loc?.party_id;
        const iconSrc = GetIconSrc({
          location: loc,
          networkFilters,
          deviceAvailable,
        });
        const icon = {
          url: iconSrc,
          scaledSize: new window.google.maps.Size(55, 55),
        };
        return (
          <CMMarker
            clusterer={clusterer}
            position={{ lat: loc.latitude, lng: loc.longitude }}
            name={loc.name}
            icon={icon}
            // icon={
            //   loc.chargingType === "swapping"
            //     ? markerRed
            //     : loc.segments.includes(2)
            //     ? markerGreen
            //     : loc.segments.includes(3)
            //     ? markerBlue
            //     : loc.segments.includes(4)
            //     ? markerOrange
            //     : markerOther
            // }
            onClick={() => handleMarkerClick(loc)}
            location={loc}
            key={hasPartyId ? loc.id : loc._id}
            isThirdPartyCPO={hasPartyId}
            onDirectionHandler={getDirectionHandler}
          />
        );
      }),
    [locations]
  );
  return (
    <>
      <GoogleMap
        zoom={googleMap.DEFAULT_ZOOM}
        options={{
          minZoom: googleMap.DEFAULT_ZOOM - 8,
          maxZoom: googleMap.DEFAULT_ZOOM + 3,
          streetViewControl: false,
          mapTypeControl: false,
          mapStyle: false,
        }}
        center={center}
        mapContainerClassName="map-container"
        onLoad={onMapLoad}
        onIdle={onMapIdle}
      >
        {isMounted && (
          <MarkerClusterer
            onLoad={(clustrer) => (window.markerClustererInstance = clustrer)}
            options={{
              styles: [
                {
                  url: clusterSvg,
                  height: 53,
                  lineHeight: 53,
                  width: 53,
                },
                {
                  url: clusterSvg,
                  height: 56,
                  lineHeight: 56,
                  width: 56,
                },
                {
                  url: clusterSvg,
                  height: 66,
                  lineHeight: 66,
                  width: 66,
                },
                {
                  url: clusterSvg,
                  height: 78,
                  lineHeight: 78,
                  width: 78,
                },
                {
                  url: clusterSvg,
                  height: 90,
                  lineHeight: 90,
                  width: 90,
                },
              ],
              batchSize: 100,
              maxZoom: googleMap.CLUSTER_DEFAULT_MAX_ZOOM,
              minimumClusterSize: 1,
            }}
          >
            {mapMarkers}
          </MarkerClusterer>
        )}
        <div className="Map-toolbar">
          {loading && <LinearProgress />}
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <SearchBar
              applyFilter={applyFilter}
              toggleDirection={toggleDirection}
              mapFilter={mapFilter}
              isDirectionOpen={isDirectionOpen}
              setCenter={setDefaultCenter}
              onDirectionHandler={getDirectionHandler}
              ref={(elRef) => (elRefs.current.searchBarRef = elRef)}
              onFilter={applyLocationFilter}
              loading={loading}
              networkFiltersArr={networkFilters}
              areNetworkFiltersFetched={fetchedNetworkFilters}
            />
          </StandaloneSearchBox>
          {/* <VehicleSegment onFilter={applyFilter} data={mapFilter} /> */}
          <RoutePlanner
            onDirectionHandler={getDirectionHandler}
            onToggleDirection={toggleDirection}
            isDirectionOpen={isDirectionOpen}
          />
          {/* <Mapkey /> */}
        </div>
        <div id="marker-selector"></div>
        {/* <div className="feedback">
          <WhatAreYourThoughts />
        </div> */}
        {/* <div className="feedback">
          <Disclaimer />
        </div> */}
        {markerClickLocation === "onMarker" && (
          <MarkerLocationDetail
            selectedLocation={selectedLocation}
            marketLocationDetail={marketLocationDetail}
            onDirectionHandler={getDirectionHandler}
            map={cmMap}
            networkFiltersArr={networkFilters}
          />
        )}
        <MuiDrawer
          anchor={Constant.action.LEFT}
          open={showDrawer}
          toggleDrawer={toggleDrawer}
          className="trip-planner"
          closeToolTipText="Close Trip Planner"
        >
          <TripPlanner
            route={mapDirection}
            onDirectionHandler={getDirectionHandler}
          />
        </MuiDrawer>
        <DirectionsRenderer
          directions={mapDirection}
          onLoad={(renderer) => (window.directionRendererInstance = renderer)}
          options={{
            polylineOptions: {
              zIndex: 50,
              strokeColor: "#6bcfe8",
              strokeWeight: 5,
            },
          }}
        />
      </GoogleMap>
      {/* <CMFooter /> */}
    </>
  );
}
