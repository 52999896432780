import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "../../layout/AuthLayout";
// components
import Page from "../Page";
import { MHidden } from "../@material-extend";
import ResetPasswordForm from "../authentication/reset-password/ResetPasswordForm";
import login_banner from "../../image/login_banner.png";
import { DomainText } from "../chargeMiles/DomainText";
import { useDomainConfig } from "../../hooks/useDomainConfig";
// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const domainConfig = useDomainConfig();

  return (
    <RootStyle title="Register | Login-UI">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get Started
        </Link>
      </AuthLayout>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h6" gutterBottom>
              Reset your password
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Box>

          <ResetPasswordForm />

          <Typography
            variant="body2"
            align="center"
            sx={{ color: "text.secondary", mt: 3 }}
          >
            By registering, I agree to <DomainText />
            &nbsp;
            <Link
              href={domainConfig.termsAndConditionUrl}
              target="_blank"
              underline="always"
              sx={{ color: "primary" }}
            >
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link
              href={domainConfig.privacyPolicyUrl}
              target="_blank"
              underline="always"
              sx={{ color: "primary" }}
            >
              Privacy Policy
            </Link>
            .
          </Typography>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
