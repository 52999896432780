import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Button, Tooltip } from '@mui/material';
//Components
import MarkerInformationTab from './MarkerInformationTab';
import MarkerConnectorTab from './MarkerConnectorTab';
import CustomerReview from '../customer-review';
import Photos from '../photos';
import Constant from '../../util/constants';
import { Context } from '../../context';

// Images and Icons
import EvStationIcon from '@mui/icons-material/EvStation';
import InfoIcon from '@mui/icons-material/Info';
import ReviewsIcon from '@mui/icons-material/Reviews';
import CollectionsIcon from '@mui/icons-material/Collections';

const styles = {
  tabParent: {
    width: '100%',
    // bgcolor: '#f3f3f3',
    borderRadius: '15px', 
    // padding: '15px',
    marginBottom: '14px',
    // '& .MuiTabs-flexContainer': {
    //   width: 'max-content'
    // }
  },
  reportButton: {
    float: 'right',
    marginBottom: '16px',
    borderRadius: '24px',
    '&:hover': {
      bgcolor: 'var(--app-color)'
    }
  }
}

const TabPanel = React.memo((props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function LocationTabs({ locationDetail, onReportClick, networkFiltersArr, isThirdPartyCPO }) {
  const [value, setValue] = React.useState(0);
  const { auth, user } = useContext(Context);

  const handleChange = (event, newValue) => {
    const { authenticated, setAuthenticated } = auth;
    if([2, 3].includes(newValue) && !authenticated.isAuth){
      setAuthenticated({ 
        ...authenticated, 
        authPopup: !0,
        redirectTo: Constant.paths.HOME,
      });
      return;
    }

    setValue(newValue);
  };

  useEffect(()=> {console.log(locationDetail)}, [locationDetail])

  return (<>
    <Box className='tabs-parent' sx={styles.tabParent}>
      <Box sx={{ border: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth" className='tabs' aria-label="Location details tab">
          <Tooltip title={Constant.label.STATIONS} placement="top">
            <Tab className="tab-connector" icon={<EvStationIcon />} {...a11yProps(0)} />
          </Tooltip>
          <Tooltip title={Constant.label.INFORMATION} placement="top">
            <Tab className="tab-information" icon={<InfoIcon />} {...a11yProps(1)} />
          </Tooltip>
          <Tooltip title={Constant.label.CUSTOMER_REVIEWS} placement="top">
            <Tab className="tab-reported-issue" icon={<ReviewsIcon />} {...a11yProps(2)} />
          </Tooltip>
          <Tooltip title={Constant.label.PHOTOS} placement="top">
            <Tab className="tab-photos" icon={<CollectionsIcon />} {...a11yProps(3)} />
          </Tooltip>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="tab-list tab-location">
        <MarkerConnectorTab isThirdPartyCPO={isThirdPartyCPO} locationDetail={locationDetail} networkFiltersArr={networkFiltersArr} />
      </TabPanel>
      <TabPanel value={value} index={1} className="tab-list tab-info">
        <MarkerInformationTab locationDetail={locationDetail[0]} forLocationTab= {true} isThirdPartyCPO={isThirdPartyCPO} />
      </TabPanel>
      <TabPanel value={value} index={2} className="tab-list tab-customer-reviews">
        <CustomerReview user={user.userDetail} location={locationDetail[0]} />
      </TabPanel>
      <TabPanel value={value} index={3} className="tab-list tab-photos">
        <Photos location={locationDetail[0]} />
      </TabPanel>
    </Box>
    {![2, 3].includes(value) && 
    <Button variant="contained" data-render-report="0" onClick={onReportClick(!1, !0)} sx={styles.reportButton}>{Constant.label.REPORT_ISSUE}</Button>}
  </>);
}   

export default LocationTabs;