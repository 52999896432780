import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import axios from "axios";
import {
  Alert,
  Box,
  Divider,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  paymentStyles,
  PAYMENT_ENDPOINTS,
  PAYMENT_METHODS,
} from "./payment.constants";
import { displayRazorpay } from "./razorpay";
import { is } from "../../../util/is";
import { useDomainConfig } from "../../../hooks/useDomainConfig";

const paymentFormSchema = Yup.object().shape(
  {
    emailOrPhone: Yup.string(),
    amount: Yup.number()
      .required()
      .positive()
      .typeError("Amount must be integer")
      .test("is-decimal", "Please enter valid amount", (value) =>
        (value + "").match(/^\d+(\.\d{1,2})?$/)
      ),
    //     .when("minutes", (minutes, schema) => {
    //       return minutes > 0 ? schema.optional() : schema;
    //     }),
    //   minutes: Yup.number()
    //     .positive()
    //     .typeError("Minutes must be integer")
    //     .when("amount", (amount, schema) => {
    //       return amount > 0 ? schema.optional() : schema;
    //     }),
  },
  ["amount"]
);

export const PaymentForm = ({
  connector,
  chargerId,
  clientHandler,
  companyId,
  email,
  domain,
  customerName,
  submitHandler,
  paymentMethod,
}) => {
  const [error, setError] = useState(false);
  const firstInputRef = useRef();
  const [paymentId, setPaymentId] = useState("");
  const domainConfig = useDomainConfig();
  const paymentFormFormik = useFormik({
    initialValues: {
      emailOrPhone: email,
      customerName: customerName,
      amount: 0,
      // minutes: 0,
      kwh: 0,
    },
    validationSchema: paymentFormSchema,
    onSubmit: (formData, { setSubmitting, resetForm }) => {
      (async function () {
        setError(false);

        try {
          console.log("paymentMethod:", paymentMethod);
          if (!paymentMethod.id || !paymentMethod.keyId)
            throw new Error("Payment Id or keyId is not present");

          const paymentConfig = getPaymentConfig(paymentMethod);

          if (!paymentConfig) throw new Error("Payment Method not Supported");

          const { emailOrPhone } = formData;
          const isMobile = /^\d{10}$/.test(emailOrPhone);
          const email = !isMobile ? emailOrPhone : "";
          const mobile = isMobile ? emailOrPhone : "";
          const response = await axios(paymentConfig.url, {
            method: "post",
            data: {
              ...formData,
              paymentId,
              chargerId,
              email,
              customerName,
              companyId,
              phone:mobile,
              domain,
              countryCode: domainConfig.countryCode,
              currency: connector?.currency,
              connectorId: !is.empty(connector?.ocpi_connector_id)
                ? connector?.ocpi_connector_id
                : connector?.connector_id,
            },
          });

          if (paymentConfig.method == "razorpay") {
            if (response?.data?.id) {
              displayRazorpay(
                {
                  ...formData,
                  currency: connector?.currency,
                  orderId: response?.data?.id,
                  connectorId: !is.empty(connector?.ocpi_connector_id)
                    ? connector?.ocpi_connector_id
                    : connector.connector_id,
                  chargerId,
                  companyId,
                  keyId: paymentConfig.keyId,
                },
                submitHandler
              );
            } else throw new Error("Error initating razorpay Payment");
          } else if (paymentConfig.method == "stripe") {
            if (response?.data?.status === "requires_payment_method") {
              clientHandler(true);
            } else if (response?.data?.clientId && response?.data?.paymentId) {
              clientHandler(true, response.data.clientId);
              setPaymentId(response.data.paymentId);
            } else {
              throw new Error("Error initating stripe Payment");
            }
          } else {
            throw new Error("PaymentMethod not supported");
          }
          setSubmitting(false);
          resetForm({ values: formData });
        } catch (error) {
          console.log("payment error:", error);
          setSubmitting(false);

          setError(true);
          resetForm();
        }
      })();
    },
  });

  const getPaymentConfig = (paymentMethod) => {
    let config;
    switch (paymentMethod.id) {
      case PAYMENT_METHODS.STRIPE: {
        config = {
          keyId: paymentMethod.keyId,
          url: PAYMENT_ENDPOINTS.getClientIdUrl,
          method: "stripe",
        };
        break;
      }
      case PAYMENT_METHODS.RAZORPAY: {
        config = {
          keyId: paymentMethod.keyId,
          url: PAYMENT_ENDPOINTS.getPaymentOrderUrl,
          method: "razorpay",
        };
        break;
      }

      default: {
        console.error(paymentMethod.id, " Not found");
      }
    }

    return config;
  };

  const {
    errors,
    touched,
    dirty,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setValues,
    handleChange,
    values: { amount, emailOrPhone, kwh },
    isValid,
  } = paymentFormFormik;

  // const getMinutes = useCallback(
  //   (amount) => {
  //     const {
  //       fixedFeeCharging = 0,
  //       fixedFeeStarting = 0,
  //       kw,
  //       priceperKW = 0,
  //       priceperMin = 0,
  //       taxPercent = 0,
  //     } = connector ?? {};

  //     if (Number(priceperKW) === 0 || isNaN(Number(priceperKW))) {
  //       return (amount / priceperMin).toFixed(2).replace(/\.00$/, "");
  //     } else {
  //       const amountForCharging = amount * (1 - Number(taxPercent) / 100) - Number(fixedFeeCharging) - Number(fixedFeeStarting);
  //       const pricePerWatt = priceperKW / 1000;
  //       const totalWattToBeCharged = amountForCharging / pricePerWatt;
  //       const chargePerMin = (kw * 1000) / 60;
  //       setFieldValue(
  //         "kwh",
  //         totalWattToBeCharged > 0
  //           ? Number(totalWattToBeCharged.toFixed(2).replace(/\.00$/, "")) / 1000
  //           : 0
  //       );
  //       return (totalWattToBeCharged / chargePerMin)
  //         .toFixed(2)
  //         .replace(/\.00$/, "");
  //     }
  //   },
  //   [connector]
  // );

  const setValuesByAmount = useCallback(
    (amount) => {
      if (amount > 0) {
        const {
          fixedFeeCharging = 0,
          fixedFeeStarting = 0,
          priceperKW = 0,
          taxPercent = 0,
          transactionPercent = 0,
        } = connector || {};


        const transactionFeesGSTTax = Number(transactionPercent) / 100;
        const transactionFeesAndAdditionTax = transactionFeesGSTTax * Number(amount);
        const subtractAdditionTax = Number(amount) - transactionFeesAndAdditionTax;
        const gstTaxcalculate = 1 + (Number(taxPercent) / 100);
        const applyGstTax = subtractAdditionTax / gstTaxcalculate;
        const subtractOtherCharges =
          applyGstTax - Number(fixedFeeCharging) - Number(fixedFeeStarting);
        const totalKw = subtractOtherCharges / Number(priceperKW);

        setFieldValue("kwh", totalKw > 0 ? totalKw.toFixed(3).replace(/\.00$/, "") : 0);
      } else {
        setFieldValue("kwh", 0);
      }
    },
    [connector]
  );

  // const setValuesByMin = useCallback((minutes) => {
  //   const {
  //     fixedFeeCharging = 0,
  //     fixedFeeStarting = 0,
  //     kw,
  //     taxPercent = 0,
  //   } = connector || {};

  //   const kwh = ((Number(kw) / 60) * Number(minutes)).toFixed(2);
  //   const originalAmount = (kwh * 1) + Number(fixedFeeCharging) + Number(fixedFeeStarting);
  //   const amount = originalAmount + (originalAmount * Number(taxPercent) / 100);

  //   if (minutes > 0) {
  //     setFieldValue("kwh", kwh);
  //     setFieldValue("amount", amount, false);
  //   } else {
  //     setFieldValue("amount", 0);
  //     setFieldValue("kwh", 0);
  //   }
  // }, [connector]);

  const setValuesByKw = useCallback(
    (kwh) => {
      const {
        fixedFeeCharging = 0,
        fixedFeeStarting = 0,
        priceperKW,
        taxPercent = 0,
        transactionPercent = 0,
      } = connector || {};
      
      const cost = Number(kwh) * Number(priceperKW);
      const addSessionFees =
        cost + Number(fixedFeeCharging) + Number(fixedFeeStarting);
      const calculateGstAmount = 1 + Number(taxPercent) / 100;
      const applyGSTAmount = addSessionFees * calculateGstAmount;
      const onlyGSTTax = applyGSTAmount - addSessionFees;
      const calculateConveniencePercent = Number(transactionPercent) / 100;
      const calculateConvenienceFees =
        applyGSTAmount / (1 - calculateConveniencePercent);
      const onlyConvenienceFees = calculateConvenienceFees - applyGSTAmount;
      const totalCost = addSessionFees + onlyGSTTax + onlyConvenienceFees;

      if (kwh > 0) {
        setFieldValue("amount", totalCost.toFixed(2).replace(/\.00$/, ""));
      } else {
        setFieldValue("amount", 0);
      }
    },
    [connector]
  );

  useEffect(() => {
    clientHandler(false);
  }, [amount]);

  useEffect(() => {
    firstInputRef?.current?.focus();
    setValues({ amount: 0, emailOrPhone, kwh: "" });
  }, [connector?.connector_id]);

  const _handleChange = (e) => {
    const { name, value } = e.currentTarget;
    // if (name === 'minutes') {
    //   setValuesByMin(+value);
    // }

    if (name === "amount") {
      setValuesByAmount(+value);
    }

    if (name === "kwh") {
      setValuesByKw(+value);
    }
    handleChange(e);
  };

  return (
    <Slide direction="up" in={!!email}>
      <Box className="tabs-parent" sx={paymentStyles.tabParent}>
        {error && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ marginBottom: "20px", color: "red" }}
          >
            Some Error Occured! Please try again.
          </Alert>
        )}
        <Typography variant="caption" mb={3}>
          Cost per KWH: {connector?.rate || "N/A"}
        </Typography>
        <FormikProvider value={paymentFormFormik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Email or Phone"
                {...getFieldProps("emailOrPhone")}
                error={Boolean(touched.emailOrPhone && errors.emailOrPhone)}
                helperText={touched.emailOrPhone && errors.emailOrPhone}
                sx={{ backgroundColor: "white" }}
                disabled={true}
              />
              <Divider sx={{ margin: "20px 0" }} />
              <TextField
                required
                inputRef={firstInputRef}
                label="Enter amount to  Charge"
                {...getFieldProps("amount")}
                error={Boolean(touched.amount && errors.amount)}
                onChange={_handleChange}
                type="number"
                helperText={
                  (touched.amount && errors.amount) ||
                  "*Price is inclusive of GST and other service charges"
                }
                sx={{ backgroundColor: "white" }}
              />
              {/* <Divider sx={{ margin: "20px 0" }}></Divider>
              <TextField
                label="Minutes to charge"
                {...getFieldProps("minutes")}
                onChange={_handleChange}
                error={Boolean(touched.minutes && errors.minutes)}
                helperText={"*Minutes are indicative. Actual may vary"}
                sx={{ backgroundColor: "white" }}
              /> */}
              <Divider sx={{ margin: "20px 0" }}></Divider>
              <TextField
                label="Total KW to be charged"
                {...getFieldProps("kwh")}
                onChange={_handleChange}
                type="number"
                sx={{ backgroundColor: "white" }}
              />
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                disabled={!(isValid && dirty)}
                type="submit"
                sx={{ marginTop: "20px" }}
              >
                Continue
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>
      </Box>
    </Slide>
  );
};
