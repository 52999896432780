import { cmsBaseUrlV1 } from "../../../react-query/endpoints";

export const PAYMENT_ENDPOINTS = {
  getClientIdUrl: `${cmsBaseUrlV1}/transactions/stripe/clientid`,
  getCreatePaymentUrl: `${cmsBaseUrlV1}/transactions/razorpay/create/payment`,
  getPaymentStatusUrl: `${window.location.origin}/payment-status`,
  getPaymentOrderUrl: `${cmsBaseUrlV1}/transactions/razorpay/create/order`,
  getVerifyPaymentUrl: `${cmsBaseUrlV1}/transactions/razorpay/verify/payment`,
};

export const paymentStyles = {
  tabPaymentIcons:{
    width: "100%",
    bgcolor: "#f3f3f3",
    borderRadius: "15px",
    padding: "15px",
    marginBottom: "14px",
    display: "flex",
    flexDirection: "column",
  },
  tabParent: {
    width: "100%",
    bgcolor: "#f3f3f3",
    borderRadius: "15px",
    padding: "15px",
    marginBottom: "14px",
    display: "flex",
    flexDirection: "column",
  },
  reportButton: {
    float: "right",
    marginBottom: "16px",
    borderRadius: "24px",
    "&:hover": {
      bgcolor: "var(--app-color)",
    },
  },
  listItemBox: {
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    display: "inline-flex",
    paddingBottom: "10px",
  },
  listItemPaper: {
    width: "100%",
    borderRadius: "3px",
    display: "flex",
    padding: "5px 12px 5px 8px",
    alignItems: "center",
  },
  listItemBold: {
    fontWeight: "600",
    textAlign: "right",
  },
  listPaperImage: {
    height: 32,
    width: 32,
    verticalAlign: "middle",
  },
  listItemText: {
    fontSize: "0.7em",
  },
};

export const PAYMENT_METHODS = {
  RAZORPAY:1,
  STRIPE:7
};