import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import {
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { IconButton, Typography, Paper, Box, Stack, Button } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import Constant from "../../util/constants";
import { getConnectors } from "../../util/Display";
import { Context } from "../../context";
import { getGeoLocation, toggleFavourite, constructDirMapUrl, hasAddedToTrip } from "../../util/Display";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import debounce from "lodash/debounce";
import { StyledButton } from "../common/Styled";

const styles = {
  speedIth: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  titles: {
    display: "flex",
    flexDirection: "column",
  },
};

const initialState = {
  showInfoWindow: false,
  favourite: !1,
  status: false,
  animation: 2,
  mapUrl: '',
  isAddedToStop: !1
};

export default function CMMarker({
  position,
  location,
  icon,
  onClick,
  clusterer,
  onDirectionHandler
}) {
  const [CMMarkerState, setCMMarketState] = useState(initialState);
  const { user, maps, auth } = useContext(Context);
  const markerRef = useRef({ refMarker: null, infoRef: null, isMouseOverOnInfo: false });

  const animationTimeout = debounce(() => {
    setCMMarketState(prev => ({
      ...prev,
      animation: null
    }))
  } , 1000);

  const handleMouseOver = (e) => {
    setCMMarketState({
      ...CMMarkerState,
      showInfoWindow: !0,
    });
  };

  const handleMouseExit = () => {
    const { isMouseOverOnInfo } = markerRef.current;
    if (!isMouseOverOnInfo) {
      setTimeout(() => { 
        setCMMarketState({
            ...CMMarkerState,
            showInfoWindow: !1,
        });
      },100);
    }
  }

  const toggleFavorites = async () => {
    setCMMarketState((prev) => ({
      ...prev,
      favourite: !CMMarkerState.favourite,
    }));
    toggleFavourite(auth, user, CMMarkerState, location._id);
  };

  const onDirection = async () => {
    const pos = await getGeoLocation();
    maps.setMapsData({
      direction: [
        { lat: pos.latitude, lng: pos.longitude },
        { lat: location.latitude, lng: location.longitude },
      ],
    });
  };

  const onMarkerClick = () => {
    setCMMarketState(prev => ({
      ...prev,
      animation: 1
    }))
    onClick();
    animationTimeout();
  }

  const addStop = (e) => {
    const { directionRendererInstance, google:{ maps } } = window;
    const direction = directionRendererInstance.getDirections();
    if(direction) {
      const { request: { destination, origin } } = direction;
      const { latitude, longitude} = location;
      const { isAddedToStop } = CMMarkerState
      onDirectionHandler(
        { lat: origin.location.lat(), lng: origin.location.lng() },
        { lat: destination.location.lat(), lng: destination.location.lng() },
        { location: new maps.LatLng(latitude, longitude), stopover: true },
        isAddedToStop
      );
      setCMMarketState((state) => ({
        ...state,
        isAddedToStop: !isAddedToStop
      }))
    }
  }

  const markerInfoHandler = useCallback((e) => {
    if (e.type === 'mouseover') {
      markerRef.current.isMouseOverOnInfo = true;
    }

    if (e.type === 'mouseleave') {
      markerRef.current.isMouseOverOnInfo = false;
      setCMMarketState({
        ...CMMarkerState,
        showInfoWindow: !1
      })
    }
  }, []);

  const onInfoWindowEvent = useCallback((onEvent) => () => {
    const markerInfo = document.querySelector('.marker-info');
    const iw_t = document.querySelectorAll('.gm-style-iw-a');
    const iw_empty = ['<div></div>', ''];
    if(iw_t && iw_t.length) {
      iw_t.forEach((iw) => {
        const { firstElementChild: { firstElementChild }, parentNode } = iw;
        const iw_c = firstElementChild.firstChild.innerHTML;
        if(iw_empty.includes(iw_c) && parentNode) {
          parentNode.removeChild(iw);
        }
      })
    }

    if(markerInfo) {
      markerRef.current.infoRef = markerInfo;
      if('onDomReady' === onEvent) {
        markerInfo.addEventListener('mouseover', markerInfoHandler);
        markerInfo.addEventListener('mouseleave', markerInfoHandler);
      }
    }
  }, []);

  useEffect(() => {
    const { userDetail } = user;
    if (!isEmpty(userDetail)) {
      const { favoriteLocations } = userDetail;
      let toggle = false;
      favoriteLocations.some((loc) => loc._id === location._id)
        ? (toggle = !0)
        : (toggle = !1);
      toggle && setCMMarketState((prev) => ({
        ...prev,
        favourite: toggle,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (CMMarkerState.showInfoWindow) {
      const { directionRendererInstance } = window;
      const direction = directionRendererInstance.getDirections();
      if(direction) {
        const { latitude, longitude } = location;
        const addedToStop = hasAddedToTrip(latitude, longitude);
        setCMMarketState((state) => ({
          ...state,
          mapUrl: Constant.types.NULL,
          isAddedToStop: addedToStop
        }))
        return;
      }

      constructDirMapUrl({ destination: location.name })
      .then((url) => {
        setCMMarketState((state) => ({
          ...state,
          mapUrl: url
        }));
      }).catch((err) => console.log(err));
    }
  }, [CMMarkerState.showInfoWindow]);

  return (  
    <Marker
      icon={icon}
      position={position}
      animation={CMMarkerState.animation}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseExit}
      onClick={onMarkerClick}
      clusterer={clusterer}
      ref={(el) => markerRef.current.refMarker = el}
    >
      {CMMarkerState.showInfoWindow && (
        <InfoWindow onDomReady={onInfoWindowEvent('onDomReady')} options={{ maxWidth: 250 }}>
          <Paper component="div" className="marker-info" elevation={0}>
            <Stack direction="column">
              <Box className="marker-info--icons">
                <div className="float-left">
                  <IconButton color="primary" aria-label="location">
                    <img
                      src={
                        getConnectors(location?.devices)[0].vehicleSegmentImage
                      }
                      alt="segment"
                      width={18}
                    />
                  </IconButton>
                  <IconButton
                    onClick={toggleFavorites}
                    color="primary"
                    aria-label="location"
                  >
                    {!CMMarkerState.favourite ? (
                      <FavoriteBorderOutlinedIcon />
                    ) : (
                      <FavoriteOutlinedIcon />
                    )}
                  </IconButton>
                </div>
                <div className="float-right">
                  <IconButton
                    onClick={onDirection}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <DirectionsIcon />
                  </IconButton>
                </div>
              </Box>
              <Box className="marker-info--name">
                <Typography
                  variant="subtitle1"
                  textAlign="left"
                  lineHeight={1.3}
                  ml={1.5}
                  fontSize="0.8rem"
                  textTransform='capitalize'
                  gutterBottom
                >
                  {location.display_name?location?.display_name:location?.name}
                </Typography>
              </Box>
              <Box className="marker-info--service-24x7" mb={0}>
                <Typography
                  variant="caption"
                  component="div"
                  textAlign="left"
                  ml={1.5}
                  color="var(--text-secondary)"
                >
                  {location?.isPaymentRequired
                    ? Constant.label.CHARGE_PAID
                    : Constant.label.CHARGE_FREE}
                </Typography>
              </Box>
              <Box className="marker-info--service-24x7" mb={0}>
                <Typography
                  variant="caption"
                  component="div"
                  textAlign="left"
                  ml={1.5}
                  color="var(--text-secondary)"
                >
                  {`${location?.devices?.length} Station available`}
                </Typography>
              </Box>
              
              <Box className="market-info--points" ml={1.5} mb={1}>
                <Stack direction="row" spacing={2}>
                  {getConnectors(location?.devices).map((v, i) => {
                    console.log(`v: ${JSON.stringify(v)}`);
                    return (
                      <Box
                        className={`speed-${i}`}
                        sx={styles.speedIth}
                        key={i + 1}
                      ><span className="map-connector-type-icon">{v.connectorTypeImage}</span>
                        {/* <img
                          src={v.connectorTypeImage}
                          width={32}
                          height={32}
                          alt={`image-${i}`}
                        /> */}
                         <Typography
                            variant="caption"
                            color="var(--text-secondary)"
                          >
                            x{v.totalPoints}
                          </Typography>
                        
                      </Box>
                    )
                  }
                  )}
                </Stack>
              </Box>
              <Box className="marker-info--google-dir" mb={1}>
                  {!isNull(CMMarkerState.mapUrl) ? 
                  <Button href={CMMarkerState.mapUrl} variant="text" target="_blank">{Constant.label.VIEW_ON_GOOGLE_MAP}</Button> :
                  <StyledButton onClick={addStop} variant="contained" color={!CMMarkerState.isAddedToStop ? 'primary' : 'error'} size="small" fullWidth>
                    {!CMMarkerState.isAddedToStop ? Constant.label.ADD_TO_TRIP_PLANNER : Constant.label.REMOVE_TRIP}
                  </StyledButton>}
              </Box>
            </Stack>
          </Paper>
        </InfoWindow>
      )}
    </Marker>
  );
}
