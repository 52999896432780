import { useState } from "react";
import { ratingClasses, Stack, Typography } from "@mui/material";
//import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Angry2 from "../image/feedbackIcons/Feedbackicon-01.png";
import Angry3 from "../image/feedbackIcons/Feedbackicon-02.png";
import Angry4 from "../image/feedbackIcons/Feedbackicon-03.png";
import Angry5 from "../image/feedbackIcons/Feedbackicon-04.png";
import Angry6 from "../image/feedbackIcons/Feedbackicon-05.png";
import Angry12 from "../image/feedbackIcons/Feedbackicon-11.png";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import axios from "axios";
import UploadPhoto from "./common/UploadPhoto";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CMFooter } from "./common/CMFooter";
import { useNavigate } from "react-router-dom";

const FeedBack = () => {
  const views = [
    { name: "Angry", icons: "Angry2" },
    { name: "Upset", icons: "Angry3" },
    { name: "Neutral", icons: "Angry4" },
    { name: "Happy", icons: "Angry5" },
    { name: "Exited", icons: "Angry6" },
  ];
  const navigate = useNavigate();
  const [reaction, setReaction] = useState(1);
  const [reactionImage, setReactionImage] = useState(Angry2);
  const [type, setType] = useState("FEEDBACK");
  const [attachment, setAttachment] = useState();
  const [snackBarStatus, setSnackbarStatus] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState(
    "Feedback has been submitted successfully"
  );

  const handleCategoryChange = (event, newAlignment) => {
    setType(newAlignment);
  };

  const onCancel = () => {
    navigate("/");
  };
  const showMessage = () => {
    setSnackbarStatus(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStatus(false);
  };

  const snackBarAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const uploadImage = (binaryString) => {
    if (!binaryString) {
      return;
    }
    let addImagePayload = {
      image: binaryString,
    };
    (async function () {
      try {
        const addImage = async () => {
          console.log(process.env.REACT_APP_SERVER_URL);
          let response = await axios(
            `${process.env.REACT_APP_API_END_POINT}util/image-upload`,
            {
              method: "post",
              data: addImagePayload,
            }
          );
          if (response && response.data) {
            setAttachment(response.data.data);
          }
        };
        await addImage();
      } catch (error) {
        console.error(error.response);
      }
    })();
  };

  const submitFeedback = (feedbackData, { setSubmitting, resetForm }) => {
    feedbackData.type = type;
    feedbackData.reaction = reaction;
    feedbackData.attachment = attachment;
    (async function () {
      try {
        const addFeedback = async () => {
          console.log(process.env.REACT_APP_SERVER_URL);
          let response = await axios(
            `${process.env.REACT_APP_API_END_POINT}feedback/add`,
            {
              method: "post",
              data: feedbackData,
            }
          );
          let tokenResp = response.data.data;
          resetForm();
          setSnackbarStatus(true);
        };
        await addFeedback();
      } catch (error) {
        console.error(error.response);
      }
    })();
  };

  return (
    <div className="section-feedback">
      <Formik
        initialValues={{
          type: "",
          reaction: "",
          comment: "",
          name: "",
          attachment: "",
        }}
        validationSchema={Yup.object({
          type: Yup.string(),
          reaction: Yup.string(),
          comment: Yup.string().required("Please compose your feedback"),
          name: Yup.string().required("Please add your corresponding alias"),
          attachment: Yup.string(),
        })}
        onSubmit={submitFeedback}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form>
            <h1>Feedback </h1>
            <Typography variant="h6" style={{ color: "grey" }}>
              {" "}
              Rate Your Experience
            </Typography>
            <div>
              <img src={reactionImage} alt={reactionImage} />
            </div>
            <Stack
              spacing={2}
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <div>
                <img
                  src={Angry2}
                  alt={Angry2}
                  onClick={(e) => {
                    setReactionImage(Angry2);
                    setReaction(1);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={Angry3}
                  alt={Angry3}
                  onClick={(e) => {
                    setReactionImage(Angry3);
                    setReaction(2);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={Angry4}
                  alt={Angry4}
                  onClick={(e) => {
                    setReactionImage(Angry4);
                    setReaction(3);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={Angry5}
                  alt={Angry5}
                  onClick={(e) => {
                    setReactionImage(Angry5);
                    setReaction(4);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={Angry6}
                  alt={Angry6}
                  onClick={(e) => {
                    setReactionImage(Angry6);
                    setReaction(5);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <img src={Angry12} alt={Angry5} style={{ maxWidth: "320px" }} />
            </Stack>
            <div>
              <h4>Please select Your Feedback Categories Below</h4>
              <Stack
                spacing={2}
                direction="row"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={type}
                  exclusive
                  onChange={handleCategoryChange}
                >
                  <ToggleButton value="FEEDBACK" fullWidth>
                    Feedback
                  </ToggleButton>
                  <ToggleButton value="BUG" fullWidth>
                    Bug
                  </ToggleButton>
                  <ToggleButton value="FEATURE" fullWidth>
                    Suggest Feature
                  </ToggleButton>
                  <ToggleButton value="LOCATION" fullWidth>
                    Report Charging Point Issues
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </div>
            <Stack
              spacing={2}
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: "10px" }}
            >
              <Box
                style={{ justifyContent: "center" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  label="Please Leave your comment below"
                  name="comment"
                  value={values.comment}
                  onChange={handleChange}
                  error={Boolean(touched.comment && errors.comment)}
                  helperText={touched.comment && errors.comment}
                />
              </Box>
              <Box
                style={{ justifyContent: "center" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  name="name"
                  label="Your name/ Email"
                  value={values.name}
                  onChange={handleChange}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <UploadPhoto
                setImageSrc={setAttachment}
                imageSrc={attachment}
                uploadImage={uploadImage}
              />
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Button variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackBarMessage}
        action={snackBarAction}
      />
      {/* <CMFooter /> */}
    </div>
  );
};

export default FeedBack;
