import { memo, useEffect, useMemo, useReducer, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Constant from '../../util/constants';
import { StyledImg } from '../common/Styled';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { getDistance, getDuration } from '../../util/Display';
import { constructDirMapUrl } from '../../util/Display';
import Token from '../../util/Token';
import { useCreateTrip, useUpdateTrip } from '../../react-query/hooks/useTrip';

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import BalloonMarkGreen from '../../image/balloon_green.svg';
import { ReactComponent as GoogleMapsIcon } from '../../image/GoogleMaps.svg';
import { Context } from '../../context';

const { label } = Constant;

const types = {
    [label.VIEW_DIRECTIONS]: 'VIEW_DIRECTIONS',
}

const reducer = (state, action) => {
    switch(action.type) {
        case types[label.VIEW_DIRECTIONS]:
            return { ...state, [label.VIEW_DIRECTIONS]: action.value }
    }
}

const initialState = {
    [label.VIEW_DIRECTIONS]: ''
}

function TripPlanner({ route, onDirectionHandler }) {
    const { auth, user } = useContext(Context);
    const [tripState, dispatch] = useReducer(reducer, initialState);
    const createTrip = useCreateTrip();
    const updateTrip = useUpdateTrip();
    

    const legs = useMemo(() => {
        if (!isEmpty(route)) {
            const { legs } = route.routes[0];
            const distAndDuration = legs.reduce((prev, leg) => {
                prev.totalDistance = prev.totalDistance || 0;
                prev.totalDuration = prev.totalDuration || 0;
                prev.totalDuration += leg.duration.value;
                prev.totalDistance += leg.distance.value;
                return prev;
            }, {});
            return {
                waypoints: legs,
                end: legs[legs.length - 1],
                distAndDuration
            }
        }
    }, [route]);

    const removeStop = (leg) => () => {
        const { start_location: { lat, lng } } = leg;
        const { directionRendererInstance, google: { maps } } = window;
        const direction = directionRendererInstance.getDirections();
        if(direction) {
            const { request: { destination, origin } } = direction;
            onDirectionHandler(
              { lat: origin.location.lat(), lng: origin.location.lng() },
              { lat: destination.location.lat(), lng: destination.location.lng() },
              { location: new maps.LatLng(lat(), lng()) },
              true
            )
        }
    }
    
    const saveTrip = () => {
        const { authenticated, setAuthenticated } = auth;
        if(!authenticated.isAuth){
            setAuthenticated({ 
              ...authenticated, 
              authPopup: !0,
              redirectTo: Constant.paths.HOME,
            });
            return;
          }

        const { location } = window;
        const { legs } = route.routes[0];
        const waypoint = [...legs];
        const to = legs[legs.length-1];
        const from = legs[0];
        const url = new URL(location.href);
        let via = [];

        if (legs.length === 2) {
            via.push({
                latitude: from.end_location.lat(),
                longitude: from.end_location.lng(),
                address: from.end_address
            })
        } else if(legs.length > 2) {
            waypoint.shift();
            via = waypoint.map((way) => ({
                latitude: way.start_location.lat(),
                longitude: way.start_location.lng(),
                address: way.start_address
            }))
        }

        const token = Token.parsedToken();
        const tripReqBody = {
            user: token.data._id,
            from: {
                latitude: from.start_location.lat(),
                longitude: from.start_location.lng(),
                address: from.start_address
            },
            to: {
                latitude: to.end_location.lat(),
                longitude: to.end_location.lng(),
                address: to.end_address
            },
            via,
            ...(url.searchParams.has('tripId') && {
                _id: url.searchParams.get('tripId')
            }),
        }
        if (!url.searchParams.has('tripId'))
            createTrip.mutate(tripReqBody);

        if (url.searchParams.has('tripId')) 
            updateTrip.mutate(tripReqBody)
    }
    // construct the google map url
    useEffect(() => {
        if (route) {
            constructDirMapUrl({})
            .then((url) => {
                dispatch({
                    type: types[label.VIEW_DIRECTIONS],
                    value: url
                });
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [route]);

    return (
        <Box className="MuiDrawer-paper__trip-planner">
            <Typography variant="h5">{label.TRIP_PLANNER}</Typography>
            {(createTrip.isLoading || updateTrip.isLoading) && <div className='overlay'> <CircularProgress /> </div>}
            {!isEmpty(legs) && <Grid container>
                <Grid item xs={12} md={12}>
                    <Box className="px-3 py-2">
                        <Stack className="trip-panner__total py-2" justifyContent="space-between" direction="row" alignItems="center">
                            <Paper elevation={0} component="div">
                                <font className="font-weight-500">Total Distance:</font>
                                <font className="font-weight-700 ml-1">{getDistance(legs.distAndDuration.totalDistance)}</font>
                            </Paper>
                            <span className=''>
                                <span className='image'><AccessTimeIcon className='inline-block align-middle' /> Approx:</span>
                                <font className="font-weight-700 ml-1">{getDuration(legs.distAndDuration.totalDuration)}</font>
                            </span>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} className="py-2">
                    <Stack justifyContent="space-around" direction="row">
                        <Box className="trip-planner--share-trip trip-action">
                            <a href="#">
                                <ShareOutlinedIcon />
                                <Typography variant="subtitle2">{label.SHARE}</Typography>
                            </a>
                        </Box>
                        <Box className="trip-planner--save-trip trip-action">
                            <a href="#" role="button" onClick={saveTrip}>
                                <RouteOutlinedIcon />
                                <Typography variant="subtitle2">{label.SAVE}</Typography>
                            </a>
                        </Box>
                        <Box className="trip-planner--save-trip trip-action">
                            <a href={tripState[label.VIEW_DIRECTIONS]} target="_blank" title="View in Google Map">
                                <GoogleMapsIcon height={24} width={24} />
                                <Typography variant="subtitle2">
                                    {label.VIEW_DIRECTIONS}
                                </Typography>
                            </a>
                        </Box>
                    </Stack>
                </Grid>
                {legs.waypoints.map((leg, idx) => (
                    <Grid item xs={12} md={12} key={uuidv4()}>
                        <Box className="px-3 py-3">
                            <Stack className="trip-planner__start-point py-2" direction="row" spacing={2} alignItems="center">
                                <span className='image'>
                                    <StyledImg src={BalloonMarkGreen} height={42} width={42} />
                                </span>
                                <Paper elevation={0} component="span">
                                    {idx === 0 && 
                                    <Typography variant="subtitle1" className='trip-planner__label'>
                                        {label.STARTING_POINT}
                                    </Typography>}
                                    <Typography variant="subtitle1" className="trip-planner__location">{leg.start_address}</Typography>
                                </Paper>
                                {idx !== 0 && 
                                <IconButton onClick={removeStop(leg)} size="small" aria-label="remove stop">
                                    <ClearOutlinedIcon />
                                </IconButton>}
                            </Stack>
                            <Stack className="trip-panner__metric-duration py-2" justifyContent="space-around" direction="row" alignItems="center">
                                <Chip label={leg.distance.text} className="bg-grey-300 text-white font-weight-500" />
                                <span className='trip-planner__label'>
                                    <span className='image'><AccessTimeIcon /></span>
                                    <span className='trip-planner__duration ml-1'>{leg.duration.text}</span>
                                </span>
                            </Stack>
                            {idx === 0 && 
                            <Paper component="span" elevation={0}>
                                Click on multiple location balloons along the route as stopover point
                            </Paper>}
                        </Box>
                    <Divider variant="middle" />
                </Grid>))}
                <Grid item xs={12} md={12}>
                    <Box className="px-3 py-3">
                        <Stack className="trip-planner__start-point py-2" direction="row" spacing={2} alignItems="center">
                            <span className='image'>
                                <StyledImg src={BalloonMarkGreen} height={42} width={42} />
                            </span>
                            <Paper elevation={0} component="span">
                                <Typography variant="subtitle1" className='trip-planner__label'>{label.DESTINATION_POINT}:</Typography>
                                <Typography variant="subtitle1" className="trip-planner__location">{legs.end.end_address}</Typography>
                            </Paper>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>}
        </Box>
    )
}

export default memo(TripPlanner);