import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../Page";
import AccountForm from "../account/AccountForm";

export default function Account() {
  return (
    <Page title="Dashboard | Account-UI">
      <Container maxWidth="xl" sx={{ mt: 8 }}>
        <AccountForm />
      </Container>
    </Page>
  );
}
