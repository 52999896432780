import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState, useEffect, useContext } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../Page";
import Scrollbar from "../Scrollbar";
import SearchNotFound from "./SearchNotFound";
import UserListHead from "./UserListHead";
import UserListToolbar from "./UserListToolbar";
import UserMoreMenu from "./UserMoreMenu";
import { Context } from "../../context";
import axios from "../../util/axios";
import Token from "../../util/Token";
import AddVehicle from "./AddVehicleTab";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteVehicleUrl } from "../../react-query/endpoints";
import AddVehicleModal from "./AddVehicleModal";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "make", label: "Make", alignRight: false },
  { id: "model", label: "Model", alignRight: false },
  { id: "charger",label:"Charger",alignRight:false },
  { id: "color",label:"Color",alignRight:false },
  { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    // return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    const filteredSearch = stabilizedThis
      .filter((vehicleObj) =>
        vehicleObj[0].make.toLowerCase().includes(query.trim().toLowerCase())
      )
      .map((vehicleObj) => vehicleObj[0]);
    return filteredSearch;
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function MyVehicle() {
  const { user, message } = useContext(Context);
  const { userDetail } = user;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userList, setUserList] = useState([]);
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
 

  const fetchUsersVehicleList = () => {
    (async function () {
      try {
        const getUsersVehicleList = async () => {
          const response = await axios(
            `${process.env.REACT_APP_API_END_POINT}user/list-vehicle?user=${userDetail._id}`,
            {
              method: "get",
            }
          );
          const userTypeListRes = response.data.data;
          setUserList(userTypeListRes);
        };

        await getUsersVehicleList();
      } catch (error) {
        console.error(error.response);
      }
    })();
  };

  const deleteVehicle = async ({ vehicleId }) => {
    const endpoint = deleteVehicleUrl(vehicleId);
    return await axios(endpoint, {
      method: "delete",
    });
  };

  const deleteMyVehicle = useMutation(deleteVehicle, {
    onSuccess: () => {
      console.log("deleted successfully");
      fetchUsersVehicleList();
    },
  });

  useEffect(() => {
    if (!userDetail?._id) {
      return;
    }
    fetchUsersVehicleList();
  }, [userDetail]);

  const handleDeleteBtnClick = (vehicleId) => {
    deleteMyVehicle.mutate({ vehicleId });
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((vehicleObj) => vehicleObj._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const addVehicleHandler = () => {
    setSelectedVehicle(null);
    setShowAddVehicle(true);
  };

  const editVehicleHandler = (vehicleToEdit) => {
    setSelectedVehicle(vehicleToEdit);
    setShowAddVehicle(true);
  };

  const AddVehicleModalClose = () => {
    fetchUsersVehicleList();
    setShowAddVehicle(false);
  };

  return (
    <Page title="User | ChargeMiles-UI">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Button
          variant="contained"
          startIcon={<Icon icon={plusFill} />}
          onClick={addVehicleHandler}
        >
          Add Vehicle
        </Button>
      </Stack>
      {!showAddVehicle && (
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { _id, make, model, charger,color,status } = row;
                      const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, _id)}
                          /> */}
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {make}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {model}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {charger}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {color}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ color: status === 1 ? "green" : "red" }}
                          >
                            {status === 1 ? "Active" : "Inactive"}
                          </TableCell>

                          <TableCell align="right" sx={{borderTop:'1px solid rgba(224, 224, 224, 1)'}}>
                            <UserMoreMenu
                              editVehicleHandler={editVehicleHandler}
                              onDeleteHandler={handleDeleteBtnClick}
                              selectedVehicle={row}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}

      {showAddVehicle && (
        // <Card sx={{padding:2}}>
        //   <AddVehicle
        //     setShowAddVehicle={setShowAddVehicle}
        //     fetchUsersVehicleList={fetchUsersVehicleList}
        //     selectedVehicle={selectedVehicle} />
        // </Card>
        <AddVehicleModal open={showAddVehicle} onClose={AddVehicleModalClose}  />
      )}
      
    </Page>
  );
}
