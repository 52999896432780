import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Alert, Button, Skeleton } from "@mui/material";
import { Overlay } from "../../Overlay";
import { PAYMENT_ENDPOINTS } from "./payment.constants";
import { useEffect } from "react";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const CheckoutForm = ({ submitHandler, keyId }) => {
  const [isFormLoaded, setFormLoaded] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  //   elements &&
  //   elements.getElement("payment") &&
  //   elements.getElement("payment").focus();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setSubmitting(true);
    try {
      const { paymentIntent, error } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        // confirmParams: {
        //   return_url: `${PAYMENT_ENDPOINTS.getPaymentStatusUrl}?id=${keyId}`,
        // },
        redirect: "if_required",
      });
      if (paymentIntent && paymentIntent.status === "requires_capture") {
        submitHandler({
          isDone: true,
          status: true,
          message:
            "Payment is successful! Please wait, charger will start in a while.",
        });
      } else if (error && error.type !== "validation_error") {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        throw new Error(error.message);
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      setErrorMessage(e.message || "something went wrong! Please try again");
    }
  };

  return (
    <>
      {isSubmitting && <Overlay />}
      <form onSubmit={handleSubmit}>
        {errorMessage && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ marginBottom: "20px", color: "red" }}
          >
            {errorMessage}
          </Alert>
        )}
        <PaymentElement
          options={{
            terms: { card: "always" },
          }}
          onReady={() => {
            setFormLoaded(true);
          }}
        />
        {isFormLoaded ? (
          <Button
            variant="contained"
            type="submit"
            disabled={!stripe}
            sx={{ marginTop: "20px" }}
          >
            Pay & Start Charge
          </Button>
        ) : (
          <Skeleton variant="rectangular" />
        )}
      </form>
    </>
  );
};
export const StripeCheckout = ({ clientId, submitHandler, keyId }) => {
  const options = {
    clientSecret: clientId,
    loader: "always",
  };

  const [stripePromise, setStripePromise] = useState();
  useEffect(() => {
    console.log(keyId);
    if (keyId) {
      const stripe = loadStripe(keyId);
      setStripePromise(stripe);
    }
  }, [keyId]);

  console.log(keyId, stripePromise);
  return (
    <>
      {clientId && stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm submitHandler={submitHandler} keyId={keyId} />
        </Elements>
      )}
    </>
  );
};
