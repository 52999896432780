import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { chargerTransactionActivityUrl } from '../../react-query/endpoints';
import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import {  Card, CircularProgress, Container, Grid, IconButton, Paper, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { differenceInSeconds, format, formatDuration, intervalToDuration, isValid } from 'date-fns';
import { is } from '../../util/is';
import { Icon } from '@iconify/react';
import ChargingFill from "@iconify/icons-eva/charging-outline";
import AlertFill from "@iconify/icons-eva/alert-triangle-fill";
import StopIcon from "../../image/stop_icon.svg";
import RefreshIcon from "../../image/refresh_icon.svg";
import Page from '../Page';
import { MuiSnackBar } from '../@material-extend';
import ChargemilesLogo from "../../image/networkFilter/Chargemiles.png";
import MoveonEVLogo from "../../image/networkFilter/MoveonEV.png";
import AmikLogo from "../../image/networkFilter/AmikCharge.png";
import TechvoltLogo from "../../image/networkFilter/techvoltNetwork.png";


//------------------------------------------------CONSTANTS------------------------------

const APP_GREEN = "#48C13E";
const STATUS_ARR = [
  { status: "Preparing", message: "Plug in the charging cable to your vehicle.", variant: "error" },
  { status: "Charging", message: "Charging in progress.", variant: "success" },
  { status: "Available", message: "Connection in progress.", variant: "success" },
  { status: "SuspendedEV", message: "Charging stopped by EV.", variant: "error" },
  { status: "SuspendedEVSE", message: "Plug in the charging cable to your vehicle.", variant: "error" },
  { status: "Finishing", message: " Finishing the charger session", variant: "success" },
  { status: "Faulted", message: " A Fault occurred", variant: "error" },
]

const CURRENCY_ENUM = {
    CAD:"C$",
    INR:"₹"
}
//------------------------------------------------------------------------------------------


//-----------------------------------------------STYLED AND HELPER COMPONENTS----------------------------------------------------------------------

export const StyledTypography = styled(Typography)(({ theme, color, fontSize, fontWeight, backgroundColor }) => ({
  color: color ? color : "black",
  fontSize: fontSize ? fontSize : theme.spacing(2.3),
  fontWeight: fontWeight ? fontWeight : "500",
  backgroundColor: backgroundColor ? backgroundColor : "#ffff"
}));

export const StyledCard = styled(Card)(({ theme, backgroundColor }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  backgroundColor: backgroundColor ? backgroundColor : "#ffff",
  [theme.breakpoints.down('sm')]: {
    padding: "5px",
    minHeight: "80px"
  }
}));

export const BaseChart = ({ options, series, type, width, height }) => {
  return <ReactApexChart options={options} series={series} type={type} height={height} width={width} />
};

export const ErrorComponent = ({message1 ,message2,showRefreshBtn,action})=>{
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return(
    <Page
    title="Transaction"
    sx={{ marginTop: { xs: "128px", md: "62px", overflow: "hidden" } }}
  >
    <Container
      sx={{
      margin: "auto",
      }}
      style={{
        width: '100%',
        padding: isSmallScreen ? theme.spacing(2) : theme.spacing(14),
      }}
      maxWidth="lg"
    >
      <StyledCard backgroundColor="#FDEDED">
        <Icon icon={AlertFill} color="#f31111" fontSize={isSmallScreen ? theme.spacing(3.5) : theme.spacing(7)} />
        <StyledTypography backgroundColor="#FDEDED" fontSize={isSmallScreen ? theme.spacing(2) : theme.spacing(4)} fontWeight="800" color="red"> {message1} — <strong>{message2}!!</strong> </StyledTypography>
      </StyledCard>
      {showRefreshBtn && action && <IconButton onClick={() => action()}
                  disableFocusRipple={true}
                  disableRipple={true}
                    sx={{ marginRight: isSmallScreen ? 0.25 : 1, marginTop:theme.spacing(1)}}>
                    <img src={RefreshIcon} style={{  minWidth: isSmallScreen ? theme.spacing(7) : theme.spacing(10), height: isSmallScreen ? theme.spacing(7) : theme.spacing(10)}} alt ="refresh" />
                  </IconButton>}
    </Container>
  </Page>
  )
}

//------------------------------------------------------------------------------------------

//------------------------------------------DATE TIME RELATED HELPER FUNCTIONS------------------------------------------------

export const onlyTime = (date) => {
  // const datetimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6}$/;
  // return !is.empty(date) && datetimeRegex.test(date) && isValid(new Date(date))
  return !is.empty(date) &&  isValid(new Date(date))
    ? format(new Date(date), " hh:mm:ss a")
    : "";
};

export const currentDate = () => {
  return format(new Date(), "dd MMM yyyy hh:mm:ss a");
};


export const formattedTime = (end, start) => {
  const differenceInSecs = differenceInSeconds(end, start);

  const duration = intervalToDuration({ start: 0, end: differenceInSecs * 1000 });
  const formattedDuration = formatDuration(duration, {
    format: ["minutes", "seconds"],
  });
  const updatedFormattedDuration = formattedDuration.replace("minutes", "mins");
  const finalFormattedDuration = updatedFormattedDuration.replace("seconds", "secs");
  return finalFormattedDuration;
};

//------------------------------------------------------------------------------------------


//------------------------------------------------API CALL FUNCTION---------------------------------------------

const fetchTransactionRelatedDetails = async (endPoint, activitytoken) => {
  const response = await axios.get(chargerTransactionActivityUrl(endPoint), {
    headers: {
      authorization:`Bearer ${activitytoken}`
    }
  })
  return response.data;
}

const stopTransactionRequest = async (endPoint,activitytoken)=>{
  const response = await axios.post(chargerTransactionActivityUrl(endPoint),{},{
    headers: {
      authorization:`Bearer ${activitytoken}`
    }
  })
  return response.data;

}

const ChargerTransactionStats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallerScreen = useMediaQuery('(max-width: 280px) and (max-height: 653px)');

  // Retrieve specific query parameters
  const token = queryParams.get('token');

  //  STATES---------------------------------------------------------------
  const [isInvalidToken,setIsInvalidToken] = useState(false);
  const [firstTimeFetchingDone, setFirstTimeFetchingDone] = useState(false)
  const [refetchIntervalVal, setRefetchIntervalVal] = useState(false);
  const [graphData, setGraphData] = useState([
    {
      label: "Power.Active.Import",
      measurand: "Power.Active.Import",
      xaxis: [],
      yaxis: [],
      unit: "Kw"
    }
  ]);
  const [statisticCards, setStatisticsCards] = useState([
    { label: "Charging Duration", value: "", title: "chargingDuration", prefix: "", suffix: "" },
    { label: "Energy Consumed", value: "", title: "energyConsumed", prefix: "", suffix: "kWh" },
    { label: "Start Meter Reading", value: "", title: "start_meter_value", prefix: "", suffix: "" },
    { label: "Amount Paid", value: "", title: "totalAmountPaid", prefix: "", suffix: "" },
  ])
  const [transactionDetails, setTransactionDetails] = useState({});
  const [connectorStat, setConnectorStat] = useState({});
  const [stopRefetching, setStopRefetching] = useState(false);
  const [retryCounter, setRetryCounter] = useState(0);
  const currentDateTime = currentDate();
  const [messageOption, setMessageOption] = useState({
    open: false,
    message: '',
    variant: 'success'
  });
  const [showChargingFailedError, setShowCharingFailedError] = useState(false);
  const [isTransactionCompleted, setIsTransactionCompleted] = useState(false);
  const [clickedStop, setClickedStop] = useState(false);
  const [chargerLogo, setChargerLogo] = useState();

  //---------------------------------------------------------------------------------------------------

  //-------------------------------------METHODS----------------------------------------------------------------------------

  const handleReload = () => {
    window.location.reload();
  };

  const onFetchingTransactionDetails = (data) => {
    const { measurandValues, transactions } = data;
    if (!is.empty(transactions) && !is.empty(transactions[0])) {
      const { starttime, stoptime, start_meter_value, current_meter_value, currency,energyUnit } = transactions[0]
      if (!is.nullOrUndefined(stoptime)) setStopRefetching(true);
      const startTime = new Date(starttime);
      const stopTime = (()=>{
        if(!is.nullOrUndefined(stoptime)) return new Date(stoptime);
        // const offset = new Date(starttime).getTimezoneOffset();
        // const updatedCurrent =current.getTime() + offset * 60 * 1000;
        const current = new Date().toISOString();
        return new Date(current)
      })();
      const energyUnitLowerCase = String(energyUnit).toLowerCase()
      const chargingDuration = formattedTime(stopTime, startTime)
      const startMeterVal = !is.nullOrUndefined(start_meter_value) ? Number(start_meter_value) : 0;
      const currentMeterVal = !is.nullOrUndefined(current_meter_value) ? Number(current_meter_value) : 0;
      const currentEnergyConsumption = currentMeterVal > startMeterVal  ?  currentMeterVal - startMeterVal : 0;

          let energyConsumedToShow=0;
          let energyUnitToShow="";
      if(energyUnitLowerCase==="wh"){
        const energyInKWh =  (Number(currentEnergyConsumption) / 1000).toFixed(2);
        if(energyInKWh<0.5){
          energyConsumedToShow = Number(currentEnergyConsumption);
          energyUnitToShow = "Wh";

        }else{
          energyConsumedToShow = energyInKWh;
          energyUnitToShow = "kWh";
        }
      }else{
        const energyInKWh =  Number(currentEnergyConsumption)
        if(energyInKWh<0.5){
          energyConsumedToShow = energyInKWh *1000;
          energyUnitToShow = "Wh";

        }else{
          energyConsumedToShow = energyInKWh;
          energyUnitToShow = "kWh";
        }
      }
      const finalTransactionDetails = { ...transactions[0], chargingDuration, energyConsumed:energyConsumedToShow,suffix:energyUnitToShow }

      const updatedStatisticCards = statisticCards.map(({ title, value, label, prefix, suffix }) => ({ title, label, value: finalTransactionDetails[title], prefix:title==="totalAmountPaid"?CURRENCY_ENUM[currency]:prefix, suffix:title==="energyConsumed"?finalTransactionDetails["suffix"]:suffix }));
      setTransactionDetails(finalTransactionDetails);
      setStatisticsCards(updatedStatisticCards);

      if (!is.nullOrUndefined(stoptime)) {
        setIsTransactionCompleted(true);
        setConnectorStat((connectorStat) => ({ ...connectorStat, status: "Completed" }))
        setMessageOption({
          ...messageOption,
          open: true,
          message: "Transaction Succesfully Completed",
          variant: "success"
        })
      }
    }
    if (!is.empty(data) && !is.empty(measurandValues)) {
      const updatedData = graphData.map(({ measurand, xaxis, yaxis, label, unit }) => {
        let updatedXaxis = [];
        let updatedYaxis = [];
        let newUnit ;
        const newValues = measurandValues.filter((measurandValue) => measurandValue.measurand === measurand);
        if (newValues.length) {
          const fetchedXaxis = newValues.map(({ value_timestamp }) => new Date(value_timestamp).getTime());
          const fetchedYaxis = newValues.map(({ value }) => !is.nullOrUndefined(value) ? Number(value).toFixed(2) : 0.00);
          updatedXaxis = [...updatedXaxis, ...fetchedXaxis];
          updatedYaxis = [...updatedYaxis, ...fetchedYaxis];
          newUnit = newValues[0].unit
        }
        return { measurand, xaxis: updatedXaxis, yaxis: updatedYaxis, label, unit:newUnit }
      })
      setGraphData(updatedData)

    };
  }


  const { data: connectorData, isLoading: isLoadingConnectorStatus,refetch:refetchConnectorStatus } = useQuery(["connector"], () => fetchTransactionRelatedDetails("connector", token), {
    enabled: !is.empty(token),
    refetchInterval: refetchIntervalVal,
    onSuccess: async (data) => {
      if (is.empty(data)) {
        return
      }
      const { connectorStatus } = data;
      if (is.empty(connectorStatus) && is.empty(connectorStatus[0])) {
        return
      }
      const connStatusDetails = connectorStatus[0];
      const provider = connStatusDetails.provider;
      if (provider === "MoveonEV") {
        setChargerLogo(MoveonEVLogo);
      } else if (provider === "ChargeMiles") {
        setChargerLogo(ChargemilesLogo);
      } else if (provider === "Techvolt Network") {
        setChargerLogo(TechvoltLogo);
      } else {
        setChargerLogo(AmikLogo);
      }
      const messageToShow = STATUS_ARR.find(({ status }) => status.toLowerCase() === connStatusDetails.status.toLowerCase())
      let updatedMessageOp
      if (messageToShow) {
        updatedMessageOp = { ...messageToShow, open: true };
        setMessageOption(updatedMessageOp)
      }
      setConnectorStat({ ...connectorStatus[0] })
      if (updatedMessageOp.status !== "Charging") {
        if (retryCounter <= 1) setRetryCounter(oldC => oldC + 1);
        else {
          setRefetchIntervalVal(false)
          setShowCharingFailedError(true)
          return
        };
        setRefetchIntervalVal(20000)
        return
      };
      try {
        setFirstTimeFetchingDone(true)
        setRetryCounter(0)
        setRefetchIntervalVal(false)

      } catch (error) {
        console.error(error)
      }
    },
    onError: (error) => {
      console.log(error)
      const {response} = error;
      if(!is.empty(response) && !is.empty(response.data) && !response?.data?.auth && (response?.data?.message==="Failed to authenticate token." ||  response?.data?.message==="Invalid Request")){
       setIsInvalidToken(true);
       return;
      }
      if (retryCounter <= 20) setRetryCounter(oldC => oldC + 1);
      else {
        setRefetchIntervalVal(false)
        setShowCharingFailedError(true)
        return
      };
      setRefetchIntervalVal(20000)
      return
    }
  });

  const { isLoading: isFetchingTransactionDetails, isStale, refetch, isRefetching } = useQuery(["activityDetails"], () => fetchTransactionRelatedDetails("activitydetails", token), {
    enabled: firstTimeFetchingDone && !isTransactionCompleted,
    staleTime: 20000,
    onSuccess: onFetchingTransactionDetails,
    onError: (err) => {
      setMessageOption({
        ...messageOption,
        open: true,
        message: err.message,
        variant: "error"
      })
    }
  })

  const  { mutate } = useMutation(()=> stopTransactionRequest("stoptransaction",token),{
    onSuccess:()=>refetch(),
    onError:(error)=>{
      setMessageOption({
        ...messageOption,
        open: true,
        message: error?.response?.data?.message ?? error?.message,
        variant: "error"
      })
     
    },
    onSettled:()=> setClickedStop(false)
  })

  useEffect(()=>{
    if(clickedStop){
      mutate()
    }

  },[clickedStop])

  useEffect(() => {
    if (isStale && firstTimeFetchingDone && !stopRefetching) {
      refetch()
    }
  }, [isStale, firstTimeFetchingDone, stopRefetching, refetch]);

  useEffect(()=>{
    let timer;
   if(isTransactionCompleted){
     timer = setTimeout(() => {
       navigate(`/invoice?token=${token}`);
    }, 10000);


    return ()=>{
      clearTimeout(timer)
    }
   }
  },[isTransactionCompleted,token,navigate])
 
  // console.log(token)
  // console.log(graphData)
  // console.log(statisticCards)
  
  if (is.empty(token) || (isInvalidToken)) {
    return <ErrorComponent message1={"Invalid Request"} message2={"Please try again with valid information"}/>
  };

  if (isLoadingConnectorStatus) {
    return <div style={{ margin: 300 }}><CircularProgress
      color="success"
      size={40}
      thickness={4}
      variant="indeterminate" /> </div>
  };
  
  if(showChargingFailedError) {
    return(
      <Stack>

        <ErrorComponent message1={"Charging status rejected by charger"} message2={"Please try again by clicking refresh"} showRefreshBtn={true} action={handleReload} />
        
      </Stack>
      )
  };

  return (
    <Page
      title="Transaction"
      sx={{ marginTop: { xs: "128px", md: "62px", overflow: "hidden" } }}
    >
      <Container
        sx={{
          margin: "auto",
        }}
        style={{
          width: '100%',
          padding: isSmallScreen ? theme.spacing(0) : theme.spacing(14),
        }}
        maxWidth="lg"
      >
        {!showChargingFailedError && <Paper elevation={4} sx={{ padding: 3 }} >
          <Stack spacing={3}>
            <Stack>
              <Stack direction="row" justifyContent="center">
                <img src={chargerLogo} alt="chargemilesLogo" height={isSmallScreen ? theme.spacing(2.5) : theme.spacing(7)} width={isSmallScreen ? theme.spacing(3) : theme.spacing(8)} style={{ marginTop: 2 }} />
                <div>
                  <StyledTypography fontSize={isSmallScreen ? theme.spacing(2) : theme.spacing(4)} fontWeight="800" ml={0.5} >{connectorStat.chargepoint_name}</StyledTypography>
                  <StyledTypography color="grey" fontSize={isSmallScreen ? theme.spacing(1) : theme.spacing(2)}>
                    {connectorStat.addressStreet}
                  </StyledTypography>
                </div>
              </Stack>
              <Grid container justifyContent={!isTransactionCompleted ? "end" : "center"} alignItems="center" marginTop={4}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <StyledTypography fontWeight="600" fontSize={isSmallScreen ? theme.spacing(2) : theme.spacing(3)} > Status : <span style={{ color: messageOption.variant !== "error" ? APP_GREEN : theme.palette.error.main }}>{connectorStat.status}</span></StyledTypography>
                </Grid>
                {!isTransactionCompleted && <Grid item xs={12} sm={12} lg={6} md={6}>
                  <IconButton onClick={() => refetch()}
                  disableFocusRipple={true}
                  disableRipple={true}
                    sx={{ marginRight: isSmallScreen ? 0.50 : 1}}>
                    <img src={RefreshIcon} style={{  minWidth: isSmallScreen ? theme.spacing(6) : theme.spacing(6), height: isSmallScreen ? theme.spacing(6) : theme.spacing(6)}} alt ="refresh" />
                  </IconButton>
                  <IconButton onClick={() => mutate()}
                  disableFocusRipple={true}
                  disableRipple={true}
                  disabled={clickedStop}>
                    <img src={StopIcon} style={{  minWidth: isSmallScreen ? theme.spacing(6) : theme.spacing(6), height: isSmallScreen ? theme.spacing(6) : theme.spacing(6)}} alt ="stop" />
                  </IconButton>
                </Grid>}
              </Grid>
            </Stack>
            {
              <>
                <Paper elevation={2}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" p={isSmallScreen ? 1: 3}>
                    <StyledTypography fontSize={isSmallScreen ? theme.spacing(1.3) : theme.spacing(3.3)}>{currentDateTime}</StyledTypography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} ml={0.5}>
                      <Icon icon={ChargingFill} color={APP_GREEN} width={isSmallScreen ? 23:30} height={isSmallScreen ? 23:30} />
                      <StyledTypography color={APP_GREEN} fontSize={isSmallScreen ? theme.spacing(1.4) : theme.spacing(2.2)}> {!is.nullOrUndefined(transactionDetails.batteryPercent) ?`${transactionDetails.batteryPercent}%`: "N/A"} </StyledTypography>
                    </Stack>
                  </Stack>


                  {
                    // !is.empty(graphData[0]["xaxis"]) && !is.empty(graphData[0]["yaxis"]) && 
                    graphData.map(({ label, xaxis, yaxis, unit, measurand }, ind) => {
                      let series = [
                        {
                          name: measurand,
                          data: yaxis,
                        },
                      ];
                      let options = {
                        chart: {
                          height: 200,
                          width: 300,
                          type: "area",
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false
                          }
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        stroke: {
                          curve: "smooth",
                          width: 2,
                          lineCap: "butt",
                          colors: [APP_GREEN]
                        },
                        title: {
                          text: "",
                          align: "left",
                        },
                        fill: {
                          colors: [APP_GREEN],
                        },
                        grid: {
                          row: {
                            colors: ["#C8FACD"],
                            opacity: 0.4,
                          },
                        },
                        xaxis: {
                          categories: xaxis,
                          type: "datetime",
                          timezone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          labels: {
                            formatter: (value) =>onlyTime(value),
                            rotate: 0,
                            maxWidth: 10,
                          },
                          title: {
                            text: "Time",
                            style: {
                              fontSize: isSmallerScreen ? theme.spacing(1) : theme.spacing(1.7),
                              fontWeight: "600",
                              color: "grey",
                              marginTop: "2px",
                            },
                          },
                        },
                        yaxis: {
                          title: {
                            text: `${unit}`,
                            style: {
                              fontSize: isSmallerScreen ? theme.spacing(1) : theme.spacing(1.7),
                              fontWeight: "600",
                              color: "grey",
                            },
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 768,
                            options: {
                              chart: {
                                width: '100%',
                                height: '250px',
                              },
                            },
                          },
                        ],
                      };

                      return (

                        <BaseChart
                          options={options}
                          series={series}
                          type="area"
                          key={`area-chart-${ind}-${measurand}`}
                        />

                      );
                    })
                  }
                </Paper>
                <Grid container rowGap={1}>
                  {
                    !statisticCards.find((card) => (card.value === "")) && statisticCards.map(({ label, value, prefix, suffix }, index) => (
                      <Grid item md={6} lg={6} xs={6} key={`${label}-${index}-${label}`} p={1}>
                        <StyledCard sx={{width:isSmallerScreen ? "92px" :null,height:isSmallerScreen ? "83px" :null}} >
                          <StyledTypography fontSize={isSmallScreen ? theme.spacing(1.8) : theme.spacing(2.2)}>
                            {label}
                          </StyledTypography>
                          <StyledTypography color={APP_GREEN} fontSize={isSmallScreen ? theme.spacing(1.8) : theme.spacing(3)} >
                            {prefix} {value} {suffix}
                          </StyledTypography>
                        </StyledCard>
                      </Grid>
                    ))
                  }
                </Grid>
              </>
            }
          </Stack>
        </Paper>
        }     
      </Container>
      <MuiSnackBar
        open={messageOption.open}
        severity={messageOption.variant}
        message={messageOption.message}
        onCloseFunc={() => setMessageOption((messageOp) => ({ ...messageOp, open: false }))}
      />
    </Page>
  )
}

export default ChargerTransactionStats
