import * as Yup from "yup";
import { Alert, Box, Divider, Slide, TextField } from "@mui/material";
import { paymentStyles } from "./payment.constants";
import React, { useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { queryKeys } from "../../../react-query/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { fetchWithError } from "../../../react-query/fetchWithError";
import { is } from "../../../util/is";
import styled from "@emotion/styled";
import { ReactComponent as EmployeeIcon } from "../../../image/chargerStationIcons/employeeIcon.svg";
import { ReactComponent as PayChargeIcon } from "../../../image/chargerStationIcons/payChargeIcon.svg";
import { useDomainConfig } from "../../../hooks/useDomainConfig";
import ReCAPTCHA from "react-google-recaptcha";
import useRecaptcha from "../../../hooks/useRecaptcha";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  [theme.breakpoints.between("xs", "sm")]: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  background: "#fff",
  boxShadow: "0px 0px 4px 0px #00000040",
  color: "var(--app-color)",
  whiteSpace: "nowrap",
  textTransform: "none",
  justifyContent: "space-between",
}));

const validateEmail = (email) => {
  return Yup.string().email().isValidSync(email);
};

const USERTYPE = {
  GUEST: "GUEST",
  RESIDENT: "RESIDENT",
};

const validatePhone = (phone) => {
  return Yup.string()
    .test((phone) => {
      return /^\d{10}$/.test(phone);
    })
    .isValidSync(phone);
};

const emailSchema = Yup.object().shape({
  emailOrPhone: Yup.string()
    .required("Please  enter  either email id  or  phone number")
    .test("email_or_phone", "Email / Phone is invalid", (value) => {
      return validateEmail(value) || validatePhone(value);
    }),
});

const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .required()
    .matches(/^\d{6}$/, "OTP must be 6 digits number"),
});

export const EmailComponent = ({
  chargerId,
  connector,
  email,
  setEmail,
  // customerName,
  // setCustomerName,
  setGuest,
  setPaymentStatus,
  payWithCard,
  employeeOrResident,
}) => {
  const [validationError, setValidationError] = useState("");
  const [otpValidationError, setOtpValidationError] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [fetchOtp, setFetchOtp] = useState(false);
  const [enableOTP, setEnableOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const domainConfig = useDomainConfig();
  const [isFreeConnector, setIsFreeConnector] = useState(
    connector && connector.connector_id
      ? () => {
          const {
            fixedFeeCharging,
            fixedFeeStarting,
            priceperKW,
            priceperMin,
          } = connector;
          return (
            Number(fixedFeeCharging) <= 0 &&
            Number(fixedFeeStarting) <= 0 &&
            Number(priceperKW) <= 0 &&
            Number(priceperMin) <= 0
          );
        }
      : false
  );
  const [requestFreeChargerStart, setRequestFreeChargerStart] = useState(false);
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const recaptchaClientKey = domainConfig.recaptcha_client;
  const { isFetching, isError, error, remove } = useQuery(
    queryKeys.sendOtp({
      email: validateEmail(email) ? email : "",
      phone: validatePhone(email) ? email : "",
      domain: domainConfig.domain,
      countryCode: domainConfig.countryCode,
      // customerName: customerName ? customerName : "",
      chargerId,
      connectorId: !is.empty(connector?.ocpi_connector_id)
        ? connector?.ocpi_connector_id
        : connector?.connector_id,
      origin: "web",
      capchaToken,
    }),
    fetchWithError,
    {
      enabled: enabled,
      onSuccess: () => {
        setEnableOtp(true);
        setEnabled(false);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      },
      onError: (e) => {
        setEnabled(false);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        // Check if the reCAPTCHA validation failed on the server-side
        if (e?.data?.message === "Recaptcha Failed") {
          alert("ReCAPTCHA validation failed. Please try again.");
        }
      },
    }
  );

  const {
    isFetching: isOTPLoading,
    isError: isOTPError,
    error: otpError,
    remove: removeOtpQuery,
  } = useQuery(
    queryKeys.verifyOtp({
      email: validateEmail(email) ? email : "",
      phone: validatePhone(email) ? email : "",
      otp,
      action: "RemoteStart",
      domain: domainConfig.domain,
    }),
    fetchWithError,
    {
      enabled: fetchOtp,
      onSuccess: () => {
        setPaymentStatus({
          isDone: true,
          status: true,
          message: "Please wait, charger will start in a while.",
        });
      },
      onError: (e) => {
        if (e?.response?.status !== 401) {
          setPaymentStatus({
            isDone: true,
            status: false,
            message: "Unable to start the charger. Please try again.",
          });
        } else {
          setFetchOtp(false);
        }
      },
    }
  );
  const {
    isFetching: isStartingFreeCharger,
    isError: isStartingFreeChargerError,
    error: startingFreeChargerError,
    remove: removeStartingFreeChargerQuery,
  } = useQuery(
    queryKeys.startFreeCharger(
      email,
      chargerId,
      connector?.connector_id,
      "free",
      "RemoteStart",
      domainConfig.domain
    ),
    fetchWithError,
    {
      enabled: requestFreeChargerStart,
      onSuccess: () => {
        setPaymentStatus({
          isDone: true,
          status: true,
          message: "Please wait, charger will start in a while.",
        });
        setRequestFreeChargerStart(false);
      },
      onError: (error) => {
        if (!error?.response?.data?.success) {
          setPaymentStatus({
            isDone: true,
            status: false,
            message:
              error?.response?.status === 400
                ? `Unable to start the charger. ${error?.response?.data?.message} `
                : `Unable to start the charger. Please try again.`,
          });
        }
        setRequestFreeChargerStart(false);
      },
    }
  );

  useEffect(() => {
    if (!is.empty(connector) && connector.connector_id) {
      const { fixedFeeCharging, fixedFeeStarting, priceperKW, priceperMin } =
        connector;
      const isFree =
        Number(fixedFeeCharging) <= 0 &&
        Number(fixedFeeStarting) <= 0 &&
        Number(priceperKW) <= 0 &&
        Number(priceperMin) <= 0;
      setIsFreeConnector(isFree);
    }
  }, [connector]);

  const onChange = (e) => {
    setEmail(e.target.value);
    isError && remove();
  };

  // const onChangeCustomerName = (e) =>{
  //   setCustomerName(e.target.value)
  // };

  const onOTPChange = (e) => {
    setOtp(e.target.value);
    otpError && removeOtpQuery();
  };
  const onClick = (type) => {
    emailSchema
      .validate({ emailOrPhone: email })
      .then(() => {
        setValidationError("");
        USERTYPE.RESIDENT === type ? setEnabled(true) : setGuest(true);
      })
      .catch((e) => {
        setValidationError(e.errors?.[0]);
      });
  };

  const onVerifyOtpClick = () => {
    otpSchema
      .validate({ otp })
      .then(() => {
        setOtpValidationError("");
        setFetchOtp(true);
      })
      .catch((e) => setOtpValidationError(e.errors?.[0]));
  };
  return (
    <>
      {!!connector && !isFreeConnector && (
        <Slide direction="up" in={!!connector && !isFreeConnector}>
          <Box className="tabs-parent" sx={paymentStyles.tabParent}>
            {(isError || isOTPError) && (
              <Alert
                variant="outlined"
                severity="error"
                sx={{ marginBottom: "20px", color: "red" }}
              >
                {(error?.response?.data?.message ?? error?.message) ||
                  (otpError?.response?.data?.message ?? otpError?.message)}
              </Alert>
            )}
            {/* <TextField
          // required
          label="Customer Name"
          value={customerName}
          // error={customerNameReqError}
          // helperText= {customerNameReqError ? "Please enter name" : ""}
          onChange= {onChangeCustomerName}
          // disabled={enableOTP}
          sx={{ backgroundColor: "white", marginBottom: "10px" }}
        /> */}
            <TextField
              required
              label="Enter email or phone number"
              value={email}
              error={!!validationError}
              helperText={validationError}
              onChange={onChange}
              disabled={enableOTP ? true : false}
              sx={{ backgroundColor: "white" }}
            />
            {!enableOTP && (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchaClientKey}
                onChange={handleRecaptcha}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
            {enableOTP && (
              <>
                <Divider sx={{ margin: "20px 0" }} />
                <TextField
                  required
                  label="Enter OTP"
                  value={otp}
                  error={!!otpValidationError}
                  helperText={otpValidationError}
                  onChange={onOTPChange}
                  sx={{ backgroundColor: "white" }}
                />
              </>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "20px",
              }}
            >
              {!enableOTP && (!isError || error?.response?.status !== 404) && (
                <StyledContainer>
                  {payWithCard && (
                    <StyledLoadingButton
                      startIcon={<PayChargeIcon sx={{ marginRight: "none" }} />}
                      variant="contained"
                      type="submit"
                      onClick={() => onClick(USERTYPE.GUEST)}
                      disabled={isFetching || isOTPLoading ? true : false}
                    >
                      Pay & Charge
                    </StyledLoadingButton>
                  )}
                  {employeeOrResident && (
                    <StyledLoadingButton
                      startIcon={<EmployeeIcon sx={{ marginRight: "none" }} />}
                      variant="contained"
                      type="submit"
                      onClick={() => onClick(USERTYPE.RESIDENT)}
                      disabled={
                        (isFetching || isOTPLoading ? true : false) ||
                        !capchaToken
                      }
                    >
                      Employee / Resident
                    </StyledLoadingButton>
                  )}
                </StyledContainer>
              )}
              {error?.response?.status === 404 && (
                <StyledLoadingButton
                  startIcon={<PayChargeIcon sx={{ marginRight: "none" }} />}
                  variant="contained"
                  type="button"
                  onClick={() => setGuest(true)}
                  disabled={isFetching || isOTPLoading ? true : false}
                >
                  Pay & Charge
                </StyledLoadingButton>
              )}
              {enableOTP && (
                <LoadingButton
                  variant="contained"
                  type="button"
                  onClick={onVerifyOtpClick}
                  disabled={isFetching || isOTPLoading ? true : false}
                >
                  Verify OTP
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Slide>
      )}
      <Slide direction="up" in={!!connector && isFreeConnector}>
        <Box className="tabs-parent" sx={paymentStyles.tabParent}>
          {isStartingFreeChargerError && (
            <Alert
              variant="outlined"
              severity="error"
              sx={{ marginBottom: "20px", color: "red" }}
            >
              {startingFreeChargerError?.response?.data?.message ??
                startingFreeChargerError?.message}
            </Alert>
          )}
          {/* <TextField
          // required
          label="Customer Name"
          // value={customerName}
          // error={customerNameReqError}
          // helperText= {customerNameReqError ? "Please enter name" : ""}
          onChange={onChangeCustomerName}
          // disabled={enableOTP}
          sx={{ backgroundColor: "white", marginBottom: "10px" }}
        /> */}
          <TextField
            required
            label="Enter email or phone number"
            value={email}
            error={!!validationError}
            helperText={validationError}
            onChange={onChange}
            sx={{ backgroundColor: "white" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "20px",
            }}
          >
            <LoadingButton
              variant="contained"
              type="button"
              onClick={() => setRequestFreeChargerStart(true)}
              disabled={isStartingFreeCharger ? true : false}
            >
              Start Charging
            </LoadingButton>
          </Box>
        </Box>
      </Slide>
    </>
  );
};
