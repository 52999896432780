// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../Page';
import ChangePasswordForm from '../authentication/changePassword/ChangePasswordForm'

export default function ChangePassword() {
  return (
    <Page title="Dashboard | ChargeMiles-UI">
      <Container maxWidth="xl" sx={{ mt: 10}}>
        <ChangePasswordForm />
      </Container>
    </Page>
  );
}
