import { useEffect } from 'react';
import { MuiDialog } from '../@material-extend';
import { Box, Typography, Paper, TextField, Button } from '@mui/material';
import Constant from '../../util/constants';

// User defined functions and components
import { StyledImg } from '../common/Styled';
import { getGoogleMapUrl, getFBShareUrl } from '../../../src/util/Display';

// Dialog Images
import FacebookIcon from '../../image/social-icons/facebook-logo.svg';
import TwitterIcon from '../../image/social-icons/twitter-logo.svg';
import EmailIcon from '../../image/social-icons/gmail.png';
import DefaultLocationImg from '../../image/default_geocode-2x.png'

const { label, action } = Constant;

const styles  = {
    parentImgBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItem: 'center',
        boxSizing: 'border-box',
    },
    titleParentBox: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        float: 'none',
        flexDirection: 'column',
        paddingLeft: '16px',
        lineHeight: '16px',
        fontSize: '13px',
        minWidth: 0
    },
    parentLinkBox: {
        padding: '16px 0',
        height: 100,
        '& div:first-child': {
            display: 'flex',
            '& .MuiTextField-root': {
                flex: 1
            }
        }
    },
    imgBox: {
        width: 64,
        height: 64,
        marginLeft: 0
    },
    copyLinkBtn: {
        ml: 4
    },
    social: {
        display: 'flex',
        padding: '24px 0',
        justifyContent: 'space-evenly',
        '& .social-icon': {
            width: 64
        },
        '& > button': {
            flexDirection: 'column',
            gap: '4px'
        }
    }
}

export default function ShareDialog({ location, map, ...props}) {

    const handleClick = (type) => async () => {
        switch(type) {
            case action.ON_COPY_CLICK:
                const linkText = document.getElementById('link');
                if(linkText) {
                    linkText.select();
                    const selection = document.getSelection();
                    const selectionInString = selection.toString();
                    if('clipboard' in navigator) {
                        await navigator.clipboard.writeText(selectionInString);
                    }
                }
                break;
            case action.ON_FB_SHARE_CLICK:
                window.fbShareWindowRef = window.open(getFBShareUrl(getGoogleMapUrl(location.address)), "", "width=500,height=500")
                break;
        }
    }

    return (
        <MuiDialog
        maxWidth='xs'
        fullWidth={!0}
        actions={false} 
        title={label.SHARE}
        className='cm-share-dialog'
        {...props}
        >
            <Box className="cm-share-dialog--inner">
                <Typography variant="subtitle1" fontSize="13px" fontWeight={500} color="#000" mb={2}>{label.SEND_LINK}</Typography>
                <Paper component="div" sx={styles.parentImgBox} elevation={0}>
                    <Box aria-hidden="true" sx={styles.imgBox}>
                        <StyledImg src={DefaultLocationImg} width={64} height={64} />
                    </Box>
                    <Box sx={styles.titleParentBox}>
                        <Typography variant="body2" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" color="#000">{location.name}</Typography>
                        {/* <Typography variant="body2" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" color="#70757a">Ballupur, Dehradun, Uttarakhand</Typography> */}
                    </Box>
                </Paper>
                <Paper className="relative" component="div" sx={styles.parentLinkBox} elevation={0}>
                    <Box>
                        <TextField 
                        id="link" 
                        label={label.LINK_TO_SHARE} 
                        defaultValue={getGoogleMapUrl(location.address)} 
                        inputProps={{ readOnly: !0, shrink: !0 }} 
                        variant="standard"
                        />
                        <Button variant="text" id="copy-text" onClick={handleClick(action.ON_COPY_CLICK)} sx={styles.copyLinkBtn}>Copy Link</Button>
                    </Box>
                </Paper>
            </Box>
            <Box className="cm-share-dialog--social" sx={styles.social}>
                <Box className='fb-share-button' data-href={getGoogleMapUrl(location.address)} data-layout="button" data-size="small">
                    <Button variant="text" className='flex-col' onClick={handleClick(action.ON_FB_SHARE_CLICK)}>
                        <Box aria-hidden="true" className="social-icon">
                            <StyledImg src={FacebookIcon} width={40} height={40} />
                        </Box>
                        <Typography textTransform="capitalize" variant="subtitle2" fontSize={13} color="#70757a" fontWeight={400}>Facebook</Typography>
                    </Button>
                </Box>
                <Button variant="text">
                    <Box aria-hidden="true" className="social-icon">
                        <StyledImg src={TwitterIcon} width={40} height={40} />
                    </Box>
                    <Typography textTransform="capitalize" variant="subtitle2" fontSize={13} color="#70757a" fontWeight={400}>Twitter</Typography>
                </Button>
                <Button variant="text">
                    <Box aria-hidden="true" className="social-icon">
                        <StyledImg src={EmailIcon} width={40} height={40} />
                    </Box>
                    <Typography textTransform="capitalize" variant="subtitle2" fontSize={13} color="#70757a" fontWeight={400}>Email</Typography>
                </Button>
            </Box>
        </MuiDialog>
    )
}