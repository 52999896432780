const URLs = {
    GET_USER: `${process.env.REACT_APP_API_END_POINT}user/detail`,
    ADD_FAVOURITES: `${process.env.REACT_APP_API_END_POINT}user/add-favorite-location`,
    REMOVE_FAVOURITES: `${process.env.REACT_APP_API_END_POINT}user/remove-favorite-location`,
    GET_LOCATION_DETAILS: `${process.env.REACT_APP_API_END_POINT}v2/location/get-detail`,
    REPORT_ISSUE: `${process.env.REACT_APP_API_END_POINT}user/report-issue`,
    GET_REPORTED_ISSUE: `${process.env.REACT_APP_API_END_POINT}user/get-user-issue`,
    ADD_LOCATION_IMAGE: `${process.env.REACT_APP_API_END_POINT}v2/location/add-image`,
    REMOVE_LOCATION_IMAGE: `${process.env.REACT_APP_API_END_POINT}location/remove-image`,
    REACTION: `${process.env.REACT_APP_API_END_POINT}location/location-reaction`,
    GET_REACTIONS: `${process.env.REACT_APP_API_END_POINT}location/get-location-reaction`,
    UPLOAD_IMAGE: `${process.env.REACT_APP_API_END_POINT}util/image-upload`,
    ADD_IMAGE: `${process.env.REACT_APP_API_END_POINT}v2/location/add-image`,
    REMOVE_IMAGE: `${process.env.REACT_APP_API_END_POINT}location/remove-image`,
    CREATE_TRIP: `${process.env.REACT_APP_API_END_POINT}user/add-trip`,
    UPDATE_TRIP: `${process.env.REACT_APP_API_END_POINT}user/update-trip`,
    GET_TRIP: `${process.env.REACT_APP_API_END_POINT}user/get-trip`,
    DELETE_TRIP: `${process.env.REACT_APP_API_END_POINT}user/delete-trip`
}

export default URLs;