import { Box, Grid, Container, Typography, Stack } from "@mui/material";

// components

import React from "react";

import Page from "../Page";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

import PublicIcon from "@mui/icons-material/Public";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CMFooter } from "../common/CMFooter";
import { DomainText } from "../chargeMiles/DomainText";

// ----------------------------------------------------------------------

export default function Terms() {
  const [email, setEmail] = React.useState();

  return (
    <Page title="Dashboard | Terms-UI">
      <Container
        sx={{
          padding: "48px 0",
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "start", display: "block" }}
        >
          <br></br>
        </Typography>

        <Typography
          variant="p"
          alignItems={"flex-start"}
          style={{
            textAlign: "start",

            display: "block",

            marginBottom: 30,

            color: "grey",

            fontSize: 18,
          }}
        >
          <Typography variant="body1">
            {" "}
            Effective Date: 1st November 2022. 
          </Typography>
          <Typography variant="body1">
            By entering into this (“Agreement”) with Charegmiles Ltd., you may
            use the <DomainText /> (Registered trademark of <DomainText /> Pvt.
            Ltd.) Mobile Application and website{" "}
            <a href="https://chargemiles.ca/">https://chargemiles.ca/</a> , the
            electric vehicle charging stations which are on <DomainText />
            ’s network, and purchase any other related products or services
            offered by <DomainText /> including other services which may be
            introduced in future (collectively called as, “Services” herein). 
            Note: For the sake of convenience, <DomainText /> , the registered
            trademark of <DomainText /> Ltd will be used throughout this
            agreement for the sake of simplicity. 
          </Typography>
          <Typography variant="body1">
            Please read this <DomainText /> Account Agreement carefully. For
            accessing or using the Services, you agree to be bound by the terms
            and conditions of this Agreement.   Note: If you do not agree to all
            the terms and conditions of this agreement, then you may not access
            or use our Service. 
          </Typography>
          <Typography variant="body1">
            If you are submitting a <DomainText /> Account Application in
            connection with an offer or program (“Program”) offered by
            <DomainText /> in conjunction with one of its partners, you
            acknowledge and agree that any additional program-specific terms and
            conditions made available to you shall apply to your participation
            in such Program (“Program Terms”). To the extent there is a conflict
            between this Agreement and any Program Terms, the Program Terms
            shall control as it relates to your participation in the Program. 
          </Typography>
          <Typography variant="body1">
            In case you are an employee/resident of any organisation/housing
            apartment society to which we have extended our Services, your
            profile will be common for availing the Services. All information
            shown to you as a private user including your profile information,
            charger related information and payment mechanism information will
            be controlled by the corporate/society admin user of your
            organisation/housing apartment society, and <DomainText /> shall not
            be responsible for any information shown on the <DomainText />{" "}
            Mobile App. 
          </Typography>
          <Typography variant="body1">
            By entering into this Agreement, you agree to pay all the fees
            (“Session Fees”) for access to a charging session initiated by you,
            remotely by a customer service agent at your request or using an
            application embedded in any smart phone or similar technology. 
          </Typography>
          <Typography variant="body1">
            You further agree to use your <DomainText /> Mobile App solely in
            accordance with instructions provided to you by <DomainText /> and
            in compliance of all applicable laws and regulations, as well as any
            policies and rules of the owner of the <DomainText /> Enabled
            Charging Station and/or the owner of the property on which the{" "}
            <DomainText />
            Enabled Charging Station is located. 
          </Typography>
          <Typography variant="body1">
            You shall promptly update online any changes to your name, email
            address, mailing address, PIN Code, GSTIN details, telephone number
            and payment gateway related information when that new information is
            first known. You remain liable for all Session Fees billed to your
            account until you have notified <DomainText /> Customer Service of
            any changes in your account until you have cancelled your account. 
          </Typography>
          <Typography variant="body1">
            If you wish to submit a complaint regarding a particular transaction
            charged to your <DomainText /> Account, you shall notify{" "}
            <DomainText />
            within 7 days of the date of the transaction by writing to us at{" "}
            <a href="mailto:info@chargemiles.ca">info@Chargemiles.ca</a>. The
            complaint shall clearly indicate the type of error in the
            transaction. Complaints regarding incorrect charging costs are
            processed and determined by <DomainText />. If and when a complaint
            is accepted, <DomainText /> shall compensate you for the amount
            without delay. If the complaint is rejected, <DomainText /> will
            inform you of the result within 90 days from the receipt of written
            complaint. 
          </Typography>
          <Typography variant="body1">
            <DomainText /> Mobile Application may be used to pay Session Fees on
            any publicly accessible <DomainText /> Enabled Charging Stations,
            and on any private <DomainText /> Enabled Charging Station you are
            allowed to use. Using <DomainText /> Mobile Application for payment
            of Session Fees, a record of your transaction will be created.
            Session Fees will be charged to your account in accordance with the
            price configured as per the actual consumption which may be
            different from the estimated amount informed through the{" "}
            <DomainText /> Mobile App. It is your responsibility to be aware of
            the price charged for access to the applicable <DomainText />{" "}
            Enabled Charging Station. For availing the Services, you shall
            select one of the payment mechanisms offered by <DomainText />. You
            shall be providing appropriate payment information to enable{" "}
            <DomainText /> to receive due amount against Transaction and create
            a valid invoice for the Transaction. You shall be depositing from
            time-to-time prepaid amounts towards your account balance and the
            Session Fees associated with your charging session will be deducted
            from that balance. You authorize
            <DomainText /> to, from time to time, deduct amounts payable towards
            Session Fees from your account. You shall ensure that the registered
            payment mechanism is valid and that it has sufficient balance. In
            case payment cannot be processed and the balance in your account
            cannot be deducted, we reserve the right to close your{" "}
            <DomainText />
            Account. <DomainText /> further has the right to require payment
            directly from you in case of any negative account balance. You may
            opt to use the <DomainText /> Account, to pay for the Services. Any
            money deposited in the <DomainText /> Account will be
            non-refundable, non-transferrable and may only be used to pay for
            the Services. 
          </Typography>
          <Typography variant="body1">
            If a mobile phone with <DomainText /> Mobile Application installed
            is lost or stolen, please report such loss or event online,
            immediately, or, if you do not have access to the internet, inform
            <DomainText /> Service immediately (see contact information at the
            end of this document). You remain liable for all Session Fees
            initiated through the <DomainText /> Mobile Application until such
            notification. 
          </Typography>
          <Typography variant="body1">
            Your <DomainText /> Mobile Application usage is subject to the terms
            of the <DomainText /> Policy.  You are responsible for all use of
            the <DomainText /> Mobile Application/webpage under your user name.
            You agree that, while <DomainText /> has attempted to provide
            accurate information on the <DomainText /> Mobile
            Application/webpage, such information may change frequently and in
            no event will <DomainText />
            be responsible for the accuracy, usefulness or completeness of any
            information, materials or other content on the <DomainText /> Mobile
            Application/webpage, or that any such information, materials, or
            other content is the most up-to date. 
          </Typography>
          <Typography variant="body1">
            <DomainText /> uses all reasonable efforts to ensure that the
            availability of <DomainText /> Enabled Charging Stations can
            accurately be tracked in <DomainText /> Mobile Application/website,
            but cannot guarantee or assure that the charging stations will be
            available for provision of Services at any given point of time, and
            expressly disclaim any warranty of availability of the{" "}
            <DomainText /> Enabled Charging Stations at any point of time as
            displayed in the
            <DomainText /> Mobile Application/website.  Either Party may
            terminate this Agreement at any time without assigning any reason.
            Following any termination, you remain responsible for payments owed
            under this Agreement. If such unpaid charges are not promptly
            remitted, you may become liable for additional service charges,
            fines, or penalties, and you may be subject to collection actions
            for any unpaid balance. 
          </Typography>
          <Typography variant="body1">
            <DomainText /> reserves the right to update, change or replace the
            terms and conditions of service of this Agreement at any time by
            providing either notification on website/app OR written notice to
            you, which, for these purposes, shall include an email sent to the
            email address you have on record with <DomainText /> (Hence, it’s
            your responsibility that your profile information is up to date).
            You agree that all changes become applicable to you when you use
            <DomainText /> Mobile Application after that date. However, it’s
            your responsibility also to check these terms and condition from
            time to time. 
          </Typography>
          <Typography variant="body1">
            YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE <DomainText />
            ENABLED CHARGING STATIONS AND THE <DomainText /> MOBILE APPLICATION
            ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. <DomainText />
            EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESSED OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. 
          </Typography>
          <Typography variant="body1">
            <DomainText /> Mobile Application may provide an option of advance
            booking on some <DomainText /> Enabled Charging Stations (“Booking
            Feature”). For booking any charging station in advance, a boooking
            fee (“Booking Fee”) is payable from your selected payment mechanism.
            The Booking Fee paid by you is non-refundable and non-transferable,
            and can be applied only on the <DomainText /> Enabled Charging
            Station selected at the time of booking and only for the time period
            for which the booking is made (“Booking Slot”). Please note that
            charging at your selected <DomainText /> Enabled Charging Station
            will auto-stop 5 minutes before the end of your Booking Slot, or
            before the end of the last Booking Slot in case you have booked
            multiple consecutive Booking Slots, to allow you time to disconnect
            your electric vehicle and egress from the charging station before
            the next Booking Slot. 
          </Typography>
          <Typography variant="body1">
            <DomainText /> makes all reasonable efforts to ensure that{" "}
            <DomainText />
            Enabled Charging Stations are available for charging during the
            Booking Slot(s), however there may be unforeseen events beyond our
            control which affect availability of <DomainText /> Enabled Charging
            Stations during the Booking Slot. <DomainText /> hence, reserves the
            right to cancel any advance booking at any time before or during the
            Booked Slot without assigning a reason for the same. <DomainText />
            shall not be responsible for any losses incurred by you due to a
            booking being cancelled or for the <DomainText /> Enabled Charging
            Station not being available for charging during the Booked Slot(s)
            due to any reason. If a booking is cancelled by <DomainText /> or
            your selected <DomainText /> Enabled Charging Station is unavailable
            for the Booking Slot or any part thereof, <DomainText /> will refund
            the Booking Fee in full or partially, as may be applicable, upon
            receipt of an application with valid accompanying
            documentary/photographic evidence from you. <DomainText /> will
            respond to any refund request made within 90 days from the date of
            request. 
          </Typography>
          <Typography variant="body1">
            You agree to indemnify, protect, and hold harmless <DomainText />{" "}
            and its directors, officers, employees, agents and distributors, and
            Program Partners and entities on whose premises the <DomainText />
            Enabled Charging Stations are installed, and their respective
            directors, officers, employees and agents (collectively, the
            “Indemnified Parties”) from all liability for any loss, damage or
            claim arising from or related to your misuse or misapplication of
            the Services, damage to the charging station or damage to the
            charging station premises, or otherwise for any breach of the terms
            of this Agreement. 
          </Typography>
          <Typography variant="body1">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT NEITHER <DomainText /> NOR
            ANY PROGRAM PARTNER SHALL BE LIABLE FOR, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO,
            DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
            LOSSES (EVEN IF <DomainText /> HAS BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES), ARISING OUT OF OR IN ANY MANNER CONNECTED WITH THE
            SERVICES. 
          </Typography>
          <Typography variant="body1">
            To the maximum extent allowed by law, you agree to, and hereby do,
            release the Indemnified Parties from all loss, damage, or injury
            whatsoever, known or unknown, whether arising in tort or contract or
            otherwise arising out of or in any manner connected with the
            Services, including the use of the <DomainText /> Enabled Charging
            Stations. You agree that none of the Indemnified Parties will incur
            any obligation or liability for any such loss, damage or injury to
            the maximum extent allowable by law. 
          </Typography>
          <Typography variant="body1">
            This Agreement is to be construed according to the laws of Canada.
            You agree that any dispute over privacy or the terms contained in
            this privacy policy will be governed by the laws of Canada and all
            disputes arising out of or in connection with this Agreement shall
            be finally settled arbitration to be held in Ajax, Ontario
            (Canada). 
          </Typography>
          <Typography variant="body1">
            Contact us at{" "}
            <a href="mailto:info@chargemiles.ca">info@Chargemiles.ca</a> , if
            you have any questions. 
          </Typography>
        </Typography>
      </Container>
      {/* <CMFooter /> */}
    </Page>
  );
}
