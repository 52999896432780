import { useState, useEffect, useContext } from 'react';
import { Container, Typography } from '@mui/material';
import { Context } from '../../context';
// components
import AccountTabs from './AccountTabs';
// ----------------------------------------------------------------------

export default function AccountForm() {
  const { user, userType } = useContext(Context);
  const { userDetail } = user;
  
  return (
    <Container maxWidth="xl" sx={{ mt: 10 }}>
      <Typography variant="h6">Welcome {userDetail?.name}!</Typography>
      <AccountTabs userDetail={userDetail}/>
    </Container>
  );
}
