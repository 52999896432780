import React, { useState, useEffect, useContext } from "react";
import { Paper, Box, Button, Typography, Link } from "@mui/material";
import { MHidden, MuiDialog } from "../@material-extend";
import { Link as RouterLink } from "react-router-dom";

import LoginBanner from "../../image/login_banner.png";
import GooglePlay from "../../image/android-download-white.svg";
import AppStore from "../../image/app-store.png";
import AuthSocial from "./AuthSocial";
import LoginForm from "../authentication/login/LoginForm";
import RegisterForm from "../authentication/register/RegisterForm";
import Constant from "../../util/constants";
import { Context } from "../../context";
import HttpService from "../../services/HttpService";
import Token from "../../util/Token";
import { DomainText } from "../chargeMiles/DomainText";
import { useDomainConfig } from "../../hooks/useDomainConfig";

const { label, action, methods } = Constant;
const styles = {
  loginSection: {
    width: "80%",
  },
  link: {
    textAlign: "center",
    marginTop: "8px",
  },
  typographyh5: {
    mt: 3,
    color: "var(--app-color)",
    borderBottom: "3px solid var(--app-color)",
  },
};


export default function AuthPopup() {
  const {
    auth: { authenticated, setAuthenticated },
    user,
  } = useContext(Context);
  const [popUpState, setPopUpState] = useState({
    openPopup: !1,
    toggelForm: label.LOGIN,
  });
  const domainConfig = useDomainConfig();
  const handleEvents = (type) => () => {
    if (action.MODAL_CLOSE === type) {
      setPopUpState({
        ...popUpState,
        openPopup: !1,
      });
      setAuthenticated({
        ...authenticated,
        authPopup: !1,
      });
    }

    if (action.TOGGLE_BUTTON === type)
      setPopUpState({
        ...popUpState,
        toggelForm:
          popUpState.toggelForm === label.LOGIN ? label.REGISTER : label.LOGIN,
      });
  };
  const actionContent = (
    <>
      <Typography variant="subtitle1">
        App Available now on PlayStore and App Store
      </Typography>
      <Box className="cm-apps">
        <a
          href={domainConfig.appStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Get it on App Store</span>
          <picture>
            <source media="" type="image/svg" />
            <img
              className="image"
              src={AppStore}
              style={{ filter: "invert(1)" }}
              alt="Get it on App Store"
              loading="lazy"
            />
          </picture>
        </a>
      </Box>
      <Box className="cm-apps">
        <a
          href={domainConfig.playStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Get it on Google Play</span>
          <picture>
            <source media="" type="image/svg" />
            <img
              className="image"
              src={GooglePlay}
              alt="Get it on Google Play"
              loading="lazy"
            />
          </picture>
        </a>
      </Box>
    </>
  );
  useEffect(() => {
    setPopUpState({
      ...popUpState,
      openPopup: authenticated.authPopup,
    });
  }, [authenticated.authPopup]);

  useEffect(() => {
    if (authenticated.authElementType) {
      const userData = Token.parsedToken();
      const getUser = async (userId) => {
        const userRes = await HttpService(methods.GET_USER, {
          queryParameters: { userId },
        });
        user.setUserDetail(userRes.data);
      };

      if (userData && userData.success && userData.data) {
        const {
          data: { _id },
        } = userData;
        getUser(_id);
      }
    }
  }, [authenticated?.authElementType]);

  useEffect(() => {
    let popUpStatus = localStorage.getItem("v1_chargemiles_popup_status");
    let isExpired = popUpStatus && new Date() > new Date(popUpStatus);
    if (!popUpStatus || isExpired) {
      localStorage.setItem(
        "v1_chargemiles_popup_status",
        new Date().setHours(24)
      );
      setAuthenticated({
        ...authenticated,
        authPopup: !0,
      });
    }
  }, []);

  return (
    <MuiDialog
      open={popUpState.openPopup}
      onClose={handleEvents(action.MODAL_CLOSE)}
      maxWidth="sm"
      fullWidth={true}
      actions={true}
      actionContent={actionContent}
      className="auth-popup"
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper
          elevation={0}
          sx={styles.rightSection}
          className="popup-login-section"
        >
          <Typography variant="h5" sx={styles.typographyh5}>
            {popUpState.toggelForm}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{ color: "text.secondary", mt: 2, mb: 2 }}
          >
            By login, I agree to <DomainText />
            &nbsp;
            <Link
              href={ domainConfig.termsAndConditionUrl }
              underline="always"
              sx={{ color: "primary" }}
              target="_blank"

            >
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link
              href={ domainConfig.privacyPolicyUrl }
              target="_blank"
              underline="always"
              sx={{ color: "primary" }}
             
            >
              Privacy Policy
            </Link>
            .
          </Typography>
          {popUpState.toggelForm === label.LOGIN && <LoginForm />}
          {popUpState.toggelForm === label.REGISTER && <RegisterForm />}
          
          <AuthSocial></AuthSocial>
          
          <Box sx={styles.link}>
            <span>
              {popUpState.toggelForm === label.LOGIN ? (
                <>
                  {label.NEW_ACCOUNT} <DomainText />
                </>
              ) : (
                label.ALREADY_HAVE_ACCOUNT
              )}
            </span>
            <Button
              href="#"
              size="small"
              onClick={handleEvents(action.TOGGLE_BUTTON)}
            >
              {popUpState.toggelForm === label.LOGIN
                ? label.REGISTER
                : label.LOGIN}
            </Button>
          </Box>
        </Paper>
      </Box>
    </MuiDialog>
  );
}
