import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
// material
import { Stack, TextField, IconButton, InputAdornment, FormControlLabel, Checkbox, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  BrowserRouter as Router,
  useNavigate,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import axios from '../../../util/axios';
import token from '../../../util/Token';

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  let { id, secret,type } = useParams();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState();

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (resetPasswordData, { setSubmitting, resetForm }) => {
      if (!id || !secret) {
        setError("Invalid reset password link.");
        setSubmitting(false);
        return;
      }
      if (resetPasswordData.password !== resetPasswordData.confirmPassword) {
        setError("Password and confirm password does not match.");
        setSubmitting(false);
        return;
      }
      resetPasswordData['id'] = id;
      if(type==="phone"){
        resetPasswordData['otp'] = secret
      }else resetPasswordData['secret'] = secret;
      (async function () {
        try {
          const registerHelper = async () => {
             const url = type==="phone"?`${process.env.REACT_APP_API_END_POINT}auth/change-password-phone`:`${process.env.REACT_APP_API_END_POINT}auth/change-password`
            const response = await axios(url, {
              method: 'post',
              data: resetPasswordData
            });
            const resetPasswordResponse = response.data.data;
            setSubmitting(false);
            resetForm();
            setSuccessMessage("Password changed successfully, Please login!.");
            // navigate('/login')
          };
          await registerHelper();
        } catch (error) {
          setSubmitting(false);
          if (error.response && error.response.data && error.response.data.message) {
            setError(error.response.data.message);
          }
        }
      })();
      //navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, resetForm } = formik;

  return (
    <FormikProvider value={formik}>
      <div style={{ color: 'red' }}>{error}</div>
      <div style={{ color: 'green' }}>{successMessage}</div>
      { !successMessage?
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm password"
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowConfirmPassword((prev) => !prev)}>
                    {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Link component={RouterLink} variant="subtitle2" to="/login">
              Back to login
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Reset
          </LoadingButton>
        </Stack>
      </Form>:<Stack marginTop={2}>
            <Link component={RouterLink} variant="subtitle2" to="/login">
              Back to login
            </Link>
          </Stack>}
    </FormikProvider>
  );
}
