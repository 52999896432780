import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Paper,
  Fade,
  Backdrop,
  FormControl,
  Button,
  useMediaQuery,
  SvgIcon,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { ReactComponent as RoutePlannerSVG } from "../../image/app_icon_route.svg";
import { StyledFab } from "../common/Styled";
import Constant from "../../util/constants";
import { MHidden } from "../@material-extend";
import { Context } from "../../context";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";

import "./RoutePlanner.css";

const styles = {
  routerPlannerModal: {
    width: 450,
    boxShadow: 24,
    p: 4,
    borderRadius: "25px",
  },
  routerPlannerLabel: {
    color: "#000",
    fontSize: "1.25rem",
  },
  routerPlannerModalContent: {
    mt: 2,
    flexGrow: 1,
    overflow: "auto",
    "& .MuiFormControl-root:not(:last-child)": {
      mb: 2,
    },
    "& .MuiOutlinedInput-root": {
      width: "95%",
    },
  },
  closeIcon: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  routePlannerGo: {
    "&:hover": {
      backgroundColor: "#01B763",
    },
  },
  routePlannerFab: {
    bottom: "48px",
  },
  form: {
    position: "relative",
  },
  toggleLocation: {
    position: "absolute",
    top: "50%",
    right: "14px",
    zIndex: 10,
    opacity: 1,
    transform: "translateY(-70%)",
  },
};

const { action, label } = Constant;
const initialState = {
  open: !1,
  fromLocation: [],
  toLocation: [],
};

export default function RoutePlanner({
  onToggleDirection,
  onDirectionHandler,
}) {
  const theme = useTheme();
  const belowMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [routeState, setRouteState] = useState(initialState);
  const { maps } = useContext(Context);
  const plannerRef = useRef({});

  const handleEventHandler = (actionType) => (e) => {
    switch (actionType) {
      case action.MODAL_OPEN:
        setRouteState({
          ...routeState,
          open: !0,
        });
        break;
      case action.MODAL_CLOSE:
        setRouteState({
          ...routeState,
          open: !1,
        });
        onToggleDirection(!1);
        break;
      case action.ON_FROM_LOAD:
        setRouteState((prev) => ({
          ...prev,
          fromLocation: e,
        }));
        break;
      case action.ON_TO_LOAD:
        setRouteState((prev) => ({
          ...prev,
          toLocation: e,
        }));
        break;
      case action.ON_PLACE_FROM_CHANGED:
        plannerRef.current.start = routeState.fromLocation.getPlaces();
        break;
      case action.ON_PLACE_TO_CHANGED:
        plannerRef.current.dest = routeState.toLocation.getPlaces();
        break;
      case action.SWAP:
        if (!isEmpty(plannerRef.current)) {
          const { mapsData } = maps;
          const form = document.getElementById("route-planner");
          const formData = new FormData(form).entries();
          const [startPointName, startPointValue] = formData.next().value;
          const [endPointName, endPointValue] = formData.next().value;

          if (!isEmpty(mapsData.destination)) {
            plannerRef.current.dest = mapsData.destination.getPlaces();
            mapsData.destination = null;
          }

          const startRef = [{ ...plannerRef.current.dest[0] }];
          const endRef = [{ ...plannerRef.current.start[0] }];
          form.elements[startPointName].value = endPointValue;
          form.elements[endPointName].value = startPointValue;
          plannerRef.current.start = startRef;
          plannerRef.current.dest = endRef;
        }
        break;
      default:
        const { destination } = maps.mapsData;
        const {
          current: { start, dest },
        } = plannerRef;
        let fromPlace = start[0].geometry.location;
        let toPlaces = dest;
        destination && (toPlaces = destination.getPlaces());
        let toPlace = toPlaces[0].geometry.location;

        if (window.searchMarkerInstance) {
          window.searchMarkerInstance.setMap(null);
          window.searchMarkerInstance = null;
        }
        onDirectionHandler(
          { lat: fromPlace.lat(), lng: fromPlace.lng() },
          { lat: toPlace.lat(), lng: toPlace.lng() }
        );
        setRouteState((prev) => ({
          ...prev,
          open: !1,
        }));
        break;
    }
  };
  useEffect(() => {
    const { mapsData } = maps;
    if (!isEmpty(mapsData.direction)) {
      let [start, destination] = mapsData.direction;
      onDirectionHandler(start, destination);
    }

    if (!isUndefined(mapsData.routePlanner)) {
      setRouteState((prev) => ({
        ...prev,
        open: mapsData.routePlanner,
      }));
    }
  }, [maps]);

  return (
    <div className="route-planner">
      {/* <MHidden width="mdDown">
        <Paper
          component="div"
          className="relative route-planner--paper-button Map-action-button desktop"
          onClick={handleEventHandler(action.MODAL_OPEN)}
        >
          <SvgIcon component={RoutePlannerSVG} inheritViewBox />
          <span>Route planner</span>
        </Paper>
      </MHidden> */}

      {/* <MHidden width="mdUp">
        <Paper component="div" className="mobile">
          <StyledFab
            sx={styles.routePlannerFab}
            aria-label="route planner"
            onClick={handleEventHandler(action.MODAL_OPEN)}
          >
            <SvgIcon component={RoutePlannerSVG} inheritViewBox />
          </StyledFab>
        </Paper>
      </MHidden> */}

      <Modal
        open={routeState.open}
        closeAfterTransition
        onClose={handleEventHandler(action.MODAL_CLOSE)}
        disableEnforceFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={routeState.open}>
          <Box
            sx={styles.routerPlannerModal}
            className={`route-planner--modal Map-modal ${
              belowMdScreen && "mobile"
            }`}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Plan a route
            </Typography>
            <Box
              sx={styles.routerPlannerModalContent}
              className="route-planner--modal-content"
            >
              <Paper component="form" elevation={0} id="route-planner">
                <Box className="highres start"></Box>
                <Box className="highres vert"></Box>
                <Box className="highres dest"></Box>
                <FormControl variant="standard" fullWidth={true}>
                  <StandaloneSearchBox
                    onPlacesChanged={handleEventHandler(
                      action.ON_PLACE_FROM_CHANGED
                    )}
                    onLoad={handleEventHandler(action.ON_FROM_LOAD)}
                  >
                    <OutlinedInput
                      autoFocus
                      size="small"
                      id="starting_location"
                      name="start_point"
                      placeholder="Choose the starting point, or click on map"
                      aria-label={label.STARTING_LOCATION}
                    />
                  </StandaloneSearchBox>
                </FormControl>
                <FormControl variant="standard" fullWidth={true}>
                  <StandaloneSearchBox
                    onPlacesChanged={handleEventHandler(
                      action.ON_PLACE_TO_CHANGED
                    )}
                    onLoad={handleEventHandler(action.ON_TO_LOAD)}
                  >
                    <OutlinedInput
                      size="small"
                      fullWidth
                      placeholder="Choose destination..."
                      aria-label={label.DESTINATION_LOCATION}
                      name="end_point"
                      defaultValue={
                        maps.mapsData.destination
                          ? maps.mapsData.destination.getPlaces()[0]
                              .formatted_address
                          : ""
                      }
                    />
                  </StandaloneSearchBox>
                </FormControl>
                <FormControl variant="standard" sx={{ mt: 2 }} fullWidth={true}>
                  <Button
                    sx={styles.routePlannerGo}
                    className="router-planner--go round"
                    variant="contained"
                    onClick={handleEventHandler(action.SUBMIT)}
                  >
                    {label.GO}
                  </Button>
                </FormControl>
              </Paper>
              <Box className="toggle-location" sx={styles.toggleLocation}>
                <Tooltip
                  title="Reverse starting point and destination"
                  placement="bottom-start"
                  onClick={handleEventHandler(action.SWAP)}
                >
                  <IconButton aria-label="Reverse Starting Location to Destination Location">
                    <SwapVertOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <IconButton
                onClick={handleEventHandler(action.MODAL_CLOSE)}
                sx={styles.closeIcon}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
