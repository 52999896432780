import { createTheme } from "@mui/material";

export const useDomainTheme = (domainConfig) => {
  const { palette } = domainConfig;

  document.getElementById("favicon").href = domainConfig.faviconUrl
  
  const dynamicVariables = `
  :root {
    --app-color: ${palette.primary.main};
  }
  `;
  const styleElement = document.createElement("style");
  styleElement.appendChild(document.createTextNode(dynamicVariables));
  document.head.appendChild(styleElement);

  const domainTheme = createTheme({
    ...{ palette },
  });

  return domainTheme;
};
